import React from "react";

import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import {useTheme} from "@mui/material";
import { tokens } from "../../theme";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

const CustomModalTransactionDetail = ({ isOpen, onClose, data }) => {
  const {t}=useTranslation();
  const theme=useTheme();
  const colors=tokens(theme.palette.mode);

  return (
    <Modal dialogClassName="modal-zoom" show={isOpen} onHide={onClose} centered size="lg">
      <Modal.Header  style={{ backgroundColor: colors.default[100]  }}>
        <Modal.Title>{t('Invoice_Product_Detail')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: colors.default[100]  }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('Qty')}</TableCell>
                <TableCell>{t('Desc')}</TableCell>
                <TableCell>{t('PRICE_WITHOUT_VAT')}</TableCell>
                <TableCell>{t('Taxable_Amount')}</TableCell>
                <TableCell>{t('Total_Tax')}</TableCell>
                <TableCell>{t('Final_Total')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {data.postransdata && data.postransdata.map((row) => (
                  <TableRow>
                    <TableCell>{row.qty}</TableCell>
                    <TableCell>{row.desc}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.taxable}</TableCell>
                    <TableCell>{row.taxamount}</TableCell>
                    <TableCell>{row.total}</TableCell>
                  </TableRow>
                ))}
              {data.discountdata && data.discountdata.map((row) => (
                  <TableRow>
                    <TableCell>{row.qty}</TableCell>
                    <TableCell>{row.desc}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.taxable}</TableCell>
                    <TableCell>{row.taxamount}</TableCell>
                    <TableCell>{row.total}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            
          </Table>
        </TableContainer>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: colors.default[100]  }}>
        <Button variant="secondary" onClick={onClose}>
          {t('Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModalTransactionDetail;
