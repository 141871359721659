import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    tableLayout: "fixed",
  },
  tableContainer: {
    maxHeight: "386px", // Set your desired fixed height here
    minHeight: "386px", // Set the same value to ensure it stays fixed
  },

  scrollableWrapper: {
    overflowX: "auto",
    maxHeight: "386px",
    minHeight: "386px",
  },
  fixedColumn: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: "white",
  },
  lastColumn: {
    position: "sticky",
    right: 0,
    zIndex: 1,
    backgroundColor: "white",
  },
});

const TableProduct = ({ tableData }) => {
  const classes = useStyles();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <div className={classes.scrollableWrapper}>
      {tableData.columnlist.length === 1 ? (
        <div style={{ justifyContent: "center", alignContent: "center" }}>
          No data{" "}
        </div>
      ) : (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.fixedColumn}
                  style={{ width: "100px", background:colors.default[100],color:colors.default[200]}}
                ></TableCell>
                {tableData.columnlist.map((col, index) => (
                  <TableCell
                    className={
                      index === tableData.columnlist.length - 1
                        ? classes.lastColumn
                        : null
                    }
                    style={{ fontWeight: "bold", background:
                    index === 0 || index === tableData.columnlist.length - 1
                      ? colors.default[100]
                      : null ,
                      color:
                      index === 0 || index === tableData.columnlist.length - 1
                        ? colors.default[200]
                        : null ,
                    
                    }}
                  >
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.rowlist.map((row) => (
                <TableRow key={row.name}>
                  {row.colname.map((col, index) => (
                    <TableCell
                      key={index}
                      className={`
                              ${index === 0 ? classes.fixedColumn : ""} 
                              ${
                                index === row.colname.length - 1
                                  ? classes.lastColumn
                                  : ""
                              }
                            `}
                      style={{
                        fontWeight:
                          index === 0 || index === row.colname.length - 1
                            ? "bold"
                            : "normal",
                        width: index === 0 ? "100px" : "auto", 
                        background:
                    index === 0 || index === row.colname.length - 1
                      ? colors.default[100]
                      : null ,
                      color:
                      index === 0 || index === row.colname.length - 1
                        ? colors.default[200]
                        : null 
                      }}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default TableProduct;
