import { useTranslation } from "react-i18next";
import { Modal, Input, Form } from "antd";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material";
import { ConfigProvider, theme } from "antd";
import { useEffect } from "react";
import { baseInstance, updateToken } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const ResetPassword = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const themecustom = useTheme();

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [isOpen]);

  const handleApiRequest = async () => {
    updateToken(localStorage.getItem("token"));
    try {
      let response;
      const fieldValues = form.getFieldsValue();
      response = await baseInstance.post("/api/auth/resetpassword", {
        oldpassword: fieldValues.oldpassword,
        newpassword: fieldValues.newpassword,
      });
      form.resetFields();
      return response;
    } catch (error) {
      form.resetFields();
      throw error;
    }
  };

  const handleClick = () => {
    const fieldValues = form.getFieldsValue();
    if (fieldValues.newpassword !== fieldValues.retypepassword) {
      toast.error("New Password Did not Match");
    } else {
      handleApiRequest()
        .then((response) => {
          if (response.status === 200) {
            toast.success("Password Reset Successfully");
            onClose();
          } else if (response.status === 400) {
            toast.error("Old Password did not match");
          } else {
            toast.error("Error");
            console.log("Request failed with status:", response.status);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            toast.error("Old password did not match");
          } else if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            toast.error("Error");
            console.error(error);
          }
        });
    }
  };

  return (
    <ConfigProvider
      theme={{
        algorithm:
          themecustom.palette.mode == "dark" ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <Modal
        title={t("Reset Password")}
        visible={isOpen}
        onCancel={onClose}
        onOk={handleClick}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label={t("Old Password")}
            name="oldpassword"
            rules={[{ required: true, message: "Please Enter Old Password" }]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item
            label={t("New Password")}
            name="newpassword"
            rules={[{ required: true, message: "Please Enter New Password" }]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item
            label={t("Retype New Password")}
            name="retypepassword"
            rules={[{ required: true, message: "Please Retype New Password" }]}
          >
            <Input type="password" />
          </Form.Item>
        </Form>
      </Modal>{" "}
    </ConfigProvider>
  );
};

export default ResetPassword;
