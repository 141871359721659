import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChartVertical = (props) => {
  const [chartData] = useState({
    series: [{
      data: props.data
    }],
    options: {
      chart: {
        type: 'bar',
        height: 250
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: props.item,
      },
      fill: {
        opacity: 1,
        colors: [props.colors],
      },
    }
  });

  return (
    <div>
      <div id="barchartvertical">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={250} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default BarChartVertical;
