import React from 'react';
import { GridOverlay } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';

const CustomLoadingOverlay = () => (
  <GridOverlay>
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <LinearProgress style={{ width: '50%', borderRadius: '0.5rem' }} />
    </div>
  </GridOverlay>
);

export default CustomLoadingOverlay;