import React from "react";
import CustomDateRange from "../../components/CustomDateRange";
import Reportviewer from "../../components/supportingComponents/Reportviewer";

const Reports = () => {
  return(
    <>
    <CustomDateRange sx={{textAlign:"center"}} />
    <Reportviewer/>    
    </>

  ); 
  
};

export default Reports;
