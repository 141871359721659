import React, { useEffect, useState, useContext } from "react";
import PageHeader from "../../../components/PageHeader";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../../../components/supportingComponents/CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import OutletContext from "../../../context/OutletContext";
import jwtDecode from "jwt-decode";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import CustomDateRange from "../../../components/CustomDateRange";
import ReportModalSubway from "./CustomModal";
import { DatePicker, CustomProvider } from "rsuite";
import { baseInstance, updateToken } from "../../../api/axios";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { saveAs } from "file-saver";


const SubwayReporting = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [addCategoryModalOpen, setaddCategoryModalOpen] = useState(false);
  const { logout } = useAuth();
  const theme = useTheme();
  let duedateCount = 0;
  const [dueDateCountState, setdueDateCountState] = useState(0);
  const [dueDate, setDueDate] = useState(calculateWeekDates(0, 0).due);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const [selectedOutlet, setSelectedOutlet] = useState(OutletId[0]);
  const [weekday, setWeekDay] = useState(0);
  const [startDate, setStartDate] = useState(calculateWeekDates(0, 0).start);
  const [endDate, setEndDate] = useState(calculateWeekDates(0, 0).end);
  const [submitedReportsRow, setSubmitedReportsRow] = useState([]);
  const [selectedReport, setSelectedReport] = useState(0);

  function calculateWeekDates(startDay, duedate) {
    const currentDay = new Date().getDay();
    const daysToSubtract = (currentDay - startDay + 7) % 7;
    const currentWeekStart = new Date();
    currentWeekStart.setDate(currentWeekStart.getDate() - daysToSubtract);
    // currentWeekStart.setHours(0, 0, 0, 0);
    const currentWeekEnd = new Date(currentWeekStart);
    currentWeekEnd.setDate(currentWeekStart.getDate() + 6);
    const currentWeekDueDate = new Date(currentWeekEnd);
    currentWeekDueDate.setDate(currentWeekEnd.getDate() + duedateCount);
    return {
      start: currentWeekStart,
      end: currentWeekEnd,
      due: currentWeekDueDate,
    };
  }

  const [outlets, setOutlets] = useState([]);
  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    try {
      const fetchOutletsData = jwtDecode(token);
      setOutlets(fetchOutletsData.outlets);
    } catch {
      console.log("error in token validation");
    }
  }, []);

  useEffect(() => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post("/api/integration/subwayreporting", {
        storeid:
          selectedOutlet === ""
            ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
            : selectedOutlet,
      })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          if (!data.message) {
            setWeekDay(data.startday);
            duedateCount =
              data.dueday > data.startday
                ? data.dueday - data.startday + 1
                : data.startday - data.dueday + 1;
            setdueDateCountState(duedateCount);
            setStartDate(calculateWeekDates(data.startday, 0).start);
            setEndDate(calculateWeekDates(data.startday, 0).end);
            setDueDate(calculateWeekDates(data.startday, data.dueday).due);
          }
        }
        if (response.status === 400) {
          toast.error("Complete Api setup for the Outlet");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error("Complete Api setup for the Outlet");
        } else if (error.response.status === 403) {
          handleLogout();
          toast.error("Unauthorized access");
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
        }
      });
  }, [selectedOutlet]);

  const LoadPageContent = async () => {
    let response;
    updateToken(localStorage.getItem("token"));
    response = await baseInstance
      .post("/api/integration/subwayreporting/listsubmitted", {
        storeid:
          selectedOutlet === ""
            ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
            : selectedOutlet,
      })
      .then((response) => {
        if (response.status === 200) {
          setSubmitedReportsRow(response.data.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          handleLogout();
          toast.error("Unauthorized access");
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    LoadPageContent();
  }, [selectedOutlet]);

  const handleAdd = (params) => {
    setaddCategoryModalOpen(true);
  };

  const isWeekDay = (date) => {
    const sixWeeksAgo = new Date();
  sixWeeksAgo.setDate(sixWeeksAgo.getDate() - 7 * 7); // 6 weeks * 7 days

  // Check if the date is not a weekday or if it's within the last 6 weeks
  const isWeekday = date.getDay() !== weekday;
  const isWithinLast6Weeks =  date.valueOf() <= sixWeeksAgo.valueOf();

  return isWeekday || isWithinLast6Weeks;
    // const day = date.getDay();
    // return day !== weekday;
  };

  const getfilesubmitted = () => {
    if (selectedReport === 0) {
      toast.error("Select Report to Download");
    } else {
      let url = "/api/reports/integration/subwaysubmitted";
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post(
          url,
          {
            reportid: selectedReport,
          },
          {
            responseType: "blob",
          }
        )
        .then(function (response) {
          const blob = new Blob([response.data], { type: "application/pdf" });
          saveAs(blob, "SubwaySubmittedReport" + ".pdf");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const columns = [
    {
      field: "id",
      headerName: t("ID"),
      flex: 0.3,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "Date",
      headerName: t("Date (UTC)"),
      flex: 0.5,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "SubmittedBy",
      headerName: t("Submitted By"),
      flex: 0.5,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "Status",
      headerName: t("Status"),
      flex: 0.5,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "WeekEndDate",
      headerName: t("Weed End Date"),
      flex: 0.5,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "ResponseMessage",
      headerName: t("Response Message"),
      flex: 0.5,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];
  const handleOutletChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOutlet(selectedValue);
  };

  const changeStartDate = (date) => {
    setStartDate(date);
    // setStartDate(format(date, 'yyyy-MM-dd', { timeZone: 'Asia/Riyadh' }));
    // console.log(date);
    // console.log(format(date, 'yyyy-MM-dd', { timeZone: 'Asia/Riyadh' }));
    const newEndDate = new Date(date);
    newEndDate.setDate(newEndDate.getDate() + 6);
    setEndDate(newEndDate);
    // setEndDate(format(newEndDate, 'yyyy-MM-dd', { timeZone: 'Asia/Riyadh' }));
    const newDueDate = new Date(newEndDate);
    newDueDate.setDate(newEndDate.getDate() + dueDateCountState);
    setDueDate(newDueDate);
    // setDueDate(setEndDate(format(newDueDate, 'yyyy-MM-dd', { timeZone: 'Asia/Riyadh' })));
  };
  const handleRowClick = (params) => {
    const { id } = params.row;
    setSelectedReport(id);
  };

  return (
    <>
      <ReportModalSubway
        isOpen={addCategoryModalOpen !== false}
        onClose={() => {
          // setSelectedRowData(defaultValueSalesInvoice);
          setaddCategoryModalOpen(false);
        }}
        LoadPageContent={LoadPageContent}
        startdate={startDate}
        enddate={endDate}
        outletid={selectedOutlet}
      />
      <Box className=" h-75 mx-auto w-90 text-align " sx={{ width: "100%" }}>
        <PageHeader title={t("Subway_Reporting")} />
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <FormControl
            sx={{
              minWidth: 250,
              maxWidth: 250,
              marginBottom: "15px",
              textAlign: "left",
            }}
            size="small"
          >
            <InputLabel id="demo-select-small-label">
              {t("Select_Outlet")}
            </InputLabel>
            <Select
              value={selectedOutlet}
              onChange={handleOutletChange}
              // required
            >
              {outlets.map((outlet) => (
                <MenuItem value={outlet.id}>{outlet.storename}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            marginBottom: "15px",
            textAlign: t("langDir"),
            // border: 0.5,
            // borderColor: "#000",
            padding: 3,
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)", // Add a box shadow
            borderRadius: 3,
            marginBottom: 2,
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            style={{ marginBottom: 10 }}
          >
            {" "}
            {t("Submit_sales")}{" "}
            {/* <span style={{ color: "red" }}> - Not Submitted</span> */}
          </Typography>
          <Grid container spacing={3}>
            <Grid style={{ paddingTop: 30 }} item>
              {t("Start_Date")}
            </Grid>
            <Grid style={{ paddingLeft: 15 }} item xs={3}>
              <CustomProvider theme={theme.palette.mode}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={startDate}
                  onChange={changeStartDate}
                  disabledDate={isWeekDay}
                />
              </CustomProvider>
            </Grid>
            <Grid style={{ paddingTop: 30 }} item>
            {t("End_Date")}
            </Grid>
            <Grid style={{ paddingLeft: 15 }} item xs={3}>
              <CustomProvider theme={theme.palette.mode}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  disabledDate={isWeekDay}
                  disabled
                />
              </CustomProvider>
            </Grid>
            <Grid style={{ paddingTop: 30 }} item>
            {t("Due_Date")}
            </Grid>
            <Grid style={{ paddingLeft: 15 }} item xs={3}>
              <CustomProvider theme={theme.palette.mode}>
                <DatePicker
                  style={{ width: "100%" }}
                  value={dueDate}
                  onChange={(date) => setDueDate(date)}
                  disabledDate={isWeekDay}
                  disabled
                />
              </CustomProvider>
            </Grid>
          </Grid>
          <button
            style={{ marginTop: 15 }}
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleAdd}
          >
            {t("Show_Report")}
          </button>
        </Box>

        <Box
          sx={{
            marginBottom: "15px",
            textAlign: t("langDir"),
            // border: 0.5,
            // borderColor: "#000",
            padding: 3,
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)", // Add a box shadow
            borderRadius: 3,
            marginBottom: 2,
          }}
        >
          <Box display="flex" style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{ marginTop: "5px" }}
              >
            {t("Submitted_Reports")}
                
              </Typography>
              {/* <CustomDateRange
                sx={{
                  marginLeft: "30px",
                  marginTop: "0px",
                  marginBottom: "3px",
                }}
              /> */}
            </div>

            <button
              type="button"
              className="btn btn-sm btn-primary"
              style={{ marginLeft: "10px", marginBottom: "10px" }}
              onClick={getfilesubmitted}
            >
              <span>
                <FileDownloadOutlinedIcon />
              </span>
              PDF
            </button>
          </Box>
          <DataGrid
            rows={submitedReportsRow}
            columns={columns}
            onRowClick={handleRowClick}
            slots={{
              noRowsOverlay: () => (
                <CustomNoRowsOverlay message={t("No Reports")} />
              ),
            }}
            style={{ height: 350, marginBottom: "15px" }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 4,
                },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default SubwayReporting;
