import { Box, TextField } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import { baseInstance } from "../../../api/axios";
import OutletDropdown from "../../../components/supportingComponents/outletdropdown";
import { updateToken } from "../../../api/axios";
import jwtDecode from "jwt-decode";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import OutletContext from "../../../context/OutletContext";
import CustomLoadingOverlay from "../../../components/supportingComponents/CustomLoadingOverLay";
import { CustomNoRowsOverlay } from "../../../components/supportingComponents/CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";


function Invoices() {
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const [selectedOutlet, setSelectedOutlet] = useState(
    [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
  );
  const { logout } = useAuth();
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const {t}=useTranslation();
  const roles = jwtDecode(localStorage.getItem("token")).roles;
  const allowedEntity = roles.find(
    (role) => role.EntityCode === "Vendors" 
  );

  const [createButton, setCreateButton] = useState(allowedEntity.New);


  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const columns = [
    {
      field: "id",
      headerName: t("Transact"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
    },
    {
      field: "date",
      headerName: t("Date"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "vendor",
      headerName: t("Vendor"),
      flex: 0.4,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "total_amount",
      headerName: t("Final_Total"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "po_no",
      headerName: t("PO_No"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "outletid",
      headerName: t("Outlet"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  const [loadingInvoices, setLoadingInvoices] = useState(true);

  const handleOutletChange = (selectedValue) => {
    setSelectedOutlet(selectedValue);
  };
  const handleRowClick = (params) => {
    const { id,outletid } = params.row;
    setSelectedRowId({"OutletId": outletid,
      "Transact": id});
  };

  useEffect(() => {
    fetchRows();
  }, [selectedOutlet]);

  const handleNewInvoice = () => {
    navigate("/accounts/createinvoice");
  };

  const getfile = () => {
    if (selectedRowId == null) {
      alert("Select the Invoice");
    } else {
      let url = "/api/accounts/invoices/download";
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post(
          url,selectedRowId,
          // {
          //   OutletId: selectedOutlet,
          //   Transact: selectedRowId,
          // },
          {
            responseType: "blob", // Set the response type to 'blob'
          }
        )
        .then(function (response) {
          const blob = new Blob([response.data], { type: "application/pdf" });
          saveAs(blob, "Invoice_" + String(selectedRowId.Transact) + ".pdf");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
        });
    }
  };

  const fetchRows = () => {
    setLoadingInvoices(true);
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post("/api/accounts/invoices", {
  
          page: currentPage,
          pageSize: pageSize,
          outletid: selectedOutlet,
   
      })
      .then((response) => {
        const { data, totalRows } = response.data;
        setRows(data);
        setTotalRows(data.totalrows);
        setLoadingInvoices(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
        setLoadingInvoices(false);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    String(row.id).includes(searchText)
  );

  return (
    <Box className=" h-75 mx-auto w-90 text-align ">
      <PageHeader title={t("Invoices")} />
      <Box className=" mx-auto text-align " sx={{ width: "100%" }}>
      {createButton ? (
        <Box sx={{ textAlign: "right", marginBottom: "10px" }}>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            style={{ marginLeft: "10px" }}
            onClick={handleNewInvoice}
          >
            {t("Create_Invoice")}
          </button>
        </Box>  ) : null}
        <Box mb="10px" sx={{ textAlign: "right" }}>
          <OutletDropdown
            onChange={handleOutletChange}
            defaultValue={selectedOutlet}
            sx={{ textAlign: "left" }}
          />
          <TextField
            label="Search"
            value={searchText}
            onChange={handleSearch}
            size="small"
            style={{ marginLeft: "10px" }}
          />
          <button
            type="button"
            className="btn btn-sm btn-primary"
            style={{ marginLeft: "10px" }}
            onClick={getfile}
          >
            <span>
              <FileDownloadOutlinedIcon />
            </span>
            PDF
          </button>
        </Box>

        <DataGrid
          rows={filteredRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            columns: {
              columnVisibilityModel: {
                outletid: false,
           
              },
            },
          }}
          slots={{
            noRowsOverlay: () => <CustomNoRowsOverlay message="No Invoices!" />,
          }}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={loadingInvoices}
          style={{ height: 630 }}
          componentsProps={{
            loadingOverlay: {
              style: { zIndex: 9999 },
            },
          }}
          pageSizeOptions={[5]}
          //   disableRowSelectionOnClick
          // paginationMode="server"
          rowCount={totalRows}
          onRowClick={handleRowClick}
          // onPageChange={handlePageChange}
          // onPageSizeChange={handlePageSizeChange}
        />
      </Box>
    </Box>
  );
}

export default Invoices;
