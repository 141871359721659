import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { updateToken } from "../../../api/axios";
import { baseInstance } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { CustomNoRowsOverlay } from "../../../components/supportingComponents/CustomNoRowsOverlay";
import CustomLoadingOverlay from "../../../components/supportingComponents/CustomLoadingOverLay";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import jwtDecode from "jwt-decode";

const Vendors = () => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "id",
      headerName: "id",
      headerClassName: "th-datagrid-theme",
    },
    {
      field: "actions",
      headerName: "",
      headerClassName: "th-datagrid-theme",
      width: 0.1,
      renderCell: (params) => (
        <IconButton onClick={() => handleEdit(params.row.id)}>
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
      
    },
    {
      field: "vat",
      headerName: t("VAT"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "address",
      headerName: t("Address"),
      flex: 0.6,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  const handleEdit = (id) => {
    navigate(`/accounts/createvendor/${id}`);
  };
  const { logout } = useAuth();

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const navigate = useNavigate();
  const [loadingVendors, setLoadingVendors] = useState(true);
  const roles = jwtDecode(localStorage.getItem("token")).roles;
  const allowedEntity = roles.find(
    (role) => role.EntityCode === "Vendors" 
  );

  const [createButton, setCreateButton] = useState(allowedEntity.New);
  const [editButton, setEditButton] = useState(allowedEntity.Edit);


  const fetchvendorRows = () => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/accounts/vendors")
      .then((response) => {
        const { data, totalRows } = response.data;
        setRows(data);
        setTotalRows(data.totalrows);
        setLoadingVendors(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
        setLoadingVendors(false);
      });
  };
  useEffect(() => {
    fetchvendorRows();

  }, []);

  const handleNewVendor = () => {
    navigate("/accounts/createvendor");
  };

  return (
    <Box className=" h-75 mx-auto w-90 text-align ">
      <PageHeader title={t("Vendors")} />
      <Box className=" mx-auto text-align " sx={{ width: "100%" }}>
        {createButton ? (
          <Box sx={{ textAlign: "right", marginBottom: "10px" }}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              style={{ marginLeft: "10px" }}
              onClick={handleNewVendor}
            >
              {t("Create_Vendor")}
            </button>
          </Box>
        ) : null}

        <DataGrid
          rows={rows}
          columns={columns}
          slots={{
            noRowsOverlay: () => <CustomNoRowsOverlay message="No Vendors!" />,
          }}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={loadingVendors}
          componentsProps={{
            loadingOverlay: {
              style: { zIndex: 9999 },
            },
          }}
          style={{ height: 630 }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            columns: {
              columnVisibilityModel: {
                id: false,
                actions: editButton,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Vendors;
