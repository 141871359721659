import React from 'react'
import { useTranslation } from 'react-i18next';

export default function MemberListReport(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>

            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Member_Name')}</td>
                        <td>{t('Address')}</td>
                        <td>{t('City')}</td>
                        <td>{t('Phone No.')}</td>
                        <td>{t('Amount Due')}</td>
                        <td>{t('Total Order')}</td>
                        <td>{t('Total Sale')}</td>

                    </tr>
                </thead>
                <tbody>

                    {
                        props.data.datamemberlist.map((item, index) => (
                            <tr key={index}>
                                <td>{item.membername}</td>
                                <td>{item.address}</td>
                                <td>{item.city}</td>
                                <td>{item.mobile}</td>
                                <td>{item.amountdue}</td>
                                <td>{item.orders}</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </div>

    )

}