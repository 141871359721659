import React from "react";
import { Box, Typography, Card ,useTheme} from "@mui/material";
import ApexChart from "./Apexcharts";
import DashboardTabTable from "./DashboardTabTable";
import { ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme";

const DashboardLineChart = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      className="row"
      style={{ paddingRight: "5px", paddingLeft: "5px", marginTop: "20px" }}
    >
      <Box className="col-md-6" >
        <Card sx={{backgroundColor:colors.default[100],boxShadow: "0 1px 2.94px 0.06px #ffffff"}}>
          <Box className="card-body" >
            <ResponsiveContainer width="100%" height={350}>
              <ApexChart data={props.dataResponse} />
            </ResponsiveContainer>
          </Box>
        </Card>
      </Box>
      <Box className="col-md-6" >
        <Card sx={{backgroundColor:colors.default[100],boxShadow: "0 1px 2.94px 0.06px #ffffff"}}>
          <Box style={{ marginTop: "18px", textAlign: "center" }}>
            <Typography variant="h5" fontWeight={"bold"}>{t('Top_Records')}</Typography>
          </Box>
          <Box
            className="card-body"
            style={{
              paddingTop: "10px",
              paddingBottom: "5px",
              paddingLeft: "5px",
              paddingRight: "1px",
            }}
          >
            <ResponsiveContainer width="98%" height={350}>
              {/* <ApexChart storeData={storeData} /> */}
              <DashboardTabTable dataResponseTab={props.dataResponseTab} loadingTabs={props.loadingTabs}/>
            </ResponsiveContainer>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default DashboardLineChart;
