import { Height } from '@mui/icons-material';
import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const PieDonut = ({data}) => {
  
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      type: 'donut',
    },
    legend: {
        show:false,
        position: 'bottom', // Place the legend at the bottom
      },
      colors: ['#018539', '#F5f5f5'],
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
   

          if (opts.seriesIndex === 0) {
            return val.toFixed(2)+'%'; // Show label only for the first data point
          } else {
            return '';
          }
        },
        style: {
          colors: ['#ff0'],
          fontSize: "17px",
          
          
        },
        align: "center"
      },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 100,
          Height:100
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  });

  return (
    <Chart
      options={options}
      series={data}
      type="donut"
      width={280}
      height={160}
    />
  );
};

export default PieDonut;
