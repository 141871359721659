import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material";

const BarChartData = (props) => {
 
  const theme = useTheme();
  const [chartData, setChartData] = useState({
    series: [
      {
        name: props.name,
        data: [],
      },
    ],
    options: {
      theme: {
        mode: "light",
      },
      chart: {
        type: "bar",
        height: 350,
        width: 15,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        // text: props.name || "Bar Chart",
        align: "center",
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      xaxis: {
      //   labels: {
      //     rotateAlways: false,
      //     offsetX: 0,
      //     offsetY: 0,
      //     rotate: -45,
      //     hideOverlappingLabels: true,
      //     showDuplicates: false,
      //     formatter: function (val) {
      //         // Check the length of the label
      //         if (val.length > 7) { // You can adjust this threshold as needed
      //             return val.split(' ').join('\n');
      //         } else {
      //             return val;
      //         }
      //     }
      // },
        categories: [],
      },
      yaxis: {},
      fill: {
        opacity: 1,
        colors: [props.colors],
      },
    },
  });

  useEffect(() => {
    const categories = props.data.map(item => item.category);
    console.log(categories);
  
    const values =props.data.map(item => item.value);
    console.log(values);
    setChartData({
      ...chartData,
      series: [
        {
          data: values,
        },
      ],
      options: {
        ...chartData.options,
        xaxis: {
          ...chartData.options.xaxis,
          categories: categories,
        },
      },
    });
  }, [props.data]);
  // const [chartData,setChartData] = useState({
  //   series: [
  //     {
  //       name: '',
  //       data: [],
  //     },

  //   ],
  //   options: {
  //       theme: {
  //           mode: "light",
  //         },
  //     chart: {
  //       type: "bar",
  //       height: 350,
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //         columnWidth: "55%",
  //         endingShape: "rounded",
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     title: {
  //       text: props.name,
  //       align: 'center',
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ["transparent"],
  //     },
  //     xaxis: {
  //       categories: [],
  //     },
  //     yaxis: {
  //       // title: {
  //       //   text: "$ (thousands)",
  //       // },
  //     },
  //     fill: {
  //       opacity: 1,
  //     },
  //   //   tooltip: {
  //   //     y: {
  //   //       formatter: function (val) {
  //   //         return "$ " + val + " thousands";
  //   //       },
  //   //     },
  //   //   },
  //   },
  // });

  // useEffect(() => {
  //   setChartData({
  //     ...chartData,
  //     series: [
  //       {
  //         name: props.name,
  //         data: props.data,
  //       },
  //     ],
  //     options: {
  //       ...chartData.options,
  //       xaxis: {
  //         ...chartData.options.xaxis,
  //         categories: props.category,
  //       },
  //     },
  //   });
  // }, [props.data, props.category]);

  // useEffect(() => {
  //   setChartData((prevChartData) => ({
  //     ...prevChartData,
  //     options: {
  //       ...prevChartData.options,

  //       theme: {
  //         ...prevChartData.options.theme,
  //         mode: theme.palette.mode,
  //       },
  //     },

  //   }));
  // }, [theme.palette.mode]);
  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default BarChartData;
