import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import { updateToken } from "../../../../../api/axios";
import { baseInstance } from "../../../../../api/axios";
import useAuth from "../../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TextField, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";

const CreatePackage = ({ isOpen, onClose, data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [packageName, setPackageName] = useState();
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { logout } = useAuth();
  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const handleApiRequest = async () => {
    let response;
    updateToken(localStorage.getItem("token"));
    try {
      // Creating new vendor
      response = await baseInstance.post(
        "/api/administration/entitygroup/createentitygroup",
        {
          ApiData: {
            packageName,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleClick = () => {
    // Validate the form fields
    const validationErrors = {};
    if (packageName.trim() === "") {
      validationErrors.packageName = "Package Name is Required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      handleApiRequest()
        .then((response) => {
          if (response.status === 200) {
            // navigate("/accounts/vendors");
            toast.success("Record Created!");
            setPackageName("");
            onClose();
          } else {
            console.log("Request failed with status:", response.status);
            setPackageName("");
            onClose();
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            toast.error("Record Failed!");
            console.log(error);
            setPackageName("");
            onClose();
          }
        });
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header style={{ backgroundColor: colors.default[100] }}>
        <Modal.Title>Create Package</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: colors.default[100] }}>
        <div>
          <TextField
            label={t("Name")}
            value={packageName}
            onChange={(e) => setPackageName(e.target.value)}
            required
            error={errors.packageName}
            helperText={errors.packageName}
            fullWidth
          />
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: colors.default[100] }}>
        <Button variant="primary" onClick={handleClick}>
          Create
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreatePackage;
