
import React from "react";

import GlobalRouteConfig from "./routes/globalroute";


function App() {
 
  return (
    <GlobalRouteConfig/>
  );
}

export default App;
