import React from 'react'
import { useTranslation } from 'react-i18next';


export function WarehouseListing(props){

    const {t}=useTranslation();


return(
    <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>
            <hr/>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Warehouse_No')}</td>
                        <td>{t('Desc')}</td>
                        <td>{t('Reference_Code')}</td>

                       
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.warehousedata.map((item, index) => (
                            <tr key={index}>
                                <td>{item.warehousenum}</td>
                                <td>{item.descript}</td>
                                <td>{item.refcode}</td>
                   
                            </tr>
                        )
                        )
                    }

                    
                </tbody>
            </table>
 
        </div>



)

}