import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div id="notfound" style={{    position: "relative",
      height: "100vh"}}>
      <div className="notfound" >
        <div className="notfound-404">
          <h1 className="notfoundh1">Oops!</h1>
          <h2 className=".notfoundh2">404 - The Page can't be found</h2>
        </div>
        <a href="/">Go To Home</a>
      </div>
    </div>
  );
}

export default NotFound;
