import React, { useState, useEffect, useContext } from "react";
import { Box, useTheme } from "@mui/material";
import CustomDateRange from "../../../components/CustomDateRange";
import { useTranslation } from "react-i18next";
import DashboardCard from "./DashboardCard";
import DashboardLineChart from "./DashboardLineChart";
import PieChart from "./Piecharts";
import OutletDropdown from "../../../components/supportingComponents/outletdropdown";
import OutletContext from "../../../context/OutletContext";
import { updateToken } from "../../../api/axios";
import { baseInstance } from "../../../api/axios";
import DateContext from "../../../context/DateContext";
import { tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

function OnlineReport() {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { OutletId, setOutletId } = useContext(OutletContext);
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateContext);
  const [monthlyData, setmonthlyData] = useState([
    {
      storeName: "",
      salesData: [],
    },
  ]);
  const [isCardLoading, setCardLoading] = useState(true);
  const [loadingTabs, setLoadingTabs] = useState(true);

  const [tabsData, setTabData] = useState({
    tab1: [],
    tab2: [],
    tab3: [],
    tab4: [],
  });

  const [data, setData] = useState({
    total_sale: 0,
    total_tax: 0,
    total_customer: 0,
    salesaverage: 0,
    netsalesvalue: 0,
    paymentpiedata: [["Category", "Value"]],
    productpiedata: [["Category", "Value"]],
    salestypepiedata: [["Category", "Value"]],
  });

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const handleOutletChange = (selectedValue) => {
    setOutletId(selectedValue);
  };

  useEffect(() => {
    if (startDate != null || endDate != null) {
      setCardLoading(true);
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post("/api/dashboard/dashboardonlinereport/cards", {
       
            storeid: OutletId===""?[jwtDecode(localStorage.getItem("token")).outlets[0]?.id]:OutletId,
            startdate: startDate,
            enddate: endDate,
        
        })
        .then((response) => {
          if (response.status === 200) {
            setData(response.data.data);
            setCardLoading(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            handleLogout();
            toast.error("Unauthorized access");
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
          setCardLoading(false);
        });
    }
  }, [startDate, endDate, OutletId]);

  useEffect(() => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/dashboard/dashboardonlinereport/monthlysales", {})
      .then((response) => {
        setmonthlyData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setLoadingTabs(true);
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/dashboard/dashboardonlinereport/dashboardtabs", {
        params: {
          startdate: startDate,
          enddate: endDate,
        }
      })
      .then((response) => {
        setTabData(response.data);
        setLoadingTabs(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingTabs(false);
      });
  }, [startDate, endDate]);

  return (
    <Box m="20px" className="pcoded-main-container">
      <Box
        className="pcoded-content"
        style={{ background: colors.default[100], paddingTop: "0px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {/* Replace with MUI Select component for your dropdown */}
          <CustomDateRange sx={{ paddingRight: "20px" }} />
          <OutletDropdown
            defaultValue={OutletId}
            onChange={handleOutletChange}
          />
        </Box>
        <DashboardCard dataResponse={data} loading={isCardLoading} />
        <DashboardLineChart
          dataResponse={monthlyData}
          dataResponseTab={tabsData}
          loadingTabs={loadingTabs}
        />
        <PieChart dataResponse={data} />
      </Box>
    </Box>
  );
}

export default OnlineReport;
