// Main Report Viewer Component

import React, { useContext } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { baseInstance, updateToken } from "../../api/axios";
import jwtDecode from "jwt-decode";
import DateContext from "../../context/DateContext";
import { useTranslation } from "react-i18next";
import OutletContext from "../../context/OutletContext";
import CustomModal from "./CustomModal";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
// import DateRangePicker from 'react-bootstrap-daterangepicker';

export default function Reportviewer() {
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateContext);

  const { t } = useTranslation();
  const theme = useTheme();
  const { logout } = useAuth();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { OutletId, setOutletId } = useContext(OutletContext);
  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const options = [
    { label: t("Sales"), value: "1" },
    // { label: t("Costing"), value: "2" },
    { label: t("Listing"), value: "3" },
    { label: t("Summary"), value: "4" },
    { label: t("Void_Refunds"), value: "5" },
    // { label: t("Inventory"), value: "6" },
  ];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: colors.default[100],
      height: "50px",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: colors.default[200],
    }),
    option: (base, state) => ({
      ...base,
      background: state.isFocused ? "#e7613a" : colors.default[100],
      color: state.isFocused ? "#ffffff" : colors.default[200],
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#e7613a",
    }),
    menu: (base) => ({
      ...base,
      background: colors.default[100],
    }),
  };

  // load  content
  const [displaycontent, setdisplaycontent] = React.useState({});
  const [selectedOutlets, setSelectedOutlets] = React.useState({});

  const [reprotcontent, setreportcontent] = React.useState({});
  const [reportdata, setreportdata] = React.useState({
    Reportid: "",
    data: "",
  });

  // MODAL
  const [show, setShow] = React.useState(false);

  //loading spinner
  const [isLoadingReport, setIsLoadingReport] = React.useState(false);

  const handleClose = () => setShow(false);

  const [selectedValue, setSelectedValue] = React.useState({
    outlet: "",
    category: "",
    reports: "",
  });

  const [selectedID, setSelectedID] = React.useState({
    // outlet: "",
    category: "",
    reports: "",
  });
  // View Report Function
  const handleShow = () => {
    if (startDate == null || endDate == null) {
      alert("Please Select the date");
    } else if (selectedValue.reports == "") {
      alert("Please select the Report");
    }else if (OutletId.length==0) {
      alert("Please select the Outlet");
    }
     else if (OutletId.length>1 && selectedValue.category=="Listing") {
      alert("Select Only One outlet for the selected report.");
    }
     else if (selectedValue.category == "") {
      alert("Please select the Category");
    } else {
      if (headofficeStatus()) {
      setShow(true);
      setIsLoadingReport(true);
      updateToken(localStorage.getItem("token"));
      const req = baseInstance
        .post("/api/reports/loadreports", {
          storeid:OutletId,
          SelectedID: selectedID,
          startdate: startDate,
          endate: endDate,
        })
        .then((resp) => {
          setreportdata({
            Reportid: resp.data.value,
            data: resp.data.dataresponse,
            ReportLabel: selectedValue.reports,
            OutletLabel: resp.data.dataresponse.selectstore,
            startdates: startDate,
            endates: endDate,
            selectedID: selectedID,
          });
          setIsLoadingReport(false);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
        }); }
    }
    // fetch('/loadreports', {
    //     method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({
    //         SelectedID: selectedID, startdate: startdateVar, endate: enddateVar
    //     })
    // })
    //     .then(res => res.json())
    //     .then(data => {
    //         setreportdata({
    //             Reportid: data.value,
    //             data: data.dataresponse,
    //             ReportLabel: selectedValue.reports,
    //             OutletLabel: selectedValue.outlet,
    //             startdates: startdateVar,
    //             endates: enddateVar,
    //             selectedID: selectedID
    //         })
    //         setIsLoading(false)
    //     })
    //     .catch(error => {
    //         console.log(error)
    //         setIsLoading(false)
    //     })
    // }
  };

  const handleChange = (e) => {
    setSelectedValue({
      ...selectedValue,
      reports: e.label,
    });

    setSelectedID({
      ...selectedID,
      reports: e.value,
    });
  };

  const handleChangeoutlet = (e) => {
    const values = e.map((outlet) => outlet.value);
    setOutletId(values);
    setSelectedOutlets(e);

    // if () {
    //   console.log(e);
    //   // setSelectedValue({
    //   //   ...selectedValue,
    //   //   outlet: e.label,
    //   // });
    //   // setSelectedID({
    //   //   ...selectedID,
    //   //   outlet: e.value,
    //   // });
    // }
  };

  const handleChangecategory = async (e) => {
    try {
      // const token=localStorage.getItem("token");
      updateToken(localStorage.getItem("token"));
      const req = await baseInstance.post(
        "/api/reports/getreportsname",
        { categoryid: e.value },
        {}
      );
      const reportoptions = req.data.reportlist.map((item, index) => ({
        value: item.value,
        label: t(item.label),
      }));
      setreportcontent({
        reportname: reportoptions,
      });
      setSelectedValue({
        ...selectedValue,
        category: e.label,
      });
      setSelectedID({
        ...selectedID,
        category: e.value,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const headofficeStatus = () => {
    const outletlog = jwtDecode(localStorage.getItem("token")).outletsdetails;
    if (OutletId.length === 1) {
      return true;
    } else if (OutletId.length > 1) {
      const isHeadOffice = OutletId.every((outletId) => {
        const outletStatus = outletlog.find((item) => item.id === outletId);
        return outletStatus && outletStatus.headofficeData === 1;
      });
      if (isHeadOffice) {

        return true;
      } else {
        toast.error("The selected Outlets should be Headoffice Outlets");
        return false;
      }
    } else {
      toast.error("The selected Outlets should be Headoffice Outlets Here");
      return false;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    try {
      const fetchOutletsData = jwtDecode(token);
      const transformedData = fetchOutletsData.outlets.map((item) => ({
        value: item.id,
        label: item.storename,
      }));
      setdisplaycontent(transformedData);
      const selectedata = fetchOutletsData.outlets
        .filter((item) => OutletId.includes(item.id))
        .map((item) => ({
          value: item.id,
          label: item.storename,
        }));
        setSelectedOutlets(selectedata);
    } catch {
      console.log("error in token validation");
    }
  }, []);

  return (
    <div className=" h-75 mx-auto w-90 text-align">
      {/* <div className="row  justify-content-center pt-3 font-weight-bold">
                <DateSelector />
            </div> */}

      <div>
        <div className="row align-items-center ">
          <div className="col-md-12 " style={{ textAlign: "center" }}>
            <img
              className=" img-fluidd-inline d-inline"
              src="../../assets/codeplusicons/Code Plus UI-UX-04.svg"
              width="80px"
              height="80px"
            />
            <h6
              className="font-weight-normal d-inline"
              style={{ color: colors.default[400], fontSize: "25px" }}
            >
              <strong> {t("Outlets")} </strong>
            </h6>
            <div
              style={{ width: "60%", margin: "auto", display: "inline-block" }}
              className="pl-4 "
            >
              <Select
                styles={customStyles}
                options={displaycontent}
                value={selectedOutlets}
                isSearchable={false}
                isMulti
                closeMenuOnSelect={false}
                onChange={handleChangeoutlet}
              />
            </div>
          </div>
        </div>

        <div className="row align-items-center pt-2">
          <div
            className="col-md-12 col-md-large pl-4"
            style={{ textAlign: "center" }}
          >
            <img
              className=" img-fluidd-inline d-inline"
              src="../../assets/codeplusicons/Code Plus UI-UX-05.svg"
              width="50px"
              height="50px"
            />
            <h6
              className="font-weight-normal d-inline"
              style={{ color: colors.default[400], fontSize: "21px" }}
            >
              <strong> {t("Category")} </strong>
            </h6>
            <div
              style={{
                width: "75%",
                paddingLeft: "25px",
                margin: "auto",
                display: "inline-block",
              }}
            >
              <Select
                styles={customStyles}
                options={options}
                isSearchable={false}
                onChange={handleChangecategory}
              />
            </div>
          </div>
        </div>

        <div className="row align-items-center pt-4">
          <div
            className="col-md-12 col-md-large pl-4"
            style={{ textAlign: "center" }}
          >
            <img
              className=" img-fluidd-inline d-inline"
              src="../../assets/codeplusicons/Code Plus UI-UX-06.svg"
              width="50px"
              height="50px"
            />
            <h6
              className="font-weight-normal d-inline"
              style={{ color: colors.default[400], fontSize: "21px" }}
            >
              <strong> {t("Reports")} </strong>
            </h6>
            <div
              style={{
                width: "75%",
                paddingLeft: "30px",
                margin: "auto",
                display: "inline-block",
              }}
            >
              <Select
                styles={customStyles}
                options={reprotcontent.reportname}
                onChange={handleChange}
                isSearchable={false}
              />
            </div>
          </div>
        </div>

        <div className="row align-items-center pt-4">
          <div
            className="col-md-12 col-md-large pl-4"
            style={{ textAlign: "end", paddingRight: "15%" }}
          >
            <div style={{ textAlign: "end", display: "inline-block" }}>
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={handleShow}
              >
                {t("View")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        show={show}
        handleClose={handleClose}
        spinnerstatus={isLoadingReport}
        reportdata={reportdata}
        storeid={OutletId}
      />
    </div>
  );
}
