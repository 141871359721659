import { useEffect, useState, createElement } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { MenuOutlined } from "@mui/icons-material";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import jwtDecode from "jwt-decode";
import useAuth from "../../hooks/useAuth";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import { useTranslation } from "react-i18next";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import InstallDesktopOutlinedIcon from '@mui/icons-material/InstallDesktopOutlined';
import MoveUpOutlinedIcon from '@mui/icons-material/MoveUpOutlined';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PrintIcon from '@mui/icons-material/Print';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';

const iconComponents = {
  HomeOutlinedIcon,
  PeopleOutlineOutlinedIcon,
  MenuOutlined,
  AccountBalanceOutlinedIcon,
  AssessmentOutlinedIcon,
  ExitToAppOutlinedIcon,
  InfoOutlinedIcon,
  ReceiptLongOutlinedIcon,
  ReceiptOutlinedIcon,
  AddBusinessOutlinedIcon,
  MenuBookOutlinedIcon,
  ArrowRightRoundedIcon,
  GroupOutlinedIcon,
  BusinessOutlinedIcon,
  WorkspacesOutlinedIcon,
  StorefrontOutlinedIcon,
  AccountCircleOutlinedIcon,
  AdminPanelSettingsOutlinedIcon,
  AnalyticsIcon,
  ManageSearchIcon,
  SummarizeIcon,
  AutoGraphIcon,
  SensorsOutlinedIcon,
  InstallDesktopOutlinedIcon,
  MoveUpOutlinedIcon,
  WhatsAppIcon,
  LocalGroceryStoreIcon,
  PrintIcon,
  CompareArrowsRoundedIcon,
  AccessTimeIcon,
  CalculateOutlinedIcon
};

const Item = ({ title, to, icon, selected, setSelected, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = () => {
    setSelected(title);
    if (onClick) {
      onClick();
    }
  };
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={handleClick}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

function SideBar({ isSidebarVisible }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [entityList, setEntityList] = useState([]);
  const { t } = useTranslation();

  const handleLogout = async (e) => {
    // e.preventDefault();
    // setError("");
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
      // } else {
      //   navigate("/auth/login", { replace: true });
      //   console.log("error");
      // }
    }
  };
  useEffect(() => {
    const fetchlist = jwtDecode(localStorage.getItem("token")).roles;
    setEntityList(fetchlist);
  }, []);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.secondary[500]} !important`,
        },
        "& .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content":
          {
            flexGrow: `0`,
          },

        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 10px !important",
        },

        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },

        height: "100%",
        "@media (max-width: 991px)": {
          // display: isSidebarVisible ? "block" : "none", 
          display: isSidebarVisible ? "block" : "none", // Show or hide based on the prop
          // Show or hide based on the prop
          marginLeft: isSidebarVisible ? "0px" : "-235px",
          // position: "absolute",
          
       },
        
      }}
    >
      <ProSidebar
        collapsed={isCollapsed}
        width="210px"
        style={{
          overflow: "auto",
          position: "fixed",
          boxShadow: "0 1px 2.94px 0.06px #ffffff",
          
        }}
        // width: isCollapsed ? "210px" : 250 }}
      >
        <Menu iconShape="square">
          <MenuItem
            // onClick={() => setIsCollapsed(!isCollapsed)}
            // icon={isCollapsed ? <MenuOutlined /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {/* {!isCollapsed && (
              <Box
                display="flex"
                // justifyContent="space-between"
                alignItems="right"
                ml="15px"
              >
                 <Typography variant="h3" color={colors.grey[100]}>
                  Codeplus
                </Typography> 
                <IconButton>
                  <MenuOutlined />
                </IconButton>
              </Box>
            )} */}
          </MenuItem>

          {/* USER */}
          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src="../../assets/icon 1.png"
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              {/* <Box textAlign={"Center"}>
                <Typography variant="h2" color={colors.grey[100]}>
                  Rayyan
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  Admin
                </Typography>
              </Box> */}
            </Box>
          )}
          <Box paddingLeft={isCollapsed ? undefined : "0%"}>
            {entityList.map((entity) =>
             entity.isMenu ? (
              
              entity.isParent ? (
                <SubMenu
                  title={t(entity.EntityName)}
                  style={{ color: colors.grey[100] }}
                  icon={createElement(iconComponents[entity.Icon])}
                >
                  {/* Render child entities */}
                  {entityList
                    .filter(
                      (childEntity) =>
                        childEntity.ModuleCode === entity.ModuleCode &&
                        !childEntity.isParent
                    )
                    .map((childEntity) => (
                      <Item
                        title={t(childEntity.EntityName)}
                        to={childEntity.Path}
                        icon={createElement(iconComponents[childEntity.Icon])}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    ))}
                </SubMenu>
              ) : (
                !entityList.some(
                  (childEntity) =>
                    childEntity.ModuleCode === entity.ModuleCode &&
                    childEntity.isParent
                ) && (
                  <Item
                    title={t(entity.EntityName)}
                    to={entity.Path}
                    icon={createElement(iconComponents[entity.Icon])}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )
              )
              ) : null
            )}
            <Item
              title={t("Logout")}
              icon={<ExitToAppOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              onClick={handleLogout}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
}

export default SideBar;
