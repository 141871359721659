import React, { useState,useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material";
const LineChart = (props) => {
  const theme = useTheme();

  // const sampleData = [
  //   { x: 1643012400000, y: 100 },
  //   { x: 1642926000000, y: 120 },
  //   { x: 1642839600000, y: 90 },
  //   { x: 1642753200000, y: 110 },
  //   { x: 1642666800000, y: 80 },
  //   { x: 1642580400000, y: 130 },
  //   { x: 1642494000000, y: 70 },
  //   { x: 1642407600000, y: 150 },
  //   { x: 1642321200000, y: 60 },
  //   { x: 1642234800000, y: 140 },
  //   { x: 1642148400000, y: 100 },
  //   { x: 1642062000000, y: 120 },
  //   { x: 1641975600000, y: 90 },
  //   { x: 1641889200000, y: 110 },
  //   { x: 1641802800000, y: 80 },
  //   { x: 1641716400000, y: 130 },
  //   { x: 1641630000000, y: 70 },
  //   { x: 1641543600000, y: 150 },
  //   { x: 1641457200000, y: 60 },
  //   { x: 1641370800000, y: 140 },
  //   // Add more data points as needed
  // ];
  const [chartData, setChartData] = useState({
    series: [
      {
        name: props.name,
        data: props.data, 
      },
    ],
    options: {
        colors: [props.colors],
      theme: {
        mode: "light",
      },
      chart: {
        type: "area",
        stacked: false,
        height: 330,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
 
      fill: {
        
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return (val).toFixed(0);
          },
        },
        title: {
          text: "Amount",
        },
      },
      xaxis: {
        type: "date",
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return (val).toFixed(0);
          },
        },
      },
    },
  });

//   useEffect(() => {
//     setChartData({
//       ...chartData,
//       series: [
//         {
//           name: props.name,
//           data: props.linechartdata,
//         },
//       ],
//     });


//   }, [props.linechartdata]);

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      options: {
        ...prevChartData.options,
        
        theme: {
          ...prevChartData.options.theme,
          mode: theme.palette.mode, // Update categories from props
        },
      },
     
    }));
  }, [theme.palette.mode]);

  return (
    <div id="chart" style={{padding:"10px",width:"100%"}}>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={330}
        
      />
    </div>
  );
};

export default LineChart;
