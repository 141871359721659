import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';

const ApexChart = ({ data }) => {
  const {t} =useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const lightTheme = {
    theme: {
      mode: "light",
    },
    colors: ['#106e8a', '#ed6943', '#4f6f8f', '#c75937', '#2c8e7e'],
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      foreColor: colors.default[200],
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false, // Hide toolbar options
      },
      
    },
    
    dataLabels: {
      enabled: false,
     
    },
    markers: {
      size: 0,
    },
    title: {
      text: t('Monthly_Sales'),
      align: 'center',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return (val ).toFixed(0);
        },
      },
      title: {
        text: 'Sales',
      },
    },
    xaxis: {
      type: 'datetime', 
      
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return (val ).toFixed(0);
        },
      },
    },
    // Other options for light theme
  };
  
  const darkTheme = {
    theme: {
      mode: "dark",
    },
    colors: ['#106e8a', '#ed6943', '#4f6f8f', '#c75937', '#2c8e7e'],
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      foreColor: colors.default[200],
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false, // Hide toolbar options
      },
      
    },
    
    dataLabels: {
      enabled: false,
     
    },
    markers: {
      size: 0,
    },
    title: {
      text: t('Monthly_Sales'),
      align: 'center',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return (val ).toFixed(0);
        },
      },
      title: {
        text: 'Sales',
      },
    },
    xaxis: {
      type: 'datetime', 
      
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return (val ).toFixed(0);
        },
      },
    },
    // Other options for dark theme
  };
  const options = {
    ...lightTheme,

    colors: ['#106e8a', '#ed6943', '#4f6f8f', '#c75937', '#2c8e7e'],
    chart: {
      type: 'area',
      stacked: false,
      height: 350,
      foreColor: colors.default[200],
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false, // Hide toolbar options
      },
      
    },
    
    dataLabels: {
      enabled: false,
     
    },
    markers: {
      size: 0,
    },
    title: {
      text: t('Monthly_Sales'),
      align: 'center',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return (val ).toFixed(0);
        },
      },
      title: {
        text: 'Sales',
      },
    },
    xaxis: {
      type: 'datetime', 
      
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val) {
          return (val ).toFixed(0);
        },
      },
    },
  };
  
  const series = data.map((item) => ({
    name: item.storeName,
    data: item.salesData.map(([date, value]) => [new Date(date).getTime(), value]),
  }));

  return (
    <div id="chart">
      <ReactApexChart options={theme.palette.mode==="dark" ? darkTheme : lightTheme} series={series} type="area" height={350} />
    </div>
  );
};

export default ApexChart;
