import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { updateToken } from "../../../../../api/axios";
import { baseInstance } from "../../../../../api/axios";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TextField, useTheme, Checkbox } from "@mui/material";
import { tokens } from "../../../../../theme";
import CustomLoadingOverlay from "../../../../../components/supportingComponents/CustomLoadingOverLay";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";

const ViewUpdateRole = ({ isOpen, onClose, roleId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const [selectedRows, setSelectedRows] = useState([]);
  const { logout } = useAuth();
  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };
  const handleCheckboxChange = (row, field) => {
    setRows((prevData) =>
      prevData.map((item) =>
        item.id === row.id ? { ...item, [field]: !item[field] } : item
      )
    );
  };
  const columns = [
    {
      field: "id",
      headerName: "id",
      headerClassName: "th-datagrid-theme",
    },
    {
      field: "entitycode",
      headerName: "Access",
      flex: 0.3,
      headerClassName: "th-datagrid-theme",
    },
    {
        field: "module",
        headerName: "Module",
        flex: 0.3,
        headerClassName: "th-datagrid-theme",
      },
    {
      field: "allow",
      headerName: "Allow",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      renderCell: (params) => (
        <Checkbox
          checked={params.row.allowChecked}
          onChange={() => handleCheckboxChange(params.row, "allowChecked")}
        />
      ),
    },
    {
      field: "new",
      headerName: "New",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
      renderCell: (params) => (
        <Checkbox
          checked={params.row.newChecked}
          onChange={() => handleCheckboxChange(params.row, "newChecked")}
        />
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
      renderCell: (params) => (
        <Checkbox
          checked={params.row.editChecked}
          onChange={() => handleCheckboxChange(params.row, "editChecked")}
        />
      ),
    },
    {
      field: "del",
      headerName: "Del",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
      renderCell: (params) => (
        <Checkbox
          checked={params.row.delChecked}
          onChange={() => handleCheckboxChange(params.row, "delChecked")}
        />
      ),
    },
    {
      field: "printing",
      headerName: "Printing",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
      renderCell: (params) => (
        <Checkbox
          checked={params.row.printingChecked}
          onChange={() => handleCheckboxChange(params.row, "printingChecked")}
        />
      ),
    },
  ];

  const handleApiRequest = async () => {
    let response;
    updateToken(localStorage.getItem("token"));
    try {
      response = await baseInstance.get(
        "/api/administration/userrole/createuserrole/userroledetail",
        {
          params: {
            roleid: roleId,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleApiRequest()
        .then((response) => {
          if (response.status === 200) {
            const initialDataWithCheckboxes = response.data.data.map(
              (item) => ({
                ...item,
                allowChecked: item.allow,
                editChecked: item.edit,
                newChecked: item.new,
                delChecked: item.del,
                printingChecked: item.printing,
              })
            );
            setRows(initialDataWithCheckboxes);
          } else {
            console.log("Request failed with status:", response.status);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
        });
    }
  }, [isOpen]);

  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const handleApiPostRequest = async () => {
    let response2;
    updateToken(localStorage.getItem("token"));
    try {
      // Creating new vendor
      response2 = await baseInstance.put(
        "/api/administration/userrole/createuserrole/userroledetail",
        { ApiData: { rows, roleId } }
      );
      return response2;
    } catch (error) {
      throw error;
    }
  };

  const handleClick = () => {
    // const selectedData = selectedRows.map((rowId) => {
    //   const row = rows.find((r) => r.id === rowId);
    //   return row;
    // });
    handleApiPostRequest()
      .then((response) => {
        if (response.status === 200) {
          //   setRows(response.data);
          onClose();
          toast.success("Record Created!");
        } else {
          console.log("Request failed with status:", response.status);
          toast.error("Record Failed!");
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
          toast.error("Record Failed!");
        }
      });
  };

  return (
    <Modal dialogClassName="modal-70w" show={isOpen} onHide={onClose}>
      <Modal.Header style={{ backgroundColor: colors.default[100] }}>
        <Modal.Title>Role Details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: colors.default[100] }}>
        <div>
          <DataGrid
            rows={rows}
            sx={{
              "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                  display: "none",
                },
              "& .MuiDataGrid-columnHeader": {
                background: "#106e8a",
              },
            }}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100,
                },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
              Header: ({ column }) => (
                <div className="th-datagrid-theme">{column.headerName}</div>
              ),
            }}
            style={{ height: 630 }}
            componentsProps={{
              loadingOverlay: {
                style: { zIndex: 9999 },
              },
            }}
            pageSizeOptions={[5]}
          />
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: colors.default[100] }}>
        <Button variant="primary" onClick={handleClick}>
          Save
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewUpdateRole;
