import { Box, Typography, useTheme } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import PageHeader from "../../../components/PageHeader";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import OutletContext from "../../../context/OutletContext";
import jwtDecode from "jwt-decode";
import { CustomNoRowsOverlay } from "../../../components/supportingComponents/CustomNoRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";
import AddCategory from "./Modal";
import { updateToken, baseInstance } from "../../../api/axios";
import { Form, Input, Row, Col, Select } from "antd";
import { ConfigProvider, theme } from "antd";
import { DatePicker, CustomProvider } from "rsuite";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Popconfirm } from "antd";

const SubwaySetup = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const themecustom = useTheme();
  const [dataSelected, setDataSelected] = useState(0);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const [selectedOutlet, setSelectedOutlet] = useState(OutletId[0]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimeString, setSelectedTimeString] = useState(null);
  const [selectedTimeDate, setSelectedTimeDate] = useState(new Date());
  const [outlets, setOutlets] = useState([]);
  const [addCategoryModalOpen, setaddCategoryModalOpen] = useState(false);
  const [categoryRows, setCategoryRows] = useState([]);
  const [form] = Form.useForm();

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const handleTimeChange = (date) => {
    const timePart = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    setSelectedTimeString(timePart);
    setSelectedTimeDate(date);
  };

  const handleAdd = (params) => {
    setaddCategoryModalOpen(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    try {
      const fetchOutletsData = jwtDecode(token);
      setOutlets(fetchOutletsData.outlets);
    } catch {
      console.log("error in token validation");
    }
  }, []);

  const LoadPageContent = async () => {
    let response;
    updateToken(localStorage.getItem("token"));
    response = await baseInstance
      .post("/api/integration/subwaysetup", {
        storeid:
          selectedOutlet === ""
            ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
            : selectedOutlet,
      })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          form.setFieldsValue({
            vendorName: data.vendorName === null ? "" : data.vendorName,
            StoreNo: data.storeNo === null ? "" : data.storeNo,
            vendorId: data.vendorId === null ? "" : data.vendorId,
            vendorCountry:
              data.vendorCountry === null ? "" : data.vendorCountry,
            selectedDay: data.dayofweek === null ? 1 : data.dayofweek,
            selectedendDay: data.dayofweekend === null ? 1 : data.dayofweekend,

            selectedDate:
              data.startDate === null ? new Date() : new Date(data.startDate),
          });
          setSelectedDate(
            data.startDate === null ? new Date() : new Date(data.startDate)
          );
          const dateFromString = data.dayofweekendtime
            ? new Date(data.dayofweekendtime)
            : new Date();
          const gmtString = dateFromString.toLocaleString("en-US", {
            timeZone: "GMT",
          });
          const endTimeGMT = new Date(gmtString);
          setSelectedTimeDate(endTimeGMT);
          setSelectedTimeString(
            data.dayofweekendtimeString === null
              ? "15:00"
              : data.dayofweekendtimeString
          );

          setCategoryRows(data.categorylist===undefined ? [] :data.categorylist);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          handleLogout();
          toast.error("Unauthorized access");
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    LoadPageContent();
  }, [selectedOutlet]);

  const optionsDays = [
    { label: t("Sunday"), value: 0 },
    { label: t("Monday"), value: 1 },
    { label: t("Tuesday"), value: 2 },
    { label: t("Wednesday"), value: 3 },
    { label: t("Thursday"), value: 4 },
    { label: t("Friday"), value: 5 },
    { label: t("Saturday"), value: 6 },
  ];

  const optionsDaysEndDay = [
    { label: t("Sunday"), value: 0 },
    { label: t("Monday"), value: 1 },
    { label: t("Tuesday"), value: 2 },
    { label: t("Wednesday"), value: 3 },
    { label: t("Thursday"), value: 4 },
    { label: t("Friday"), value: 5 },
    { label: t("Saturday"), value: 6 },
  ];

  const handleApiRequest = async () => {
    updateToken(localStorage.getItem("token"));
    try {
      let response;
      const fieldValues = form.getFieldsValue();
      response = await baseInstance.put("/api/integration/subwaysetup", {
        ApiData: {
          vendorName: fieldValues.vendorName,
          StoreNo: fieldValues.StoreNo,
          vendorId: fieldValues.vendorId,
          vendorCountry: fieldValues.vendorCountry,
          selectedDay: fieldValues.selectedDay,
          selectedOutlet: fieldValues.selectedOutlet,
          selectedDayEnd: fieldValues.selectedendDay,
          selectedTimeString,
          selectedTimeDate,
          selectedDate,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleRowClick = (params) => {
    const { id } = params.row;
    setDataSelected(id);
  };

  const handleClick = () => {
    handleApiRequest()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Posted API Details!!");
        } else {
          console.log("Request failed with status:", response.status);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
        toast.error("Record Failed!");
        console.log(error);
      });
  };

  const handleDelete = async () => {
    updateToken(localStorage.getItem("token"));
    if (dataSelected == 0) {
      toast.error("Select The Data to delete!");
    } else {
      try {
        let response;
        response = await baseInstance
          .put("/api/integration/subwaysetup/addapidata", {
            ApiData: {
              dataSelected,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              toast.success("Deleted Succussfully");
              LoadPageContent();
            } else {
              console.log("Request failed with status:", response.status);
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              toast.error(error.response.data.message);
            } else if (error.response.status === 403) {
              navigate(`/`);
            } else if (error.response.status === 401) {
              handleLogout();
            } else {
              console.error(error);
            }
          });
      } catch (error) {
        throw error;
      }
    }
  };

  const columns = [
    {
      field: "id",
      headerName: t("ID"),
      flex: 0.3,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "SubwayCategoryName",
      headerName: t("Subway Category Name"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "ListData",
      headerName: t("Data"),
      flex: 0.5,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "Field",
      headerName: t("Selection By"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "ValueBy",
      headerName: t("Value By"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  return (
    <>
      <AddCategory
        isOpen={addCategoryModalOpen !== false}
        LoadPageContent={LoadPageContent}
        selectedOutlet={selectedOutlet}
        onClose={() => {
          setaddCategoryModalOpen(false);
        }}
      />
      <Box
        className=" h-75 mx-auto w-90 text-align "
        sx={{ width: "100%", paddingBottom: "20px" }}
      >
        <PageHeader title= {t("Api_Setup")}
                 />
        <Box
          sx={{
            marginBottom: "15px",
            textAlign: t("langDir"),
            padding: 3,
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)",
            borderRadius: 3,
            marginBottom: 2,
          }}
        >
          <ConfigProvider
            theme={{
              algorithm:
                themecustom.palette.mode == "dark"
                  ? darkAlgorithm
                  : defaultAlgorithm,
            }}
          >
            <Form
              form={form}
              name="subwaySetupForm"
              layout="horizontal"
              initialValues={{
                layout: "horizontal",
                selectedOutlet: selectedOutlet,
                selectedDay: 0,
                selectedendtime: selectedTimeDate,
              }}
              sx={{ display: "flex", flexDirection: "column" }}
              onFinish={handleClick}
            >
              <Form.Item style={{ textAlign: "right" }} name="selectedOutlet">
                <Select
                  size="medium"
                  style={{
                    minWidth: 250,
                    maxWidth: 250,
                    marginBottom: "5px",
                    textAlign: "left",
                  }}
                  defaultValue={selectedOutlet}
                  onChange={(value) => {
                    setSelectedOutlet(value);
                  }}
                >
                  {outlets.map((outlet) => (
                    <Select.Option value={outlet.id}>
                      {outlet.storename}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Typography
                variant="h5"
                sx={{ marginBottom: "10px", textAlign: t("langDir") }}
                fontWeight="bold"
              >
                {" "}
                {t("Vendor_Details")}{" "}
              </Typography>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Vendor Name"
                    name="vendorName"
                    rules={[
                      {
                        required: true,
                        message: "Vendor Name is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Store No"
                    name="StoreNo"
                    rules={[
                      {
                        required: true,
                        message: "Store No. is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Vendor Id"
                    name="vendorId"
                    rules={[
                      {
                        required: true,
                        message: "Vendor Id is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Vendor Country"
                    name="vendorCountry"
                    rules={[
                      {
                        required: true,
                        message: "Vendor Country is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Typography
                variant="h5"
                sx={{
                  marginTop: "20px",
                  marginBottom: "10px",
                  textAlign: t("langDir"),
                }}
                fontWeight="bold"
              >
                {" "}
                {t("Time & Day Setups")}{" "}
              </Typography>
              <Row gutter={16}>
                <Col span={5}>
                  <Form.Item
                    name="selectedDay"
                    label={t("Start_Day")}
                    
                    rules={[
                      {
                        required: true,
                        message: "Select Day is required",
                      },
                    ]}
                  >
                    <Select size="medium" defaultValue={0} >
                      {optionsDays.map((day) => (
                        <Select.Option value={day.value}>
                          {day.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="selectedendDay"
                    label={t("End_Due_Day")}
                    rules={[
                      {
                        required: true,
                        message: "Select End Day is required",
                      },
                    ]}
                  >
                    <Select size="medium" defaultValue={0} >
                      {optionsDaysEndDay.map((day) => (
                        <Select.Option value={day.value}>
                          {day.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="selectedendtime"
                    label={t("End_Due_Time_EST")}
                    rules={[
                      {
                        required: true,
                        message: "Select End Time is required",
                      },
                    ]}
                  >
                    <CustomProvider theme={themecustom.palette.mode}>
                      <DatePicker
                        format="HH:mm"
                        // value={selectedTime}
                        
                        value={selectedTimeDate}
                        onChange={handleTimeChange}
                        style={{ width: 260 }}
                      />
                    </CustomProvider>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="selectedDate"
                    label={t("Start_Date")}
                    rules={[
                      {
                        required: true,
                        message: "Start Date is required",
                      },
                    ]}
                  >
                    <CustomProvider theme={themecustom.palette.mode}>
                      <DatePicker
                      
                        style={{ width: "100%" }}
                        value={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                      />
                    </CustomProvider>
                  </Form.Item>
                </Col>
              </Row>

              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h5"
                  sx={{
                    marginTop: "20px",
                    marginBottom: "10px",
                    textAlign: t("langDir"),
                    fontWeight: "bold",
                  }}
                >
                  {t("Data Setup")}
                </Typography>
                <div>
                  <Popconfirm
                    placement="leftTop"
                    title="Are you sure to delete?"
                    description="Delete API Data" 
                    okText="Yes"
                    cancelText="No"
                    onConfirm={handleDelete}
                  >
                    <IconButton
                      size="small"
                      color="primary"
                      style={{ marginTop: "10px", marginRight: "10px" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Popconfirm>

                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    style={{ height: "30px", marginTop: "10px" }}
                    onClick={handleAdd}
                  >
                    Add Data
                  </button>
                </div>
              </Box>

              <DataGrid
                // getRowHeight={() => 'auto'}
                rows={categoryRows}
                columns={columns}
                onRowClick={handleRowClick}
                slots={{
                  noRowsOverlay: () => (
                    <CustomNoRowsOverlay message={t("No Category")} />
                  ),
                }}
                style={{ height: 300, marginBottom: "15px" }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 4,
                    },
                  },
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
              />
              <Box
                sx={{
                  textAlign: "right",
                  marginTop: "20px",
                  paddingBottom: "40px",
                }}
              >
                <button type="submit" className="btn btn-sm btn-primary">
                  {t("Save")}
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  style={{ marginLeft: "10px" }}
                  onClick={() => form.resetFields()}
                >
                  {t("Cancel")}
                </button>
              </Box>
            </Form>
          </ConfigProvider>
        </Box>
      </Box>
    </>
  );
};

export default SubwaySetup;
