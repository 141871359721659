import React, { createContext, useState } from "react";
import { startOfDay, endOfDay } from "date-fns";

const DateContext = createContext();

export default DateContext;

export const DateContextProvider = ({ children }) => {

  const currentDate = new Date();
  const currentOffset = currentDate.getTimezoneOffset();


  let localStartofToday, localEndofToday;

  if (currentDate.getHours() < 3) {

    localStartofToday = new Date(
      startOfDay(
        new Date(currentDate.getTime() - 24 * 60 * 60 * 1000)
      ).getTime() -
        currentOffset * 60000
    );
    localEndofToday = new Date(
      startOfDay(
        new Date(currentDate.getTime() - 24 * 60 * 60 * 1000)
      ).getTime() -
        currentOffset * 60000
    );
  } else {
    localStartofToday = new Date(
      startOfDay(new Date()).getTime() - currentOffset * 60000
    );
    localEndofToday = new Date(
      startOfDay(new Date()).getTime() - currentOffset * 60000
    );
  }

  const [startDate, setStartDate] = useState(localStartofToday);
  const [endDate, setEndDate] = useState(localEndofToday);

  return (
    <DateContext.Provider
      value={{ startDate, setStartDate, endDate, setEndDate }}
    >
      {children}
    </DateContext.Provider>
  );
};
