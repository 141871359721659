import React, { useState, useEffect, useContext } from "react";
import { Box,useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import DashboardCard from "./DashboardCard";

import { updateToken } from "../../../api/axios";
import { baseInstance } from "../../../api/axios";
import DateContext from "../../../context/DateContext";
import { tokens } from "../../../theme";


function AdminDashboard() {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const [isCardLoading,setCardLoading]=useState(true);

  

  const [data, setData] = useState({
    total_sale: 0,
    total_tax: 0,
    total_customer: 0,
    salesaverage: 0,
    netsalesvalue: 0,
    
  });



//   useEffect(() => {
//     if (startDate != null || endDate != null) {
//       setCardLoading(true);
//       updateToken(localStorage.getItem("token"));
//       baseInstance
//         .get("/api/getdashboardonlinereport/cards", {
//           params: {
//             storeid: OutletId,
//             startdate: startDate,
//             enddate: endDate,
//           },
//         })
//         .then((response) => {
         
//           setData(response.data.data);
//           setCardLoading(false);
//         })
//         .catch((error) => {
//           console.error(error);
//           setCardLoading(false);
//         });
//     }
//   }, [startDate, endDate, OutletId]);

//   useEffect(() => {
//     updateToken(localStorage.getItem("token"));
//     baseInstance
//       .get("/api/getdashboardonlinereport/monthlysales", {})
//       .then((response) => {
//         setmonthlyData(response.data);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }, []);

//   useEffect(()=>{
//     updateToken(localStorage.getItem("token"));
//     baseInstance
//       .get("/api/getdashboardonlinereport/dashboardtabs", {})
//       .then((response) => {
//         setTabData(response.data);
//       })
//       .catch((error) => {
//         console.error(error);
//       });


//   },[]);

  return (
    <Box m="20px" className="pcoded-main-container">
      <Box
        className="pcoded-content"
        style={{ background: colors.default[100], paddingTop: "0px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {/* Replace with MUI Select component for your dropdown */}
         
        </Box>
        <DashboardCard dataResponse={data}/>
       
      </Box>
    </Box>
  );
}

export default AdminDashboard;
