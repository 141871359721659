import React from 'react'
import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useTranslation } from 'react-i18next';



function LineRechartComponent(props){
    const {t}=useTranslation();
    const data =props.linecharts
    return (
        <LineChart width={730} height={250} data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={t("Daily_Sales")} stroke="#FF0000" />
        </LineChart>
    )

}


export default function DailySales(props) {
    const {t}=useTranslation();
    return (
        <div>
            <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>
                <h6 ></h6>
            </div>

            <table id="dailysalesviewtable" style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Date')}</td>
                        <td>{t('Transactions')}</td>
                        <td>{t('Net_Total')}</td>
                        <td>{t('Final_Total')}</td>
                        <td> % {t('Net_Total')}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.tabledata.map((item, index) => (
                            <tr key={index}>
                                <td>{item.opendate}</td>
                                <td>{item.transact}</td>
                                <td>{item.nettotal}</td>
                                <td>{item.finaltotal}</td>
                                <td>{item.netpercentage}</td>

                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div>
            <LineRechartComponent   linecharts={props.data.linecharts}/>

            </div>
        </div>
    )
}


