import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { color, typography } from "@mui/system";

export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#ffffff",
          200: "#efefef",
          300: "#dfdede",
          400: "#bebebe",
          500: "#969696",
          600: "#858585",
          700: "#595959",
          800: "#3a3a3a",
          900: "#000000",
        },

        primary: {
          100: "#bcbcbd",
          200: "#909092",
          300: "#797a7c",
          400: "#4d4d50",
          500: "#202124",
          600: "#1d1e20",
          700: "#161719",
          800: "#101112",
          900: "#030304",
        },

        secondary: {
          100: "#bcbcbd",
          200: "#909092",
          300: "#797a7c",
          400: "#4d4d50",
          500: "#202124",
          600: "#1d1e20",
          700: "#161719",
          800: "#101112",
          900: "#030304",
        },
        default:{
            100:"#202124", //background
            200:"#ffffff" ,//text
            300:"#ffffff",// for primary
            300:"#ffffff"// for secondary

        }
      } //light
    : {
        grey: {
          100: "#ffffff",
          200: "#efefef",
          300: "#dfdede",
          400: "#bebebe",
          500: "#969696",
          600: "#858585",
          700: "#595959",
          800: "#3a3a3a",
          900: "#000000",
        },

        primary: {
          100: "#ffa78f",
          200: "#f79074",
          300: "#fa7c5a",
          400: "#fc734e",
          500: "#ED6943",
          600: "#fc5d32",
          700: "#e9562d",
          800: "#e74113",
          900: "#ed3300",
        },

        secondary: {
          100: "#416a8e",
          200: "#417d8f",
          300: "#2b7e97",
          400: "#1e7c99",
          500: "#106e8a",
          600: "#0c7494",
          700: "#0e6985",
          800: "#095d76",
          900: "#055770",
        },
        default:{
            100:"#ffffff",
            200:"#000000", //text
            300:"#ED6943",// for primary
            400:"#106e8a"// for secondary



        }
      }),
});

//material ui theme settings

export const themeSettings = (mode) => {
  const colors = tokens(mode);

  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.secondary[500],
            },
            neutral: {
              main: colors.grey[500],
              dark: colors.grey[700],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.secondary[500],
            },
            neutral: {
              main: colors.grey[500],
              dark: colors.grey[700],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

//context for the color mode

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = (initialmode) => {
  const [mode, setMode] = useState(initialmode);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
