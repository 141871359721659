import React, { createContext, useState } from 'react';
import jwtDecode from 'jwt-decode';

const OutletContext = createContext();

export default OutletContext;

export const OutletContextProvider = ({ children }) => {
  let tokenAuth=localStorage.getItem("token");
  const [OutletId, setOutletId] = useState(tokenAuth ===null ?[]:[jwtDecode(tokenAuth).outlets[0]?.id]);
  return (
    <OutletContext.Provider value={{ OutletId,setOutletId }}>
      {children}
    </OutletContext.Provider>
  );
};
