import React from 'react'
import { useTranslation } from 'react-i18next';

export default function SalesByCategory(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div class="modal-body">
                <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                    <h6 id="fromtodatesalebytype"></h6>
                </div>

                <table id="salesbytypeviewtable" style={{ width: "100%" }}>
                    <thead>
                    <tr className="headingtop">
                        <td>{t('Desc')}</td>
                        <td>{t('Trans')}</td>
                        <td>{t('Trans%')}</td>
                        <td>{t('Value')}</td>
                        <td>{t('Value%')}</td>

                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.data.querysalesdatacategory.map((item,index) => (
                            <tr key={index}>
                                <td>{item.descript}</td>
                                <td>{item.qty}</td>
                                <td>{item.qty_percent}</td>
                                <td>{item.value}</td>
                                <td>{item.value_percent}</td>
                            </tr>
                        ))
                    }
                    </tbody>

                    <tr>
                        <td className='font-weight-bold'>{t('Total')}</td>
                        <td className='font-weight-bold'>{props.data.totalqty}</td>
                        <td></td>
                        <td className='font-weight-bold'>{props.data.totalvalue}</td>
                    </tr>
                </table>
            </div>
        </div>
    )
}