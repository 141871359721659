import React, { useState } from "react";
import {
  Card,
  CardContent,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme";
import OutletContext from "../../../context/OutletContext";
import { useContext } from "react";

const DashboardTabTable = (props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };


  const maxRows = 20; // Maximum number of rows to display

  // Function to render empty rows as placeholders
  const renderEmptyRows = (rowCount) => {
    const emptyRowCount =rowCount+1;
    return Array(emptyRowCount)
      .fill()
      .map((_, index) => (
        <TableRow
          key={rowCount + index}
          sx={
            index % 2 !== 0
              ? { background: "#f5f5f5" }
              : { background: colors.grey[100] }
          }
        >
          <TableCell colSpan={3}>&nbsp;</TableCell>
        </TableRow>
      ));
  };

  return (
    <Card
      sx={{
        backgroundColor: colors.primary[500],
        borderRadius: "10px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        maxWidth: 600, // Adjust the maximum width as needed
        margin: "auto", // Center the card horizontally
        height: "97%",
      }}
    >
      <div style={{ overflowX: "auto" }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#fff"
          sx={{
            width: "100%",
            backgroundColor: colors.primary[500],
            color: "white",
          }}
        >
          <Tab
            label={t("Top_Outlet")}
            sx={{ backgroundColor: colors.primary[500], color: "white" }}
          />
          <Tab
            label={t("Top_Busy_Hour")}
            sx={{ backgroundColor: colors.primary[500], color: "white" }}
          />
          <Tab
            label={t("Top_Section_By_Sale")}
            sx={{ backgroundColor: colors.primary[500], color: "white" }}
          />
          <Tab
            label={t("Top_Menu_Item")}
            sx={{ backgroundColor: colors.primary[500], color: "white" }}
          />
        </Tabs>
      </div>
      <CardContent sx={{ padding: "0px" }}>
        {currentTab === 0 && (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 400, height: 400, overflow: "auto" }}
          >
            {props.loadingTabs ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="white"
                sx={{ height: "100%" }}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Name")}</strong>
                    </TableCell>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Net_Sale")}</strong>
                    </TableCell>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Total_Sale")}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.dataResponseTab.tab1.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={
                        index % 2 !== 0
                          ? { background: "#f5f5f5" }
                          : { background: colors.grey[100] }
                      }
                    >
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.store}
                      </TableCell>
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.netsales}
                      </TableCell>
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.sales}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* {renderEmptyRows(props.dataResponseTab.tab1.length)} */}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
        {currentTab === 1 && (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 400,height: 400, overflow: "auto" }}
          >
            {" "}
            {props.loadingTabs ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="white"
                sx={{ height: "100%" }}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Name")}</strong>
                    </TableCell>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Hour")}</strong>
                    </TableCell>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Avg_Amount")}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.dataResponseTab.tab2.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={
                        index % 2 !== 0
                          ? { background: "#f5f5f5" }
                          : { background: colors.grey[100] }
                      }
                    >
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.store}
                      </TableCell>
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.hour}
                      </TableCell>
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.sales}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* {renderEmptyRows(props.dataResponseTab.tab2.length)} */}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
        {currentTab === 2 && (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 400,height: 400, overflow: "auto" }}
          >
            {props.loadingTabs ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="white"
                sx={{ height: "100%" }}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Name")}</strong>
                    </TableCell>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Section")}</strong>
                    </TableCell>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Avg_Amount")}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.dataResponseTab.tab3.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={
                        index % 2 !== 0
                          ? { background: "#f5f5f5" }
                          : { background: colors.grey[100] }
                      }
                    >
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.store}
                      </TableCell>
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.section}
                      </TableCell>
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.sales}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* {renderEmptyRows(props.dataResponseTab.tab3.length)} */}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
        {currentTab === 3 && (
          <TableContainer
            component={Paper}
            sx={{ maxHeight: 400,height: 400, overflow: "auto" }}
          >
            {props.loadingTabs ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="white"
                sx={{ height: "100%" }}
              >
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Name")}</strong>
                    </TableCell>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Menu_Item")}</strong>
                    </TableCell>
                    <TableCell
                      sx={{ background: "#f5f5f5", color: colors.grey[900] }}
                    >
                      <strong>{t("Qty")}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.dataResponseTab.tab4.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={
                        index % 2 !== 0
                          ? { background: "#f5f5f5" }
                          : { background: colors.grey[100] }
                      }
                    >
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.store}
                      </TableCell>
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.menu_item}
                      </TableCell>
                      <TableCell sx={{ color: colors.grey[900] }}>
                        {item.qty}
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* {renderEmptyRows(props.dataResponseTab.tab4.length)} */}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default DashboardTabTable;
