import React from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next';


export function SummaryGroupListingbyproduct(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>
            <hr />
            <table  style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Summary_Group')}</td>
                        <td>{t('Report_Category')}</td>
                        <td>{t('Product')}</td>

                    </tr>
                </thead>
                {
                    props.data.productlistigdata.map((item, index) => (
                        <tbody>
                            <tr key={index} className='border-top border-left border-right'>
                                <td>{item.summarygroup}</td>
                                <td colSpan="2"></td>
                            </tr>
                            {
                                item.reportcatlist.map((items, index) => (
                                    <Fragment>
                                        <tr className='border-left border-top bottom border-right ' key={index}>
                                            <td></td>
                                            <td colSpan="2">{items.reportcat}</td>
                                        </tr>
                                        {
                                            items.products.map((items2, index) => (
                                               
                                                    <tr className='border-left border-right' key={index}>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{items2[1]}</td>
                                                    </tr>

                                            ))}
                                    </Fragment>
                                ))}
                        </tbody>
                    )
                    )
                }

            </table>
        </div>
    )
}