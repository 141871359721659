import React, { useState } from "react";
import Chart from "react-apexcharts";

const PieChart = ({ data, labels }) => {
  const [options] = useState({
    chart: {
      //   width: 380,
      type: "pie",
    },
    legend: {
      position: "bottom", // Place the legend at the bottom
    },
    colors: ["#52E790", "#488cff"],
    dataLabels: {
      style: {
        colors: ['#ff0'],
        fontSize: "17px"
        
      },
      align: "center"
    },

    labels: labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
            Height: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  return (
    <div>
      <div id="chart">
        <Chart
          options={options}
          series={data}
          type="pie"
          width={260}
          height={200}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default PieChart;
