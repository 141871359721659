import React from "react";

import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useTheme
} from "@mui/material";
import { tokens } from "../../theme";


const CustomModalInvoice = ({ isOpen, onClose, data }) => {

  const {t} =useTranslation();
  const theme= useTheme();
  const colors =tokens(theme.palette.mode);

  return (
    <Modal show={isOpen} onHide={onClose} >
      <Modal.Header  style={{ backgroundColor: colors.default[100]  }}>
        <Modal.Title>{t('Sale_Invoice')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: colors.default[100]  }}>
        <div id="salesid">
          <Typography
            variant="subtitle1"
            align="center"
            sx={{ fontWeight: "bold" }}
          >
            {data.companyname}
          </Typography>
          <Typography variant="subtitle2" align="center" id="storenameinvoice">
            {data.outletname}
          </Typography>
          <br />
          <Typography sx={{ fontWeight: "bold",textAlign:t('langDir') }}>
            {t('Transact_No')} : {data.transactnoinvoice}
          </Typography>
          <Typography sx={{ fontWeight: "bold",textAlign:t('langDir') }}>
          {t('VAT_No')} : {data.vatnoinvoice}
          </Typography>
          <Typography sx={{ fontWeight: "bold",textAlign:t('langDir') }}>
            {t('Date')} : {data.dateinvoice}
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Qty')}</TableCell>
                  <TableCell>{t('Desc')}</TableCell>
                  <TableCell>{t('Amount')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Render table rows dynamically */}
                {data.salesdetail && data.salesdetail.map((row) => (
                  <TableRow>
                    <TableCell>{row.qty}</TableCell>
                    <TableCell>{row.desc}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                  </TableRow>
                ))}
             
              {data.discountdetail &&
                data.discountdetail.map((row) => (
                  <TableRow >
                    <TableCell>{row.qty}</TableCell>
                    <TableCell>{row.desc}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                  </TableRow>
                ))}
                 </TableBody>
            </Table>
          </TableContainer>
          <br />
          ********************************************************************
          <br />
          <Typography sx={{ fontWeight: "bold",textAlign:t('langDir') }}>
          {t('PRICE_WITHOUT_VAT')} : {data.total}
          </Typography>
          <Typography
            sx={{ fontWeight: "bold",textAlign:t('langDir') }}
            id="totalinvoice"
          ></Typography>
          <Typography sx={{ fontWeight: "bold" ,textAlign:t('langDir')}}>
          {t('VAT')} : {data.vattotal}
          </Typography>
          <Typography sx={{ fontWeight: "bold" }} id="vatinvoice"></Typography>
          ********************************************************************
          <Typography sx={{ fontWeight: "bold",textAlign:t('langDir') }}>
          {t('Final_Total')} : {data.finaltotal}
          </Typography>
          <Typography
            sx={{ fontWeight: "bold" }}
            id="totalvatinvoice"
          ></Typography>
          <br />
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: colors.default[100] }}>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModalInvoice;
