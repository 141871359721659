import React from 'react'
import { useTranslation } from 'react-i18next';

export default function EmployeeSalesSummary(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div class="modal-body">
                <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>
                    <h6 id="fromtodatesalebytype"></h6>
                </div>

                <table id="employeesalessummaryviewtable" style={{ width: "100%" }}>
                    <thead>
                    <tr className="headingtop">
                        <td>{t('Employee_No')}</td>
                        <td>{t('Employee_Name')}</td>
                        <td>{t('Qty')}</td>
                        <td>{t('Value')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.data.employeedata.map((item,index) => (
                            <tr key={index}>
                                <td>{item.empnum}</td>
                                <td>{item.posname}</td>
                                <td>{item.qty}</td>
                                <td>{item.datavalues}</td>
                            </tr>
                        ))
                    }
                    </tbody>

                    <tr>
                        <td className='font-weight-bold'>{t('Total')}</td>
                        <td className='font-weight-bold'></td>
                        <td className='font-weight-bold'>{props.data.totalqty}</td>
                        <td className='font-weight-bold'>{props.data.totalvalue}</td>
                    </tr>
                </table>
            </div>
        </div>
    )
}