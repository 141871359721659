import { Typography,Box,useTheme } from "@mui/material";
import {tokens} from "../theme";
import { useTranslation } from "react-i18next";

const PageHeader =({title,subtitle,centerText}) => {
    const theme =useTheme();
    const colors=tokens(theme.palette.mode);
    const {t} =useTranslation();

    
    return (
        <Box mb="10px" sx={{textAlign:"left"}}>
            <Typography
            variant="h3"
            color={colors.default[300]}
            fontWeight="bold"
            sx={{mb:"1px",
          textAlign:t('langDir')}}
            >
            {title}
            </Typography>
            <Typography variant="h4" color={colors.default[300]}> {subtitle} </Typography>
            <Typography
            variant="h3"
            color={colors.default[300]}
            fontWeight="bold"
            sx={{mb:"1px",
          textAlign:'center'}}
            >
            {centerText}
            </Typography>

        
            <hr
        style={{
          // background: colors.default[300],
          // color: colors.default[300],
          // borderColor: colors.default[300],
          // // height: '0.01px',
          // marginTop:"0px",
          // boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.1)",
          height: "10px",
          border: "0",
          boxShadow: `0 5px 9px -10px ${colors.default[300]} inset`,
          marginBottom:"5px",
          marginTop:"5px"          
        }}
      />
        </Box>
    );

}

export default PageHeader;