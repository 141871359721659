import React from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next';


export function VoidByEmployee(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>
            <hr />
            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Employee_Name')}</td>
                        <td>{t('Reasons')}</td>
                        <td>{t('Qty')}</td>
                        <td>{t('Value')}</td>

                    </tr>
                </thead>
                {
                    props.data.voidtypedata.map((item, indexs) => (
                        <tbody>
                            <tr key={indexs} className='border-top border-left border-right'>
                                <td>{item.employeename}</td>
                                <td colSpan="3"></td>
                            </tr>
                            {
                                item.products.map((items, index) => (
                                    <Fragment>
                                        <tr className='border-left border-top bottom border-right ' key={index}>
                                        <td></td>
                                            <td>{items[0]}</td>
                                            <td >{items[1]}</td>
                                            <td >{items[2]}</td>
                                        </tr>
                                    </Fragment>
                                ))}
                        </tbody>
                    )
                    )
                }

            </table>
        </div>
    )
}