import React from 'react'
import { useTranslation } from 'react-i18next';

export default function VoidByType(props) {

    const {t}=useTranslation();
   

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>

            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Desc')}</td>
                        <td>{t('Qty')}</td>
                        <td>{t('Value')}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.voidtypedata.map((item, index) => (
                            <tr key={index}>
                                <td>{item.descript}</td>
                                <td>{item.quan}</td>
                                <td>{item.value}</td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>
          
        </div>
    )
}