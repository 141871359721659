import { Box, TextField, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import PageHeader from "../../../../components/PageHeader";
import { updateToken } from "../../../../api/axios";
import { baseInstance } from "../../../../api/axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import useAuth from "../../../../hooks/useAuth";


function CompanyForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  // Required Form Details
  const [name, setName] = useState("");
  const [vatNo, setVatNo] = useState("");
  const [crNo, setCrNo] = useState("");
  const [coNo, setCoNo] = useState("");
  const [buildingNo, setBuildingNo] = useState("");
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [street, setStreet] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState([]);
  const [zipCode, setZipCode] = useState("");
  const [additionalNo, setAdditionalNo] = useState("");
  const [entityGroupId, setEntityGroupId] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState(0);


  const [errors, setErrors] = useState({});
  const { logout } = useAuth();

  
  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };
  
  const handleApiRequest = async () => {
    updateToken(localStorage.getItem("token"));
    try {
      let response;

      // if (id) {
      //   // Editing existing vendor
      //   response = await baseInstance.put(`/api/accounts/vendor/edit_vendor`, {
      //     ApiData: {
      //       name,
      //       vatNo,
      //       crNo,
      //       coNo,
      //       buildingNo,
      //       street,
      //       district,
      //       city,
      //       country,
      //       zipCode,
      //       additionalNo,
      //       id,
      //     },
      //   });
      // } else {
      // Creating new vendor
      response = await baseInstance.post(
        "/api/administration/companylist/createcompany",
        {
          ApiData: {
            name,
            vatNo,
            crNo,
            coNo,
            buildingNo,
            street,
            district,
            city,
            country,
            zipCode,
            additionalNo,
            selectedGroupId,
            userName,
            password,
            selectedCountry
          },
        }
      );
      // }

      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleClick = () => {
    // Validate the form fields
    const validationErrors = {};
    if (name.trim() === "") {
      validationErrors.name = "Name is required";
    }
    if (vatNo.trim() === "" ) {
      validationErrors.vatNo = "VAT No. is required";
    }
    if (isNaN(vatNo) ) {
      validationErrors.vatNo = "VAT No. should be Number";
    }
    if (userName.trim() === "") {
      validationErrors.userName = "Email is required";
    }
    if (password.trim() === "") {
      validationErrors.password = "Password is required";
    }
    if (selectedGroupId === 0) {
      validationErrors.entityGroupId = "Select the package";
    }
    if (selectedCountry === 0) {
      validationErrors.CountryId = "Select the Country";
    }

    
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      handleApiRequest()
        .then((response) => {
          if (response.status === 200){
            navigate("/administration/companylist");
            toast.success("Company has been created!")
          }
          else if (response.status === 400) {
            // navigate("/accounts/vendors");

            toast.error("Entered User Email is Already Exists");
          } else {
            console.log("Request failed with status:", response.status);
          }
        })
        .catch((error) => {
          if(error.response.data.message){
            toast.error(error.response.data.message);
          }
          else{
            
            if (error.response.status === 403) {
              navigate(`/`);
            } else if (error.response.status === 401) {
              handleLogout();
            } else {
              console.error(error);
              toast.error("Record Failed!");
            }
          }
        });
    }
  };

  useEffect(() => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get(`/api/administration/companylist/getcreatecompanydata`)
      .then((response) => {
        const entityGroupData = response.data;
        console.log(entityGroupData);
        setEntityGroupId(entityGroupData.EntityGroupList);
        setCountry(entityGroupData.CountryList);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
          toast.error("Record Failed!");
        }
      });
  }, []);

  const handleModuleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedGroupId(selectedValue);
    // props.onChange(selectedValue); // Call the onChange callback with the selected value
  };

  const handleCountryChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedCountry(selectedValues);
    // props.onChange(selectedValue); // Call the onChange callback with the selected value
  };

  //   useEffect(() => {
  //     // Fetch the vendor details if editing an existing vendor
  //     if (id) {
  //       updateToken(localStorage.getItem("token"));
  //       baseInstance
  //         .get(`/api/accounts/vendor/get_vendor`, { params: { vendorId: id } })
  //         .then((response) => {
  //           const vendorData = response.data;
  //           // Set the form fields with the vendor data
  //           setName(vendorData.vendornameEng);
  //           setVatNo(vendorData.vatno);
  //           setCrNo(vendorData.crno);
  //           setCoNo(vendorData.coid);
  //           setBuildingNo(vendorData.bldgno);
  //           setStreet(vendorData.street);
  //           setDistrict(vendorData.district);
  //           setCity(vendorData.city);
  //           setCountry(vendorData.country);
  //           setZipCode(vendorData.postalcode);
  //           setAdditionalNo(vendorData.additionalno);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     }
  //   }, [id]);

  return (
    <Box className=" h-75 mx-auto w-90 text-align ">
      {id ? (
        <PageHeader title="Edit Company" />
      ) : (
        <PageHeader title="Create Company" />
      )}

      <Box component="form" sx={{ display: "flex", flexDirection: "column" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              label={t("Name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              error={errors.name}
              helperText={errors.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("VAT_No")}
              value={vatNo}
              onChange={(e) => setVatNo(e.target.value)}
              required
              error={errors.vatNo}
              helperText={errors.vatNo}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("CR_No")}
              value={crNo}
              onChange={(e) => setCrNo(e.target.value)}
              required
              error={errors.crNo}
              helperText={errors.crNo}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="CO No."
              value={coNo}
              onChange={(e) => setCoNo(e.target.value)}
              error={errors.coNo}
              helperText={errors.coNo}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography
          variant="h5"
          sx={{
            marginTop: "15px",
            marginBottom: "10px",
            textAlign: t("langDir"),
          }}
          fontWeight="bold"
        >
          {" "}
          {t("Address")}{" "}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              label={t("Building_No")}
              value={buildingNo}
              onChange={(e) => setBuildingNo(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("Street")}
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("District")}
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} marginTop="5px">
          <Grid item xs={3}>
            <TextField
              label={t("City")}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
          <FormControl
              fullWidth
            >
              <InputLabel id="demo-select-small-label">
                {t("Country")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedCountry}
                label="Country*"
                onChange={handleCountryChange}
                // style={props.sx}
                error={errors.CountryId}
              >
                {country.map((countryid) => (
                  <MenuItem value={countryid.CountryId}>{countryid.Name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <TextField
              label={t("Country")}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              fullWidth
            /> */}
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={t("Zip_Code")}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={t("Additional_No")}
              value={additionalNo}
              onChange={(e) => setAdditionalNo(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Typography
          variant="h5"
          sx={{
            marginTop: "15px",
            marginBottom: "10px",
            textAlign: t("langDir"),
          }}
          fontWeight="bold"
        >
          {" "}
          {t("Packages")}{" "}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl
              sx={{
                minWidth: 200,
              }}
              size="small"
            >
              <InputLabel id="demo-select-small-label">
                {t("Packages")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
  
                onChange={handleModuleChange}
                error={errors.entityGroupId}
              >
                {entityGroupId.map((groupId) => (
                  <MenuItem value={groupId.id}>{groupId.group_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Typography
        variant="h5"
        sx={{
          marginTop: "15px",
          marginBottom: "10px",
          textAlign: t("langDir"),
        }}
        fontWeight="bold"
      >
        {" "}
       Admin Detail
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextField
            label="Email"
            value={userName}
            onChange={(e) => setUsername(e.target.value)}
            error={errors.userName}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errors.password}

            fullWidth
          />
        </Grid>
      </Grid>

      <Box sx={{ textAlign: "right", marginTop: "20px", marginBottom: "20px" }}>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleClick}
        >
          {t("Save")}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            navigate("/administration/companylist");
          }}
        >
          {t("Cancel")}
        </button>
      </Box>
    </Box>
  );
}

export default CompanyForm;
