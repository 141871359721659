import React, { useContext, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import { useTranslation } from "react-i18next";
import OutletContext from "../../../context/OutletContext";
import OutletDropdown from "../../../components/supportingComponents/outletdropdown";
import { baseInstance, updateToken } from "../../../api/axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tokens } from "../../../theme";
import { BarChart } from "./barchart";
import { DatePicker, CustomProvider } from "rsuite";

import { useEffect } from "react";

const BusinessDaySummary = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState({ 'trend':{"stat":0,"trendper":0}});
  const [dataOutlet, setDataOutlet] = useState({ topsales: [], leastsale: [] });

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };
  const handleOutletChange = (selectedValue) => {
    setOutletId(selectedValue);
  };

  useEffect(() => {
    if (selectedDate) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post("/api/businessintelligence/businessday", {
          storeid:
            OutletId === ""
              ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
              : OutletId,
          selectedDate: selectedDate,
        })
        .then((response) => {
          if (response.status === 200) {
            setData(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            handleLogout();
            toast.error("Unauthorized access");
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
          // setCardLoading(false);
        });
    }
  }, [OutletId, selectedDate]);

  useEffect(() => {
    if (selectedDate) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post("/api/businessintelligence/businessday/topleastbranch", {
          selectedDate: selectedDate,
        })
        .then((response) => {
          if (response.status === 200) {
            setDataOutlet(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            handleLogout();
            toast.error("Unauthorized access");
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
          // setCardLoading(false);
        });
    }
  }, [selectedDate]);

  return (
    <Box className="h-75 mx-auto w-90 text-align">
      <PageHeader centerText={t("Business Day Summary")} />
      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}
      >
        <CustomProvider theme={theme.palette.mode}>
          <DatePicker
            value={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
        </CustomProvider>
        <OutletDropdown defaultValue={OutletId} onChange={handleOutletChange} />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Net_Sale")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["net_sales"]}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Last 7 Days sales")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["net_sales_7days"]}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Last 30 Days Sales")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["net_sales_30days"]}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Last 30 days Trend")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["trend"]["trendper"]}{"  %"} {data["trend"]['stat']===0 ?(<ArrowDownwardIcon style={{color:"#ed3300"}} />):(<ArrowUpwardIcon style={{color:"#008000"}} />)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop="15px">
        {/* Second Row */}
        <Grid item xs={12} sm={6} md={2.4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Buiness day Sales Net")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["net_sales"]}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Same Day Last Year")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["same_date_last_year"]}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Same Day Last Month")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["same_day_last_month"]}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Same day Last Week")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["same_date_last_week"]}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Yesterday")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["yesterday"]}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop="15px">
        {/* Third Row */}
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Year To Date")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["starting_of_the_year"]}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Quater To Date")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["current_quater"]}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Month To Date")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["current_month"]}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Week To Date")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {data["current_week"]}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop="15px">
        {/* Fourth Row */}
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Sales_by_Payment")}
              </Typography>
            </Box>
            <BarChart linedata={data["paymentlinedata"]} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Sales_by_Product")}
              </Typography>
            </Box>
            <div style={{ height: "200px", width: "100%" }}>
              <BarChart linedata={data["productlinedata"]} />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            {" "}
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Top Branch in Sales(Net)")}
              </Typography>
            </Box>{" "}
            {dataOutlet["topsales"].length === 0 ? (
              <div>No data </div>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: 230,
                  height: 230,
                  overflow: "auto",
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0 1px 2.94px 0.06px #fff"
                      : null,

                  backgroundColor: colors.default[100],
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: "9px" }}>
                        <strong> </strong>
                      </TableCell>
                      <TableCell style={{ padding: "9px" }}>
                        <strong>{dataOutlet["topsales"]["outletname"]}</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ padding: "8px" }}>
                      <TableCell sx={{ padding: "8px" }}>
                        {" "}
                        Sales Final
                      </TableCell>
                      <TableCell sx={{ padding: "8px" }}>
                        {dataOutlet["topsales"]["finaltotal"]}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ padding: "8px" }}>
                      <TableCell sx={{ padding: "8px" }}>
                        No of Orders
                      </TableCell>
                      <TableCell sx={{ padding: "8px" }}>
                        {dataOutlet["topsales"]["transactions"]}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ padding: "8px" }}>
                      <TableCell sx={{ padding: "8px" }}>
                        Sales Without Tax
                      </TableCell>
                      <TableCell sx={{ padding: "8px" }}>
                        {dataOutlet["topsales"]["nettotal"]}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ padding: "8px" }}>
                      <TableCell sx={{ padding: "8px" }}>Tax Amount</TableCell>
                      <TableCell sx={{ padding: "8px" }}>
                        {dataOutlet["topsales"]["tax"]}
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ padding: "8px" }}>
                      <TableCell sx={{ padding: "8px" }}>
                        Average Order
                      </TableCell>
                      <TableCell sx={{ padding: "8px" }}></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            {" "}
            <Box
              style={{
                color: "white",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  backgroundColor: colors.primary[500],
                  borderRadius: "3px",
                }}
              >
                {t("Least Branch in Sales (NET)")}
              </Typography>
            </Box>{" "}
            {dataOutlet["leastsale"].length === 0 ? (
              <div>No data </div>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: 230,
                  height: 230,
                  overflow: "auto",
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0 1px 2.94px 0.06px #fff"
                      : null,

                  backgroundColor: colors.default[100],
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: "9px" }}>
                        <strong> </strong>
                      </TableCell>
                      <TableCell style={{ padding: "9px" }}>
                        <strong>{dataOutlet["leastsale"]["outletname"]}</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ padding: "8px" }}>
                      <TableCell sx={{ padding: "8px" }}>
                        {" "}
                        {t("Sales_Final")}
                      </TableCell>
                      <TableCell sx={{ padding: "8px" }}>
                        {dataOutlet["leastsale"]["finaltotal"]}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ padding: "8px" }}>
                      <TableCell sx={{ padding: "8px" }}>
                        {t("No of Orders")}
                      </TableCell>
                      <TableCell sx={{ padding: "8px" }}>
                        {dataOutlet["leastsale"]["transactions"]}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ padding: "8px" }}>
                      <TableCell sx={{ padding: "8px" }}>
                        {t("Sales without Tax")}
                      </TableCell>
                      <TableCell sx={{ padding: "8px" }}>
                        {dataOutlet["leastsale"]["transactions"]}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{ padding: "8px" }}>
                      <TableCell sx={{ padding: "8px" }}>
                        {t("Tax_Amount")}
                      </TableCell>
                      <TableCell sx={{ padding: "8px" }}>
                        {dataOutlet["leastsale"]["tax"]}
                      </TableCell>
                    </TableRow>

                    <TableRow style={{ padding: "8px" }}>
                      <TableCell sx={{ padding: "8px" }}>
                        {t("Average Order")}
                      </TableCell>
                      <TableCell sx={{ padding: "8px" }}></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessDaySummary;
