import { Box, useTheme } from "@mui/material";
import { CustomProvider, DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useContext } from "react";
import DateContext from "../context/DateContext";
import { subMonths, startOfMonth, endOfMonth,subDays, startOfDay,endOfDay } from "date-fns";
import { useTranslation } from "react-i18next";

const CustomDateRange = (props) => {
  const theme = useTheme();
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateContext);
  const localStartofMonth = new Date(startOfMonth(new Date()).getTime() - startOfMonth(new Date()).getTimezoneOffset() * 60000);
  const localEndofMonth = subDays(new Date(endOfMonth(new Date()).getTime() - endOfMonth(new Date()).getTimezoneOffset() * 60000),1);
  const { t } = useTranslation();

  const localStartLastMonth = new Date(startOfMonth(subMonths(new Date(),1)).getTime() - startOfMonth(subMonths(new Date(),1)).getTimezoneOffset() * 60000);
  const localEndLastMonth = subDays(new Date(endOfMonth(subMonths(new Date(),1)).getTime() - endOfMonth(subMonths(new Date(),1)).getTimezoneOffset() * 60000),1);
  const localStartLast7Days = new Date(startOfDay(subDays(new Date(), 7)).getTime() - startOfDay(subDays(new Date(), 7)).getTimezoneOffset() * 60000);
  const localEndLast7Days = new Date(startOfDay(new Date()).getTime() - startOfDay(new Date()).getTimezoneOffset() * 60000);

  // today and yesterday

  const localStartofToday = new Date(
    startOfDay(new Date()).getTime() - startOfDay(new Date()).getTimezoneOffset() * 60000
  );
  
  const localEndofToday = new Date(
    startOfDay(new Date()).getTime() - startOfDay(new Date()).getTimezoneOffset() * 60000
  );
  
  const localStartOfYesterday = new Date(
    startOfDay(subDays(new Date(), 1)).getTime() -
      startOfDay(subDays(new Date(), 1)).getTimezoneOffset() * 60000
  );
  
  const localEndOfYesterday = new Date(
    startOfDay(subDays(new Date(), 1)).getTime() -
      startOfDay(subDays(new Date(), 1)).getTimezoneOffset() * 60000
  );



  const currentMonthStart = localStartofMonth;
  const currentMonthEnd = localEndofMonth;

  const customRanges = [
    {
      label: t("Today"),
      value: [localStartofToday, localEndofToday],
    },
    {
      label: t("Yesterday"),
      value: [localStartOfYesterday, localEndOfYesterday],
    },
    {
      label: t("Last_7_Days"),
      value: [localStartLast7Days, localEndLast7Days],
    },
    {
      label: t("This_Month"),
      value: [currentMonthStart, currentMonthEnd],
    },
    {
      label: t("Last_Month"),
      value: [localStartLastMonth, localEndLastMonth],
    },
   
  ];

  const handleDateRangeChange = (value) => {
    if (value === null) {
      setStartDate(null);
      setEndDate(null);
    } else if (Array.isArray(value) && value.length === 2) {
      const [start, end] = value;
      const localStartDate = new Date(start.getTime() - start.getTimezoneOffset() * 60000);
      const localEndDate = new Date(end.getTime() - end.getTimezoneOffset() * 60000);
      setStartDate(localStartDate);
      setEndDate(localEndDate);
    }
  };
  return (
    <CustomProvider theme={theme.palette.mode}>
    <Box mb="30px" sx={props.sx}>
      <DateRangePicker
        placeholder="Select Date Range"
        value={[startDate, endDate]}
        ranges={customRanges}
        onChange={handleDateRangeChange}
        // format="yyyy-MM-dd"
      />
    </Box>
    </CustomProvider>
  );
};

export default CustomDateRange;
