import { Box,   TextField } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { baseInstance } from "../../../api/axios";
import { updateToken } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import CustomLoadingOverlay from "../../../components/supportingComponents/CustomLoadingOverLay";
import { CustomNoRowsOverlay } from "../../../components/supportingComponents/CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import useAuth from "../../../hooks/useAuth";

function Users() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { t } = useTranslation();
  const { logout } = useAuth();

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const handleEdit = (id) => {
    navigate(`/administration/users/createuser/${id}`);
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      flex: 0.1,
      headerClassName: "th-datagrid-theme",
    },

    {
      field: "actions",
      headerName: "",
      headerClassName: "th-datagrid-theme",
      width: 0.1,
      renderCell: (params) => (
        <IconButton onClick={() => handleEdit(params.row.id)}>
          <EditIcon />
        </IconButton>
      ),
    },

    {
      field: "name",
      headerName: "Name",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
    },
    {
      field: "email_address",
      headerName: "Email",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },

    {
      field: "role",
      headerName: "Role",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  const [loadingUser, setLoadingUsers] = useState(true);

  //   const handleOutletChange = (selectedValue) => {
  //     setSelectedOutlet(selectedValue);
  //   };
  //   const handleRowClick = (params) => {
  //     const { id } = params.row;
  //     setSelectedRowId(id);
  //   };

  useEffect(() => {
    fetchRows();
  }, []);

  const handleNewUser = () => {
    navigate("/administration/users/createuser");
  };

  const fetchRows = () => {
    setLoadingUsers(true);
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/administration/users", {})
      .then((response) => {
        const { data } = response.data;
        setRows(data);

        // setTotalRows(data.totalRows);
        setLoadingUsers(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
        setLoadingUsers(false);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    String(row.outlet_name).includes(searchText)
  );

  return (
    <Box className=" h-75 mx-auto w-90 text-align ">
      <PageHeader title={t("Users")} />
      <Box className=" mx-auto text-align " sx={{ width: "100%" }}>
        <Box sx={{ textAlign: "right", marginBottom: "10px" }}>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            style={{ marginLeft: "10px" }}
            onClick={handleNewUser}
          >
            Create User
          </button>
        </Box>
        <Box mb="10px" sx={{ textAlign: "right" }}>
          <TextField
            label="Search"
            // value={searchText}
            onChange={handleSearch}
            size="small"
            style={{ marginLeft: "10px" }}
          />
        </Box>

        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          slots={{
            noRowsOverlay: () => <CustomNoRowsOverlay message="No Users!" />,
          }}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
            loading={loadingUser}
          style={{ height: 630 }}
          componentsProps={{
            loadingOverlay: {
              style: { zIndex: 9999 },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
}

export default Users;
