import React, { useState, useEffect, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import { baseInstance, updateToken } from "../../../api/axios";
import PageHeader from "../../../components/PageHeader";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Select, Form, Row, Col, Input } from "antd";
import { useTheme } from "@mui/material";
import { DatePicker, CustomProvider } from "rsuite";
import { ConfigProvider, theme } from "antd";
import jwtDecode from "jwt-decode";
import OutletContext from "../../../context/OutletContext";

export default function PrintLabel() {
  const { OutletId, setOutletId } = useContext(OutletContext);
  const [selectedOutlet, setSelectedOutlet] = useState(OutletId[0]);
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { Option } = Select;
  const [form] = Form.useForm();
  const themecustom = useTheme();
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [outlets, setOutlets] = useState([]);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsEmployee, setOptionsEmployee] = useState([]);
  const [expirydate, setExpiryDate] = useState(new Date());
  const [optionsItem, setOptionsItem] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const iframeRef = useRef();

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    try {
      const fetchOutletsData = jwtDecode(token);
      setOutlets(fetchOutletsData.outlets);
    } catch {
      console.log("error in token validation");
    }
  }, []);

  const handlePrint = () => {
    const fieldValues = form.getFieldsValue();
    let content = ``;
    for (let i = 1; i <= fieldValues.noprints; i++) {
      content += `
        <div style="width: 105mm; height: 45mm;page-break-after: always;">
          
  
<p style=" font-size: 23px; font-weight: bold; margin-left: 10px;">${
        fieldValues.selecteditem
      }</p>

          <p style="font-size: 17px;font-weight: bold;">Storage: ${
            fieldValues.selectstorage
          }</p>
          <p style="font-size: 18px;font-weight: bold;">Prepared On:  ${new Date().toDateString()} ${new Date().toLocaleTimeString()}</p>
          <p style="font-size: 18px;font-weight: bold;">Expiry By: ${expirydate.toDateString()} ${expirydate.toLocaleTimeString()}</p>
          <p style="font-size: 18px;font-weight: bold;">Prepared By: ${
            fieldValues.selectedemployee
          }</p>
          <div style="text-align: right;">
          <img alt="subway-codeplus" width="120px" height="40px" src="../../assets/subwaylogo.jpeg" style="display: inline-block;" /> 

          </div>
         

          </div>
      `;
    }

    const iframeDoc = iframeRef.current.contentDocument;

    iframeDoc.open();
    iframeDoc.write(`
    <html>
      <head>
      <head>
      <style>
        @page {
          size: 108mm 50mm;
          margin: 0;
        }
      </style>
        <title>Print</title>
      </head>
      <body>${content}</body>
    </html>
  `);
    iframeDoc.close();

    iframeRef.current.onload = function () {
      iframeRef.current.contentWindow.print();
    };
  };

  useEffect(() => {
    fetchRows();
  }, [selectedOutlet]);

  const fetchRows = () => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post("/api/menumanagement/printlabel", {
        storeid: selectedOutlet,
      })
      .then((response) => {
        setOptionsCategory(response.data.categorylist);
        setOptionsEmployee(response.data.employeelist);
        setOptionsItem(response.data.itemlist);
        setSelectedCategory(
          response.data.categorylist.length > 0
            ? response.data.categorylist[0].value
            : 0
        );
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
      });
  };

  const optionStorage = [
    { label: "Dry", value: "Dry" },
    { label: "Frozen", value: "Frozen" },
    { label: "Chiller", value: "Chiller" },
    { label: "Line", value: "Line" },
    { label: "Chemical", value: "Chemical" },

  ];

  const handleOutletChange = (selectedValue, option) => {
    setSelectedOutlet(selectedValue);
  };

  const handleCategory = (selectedValue, option) => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post("/api/menumanagement/printlabel/getitems", {
        storeid: selectedOutlet,
        reportcatid: selectedValue,
      })
      .then((response) => {
        setOptionsItem(response.data.itemlist);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
      });
    setSelectedCategory(selectedValue);
  };

  return (
    <>
      <Box
        className=" h-75 mx-auto w-90 text-align "
        sx={{ height: 200, width: "100%" }}
      >
        <PageHeader title={t("Print_Label")} />
        <ConfigProvider
            theme={{
              algorithm:
                themecustom.palette.mode == "dark"
                  ? darkAlgorithm
                  : defaultAlgorithm,
            }}
          >

        <Form form={form} layout="vertical" onFinish={handlePrint}>
          <Box
            sx={{
              textAlign: "Right",
              marginTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Select
              placeholder={t("Select_Outlet")}
              value={selectedOutlet}
              onChange={handleOutletChange}
            >
              {outlets.map((outlet) => (
                <Option key={outlet.id} value={outlet.id}>
                  {outlet.storename}
                </Option>
              ))}
            </Select>
          </Box>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label={t("Select_Category")}
                name="selectedcategory"
                rules={[{ required: true, message: "Please select category" }]}
              >
                <Select
                  placeholder={t("Select_Category")}
                  optionFilterProp="children"
                  showSearch
                  value={selectedCategory}
                  onChange={handleCategory}
                  style={{ width: "100%" }}
                >
                  {optionsCategory.map((categoryitem) => (
                    <Option key={categoryitem.value} value={categoryitem.value}>
                      {categoryitem.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("Select_Item")}
                name="selecteditem"
                rules={[{ required: true, message: "Please select Item" }]}
              >
                <Select
                  placeholder={t("Select_Item")}
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                >
                  {optionsItem.map((item) => (
                    <Option key={item.value} value={item.label}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("Select_Employee")}
                name="selectedemployee"
                rules={[{ required: true, message: "Please select Employee" }]}
              >
                <Select
                  placeholder={t("Select_Employee")}
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                >
                  {optionsEmployee.map((employee) => (
                    <Option key={employee.value} value={employee.label}>
                      {employee.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label={t("No_prints")}
                name="noprints"
                rules={[{ required: true, message: "No of print" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="selectedDate" label={t("Expiry_Date")}>
                <CustomProvider theme={themecustom.palette.mode}>
                  <DatePicker
                    format="yyyy-MM-dd HH:mm:ss"
                    style={{ width: "100%" }}
                    value={expirydate}
                    onChange={(date) => setExpiryDate(date)}
                  />
                </CustomProvider>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t("Select_Storage")}
                name="selectstorage"
                rules={[{ required: true, message: "Please select Storage" }]}
              >
                <Select
                  placeholder={t("Select_Storage")}
                  optionFilterProp="children"
                  showSearch
                  style={{ width: "100%" }}
                >
                  {optionStorage.map((storage) => (
                    <Option key={storage.value} value={storage.label}>
                      {storage.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Box
            sx={{
              textAlign: "Left",
              marginTop: "20px",
              paddingBottom: "40px",
            }}
          >
            <button type="submit" className="btn btn-sm btn-primary">
              {t("Print")}
            </button>
          </Box>
          <iframe
            ref={iframeRef}
            style={{ display: "none" }}
            title="Print Content"
          ></iframe>
        </Form>
        </ConfigProvider>
      </Box>
    </>
  );
}
