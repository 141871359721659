import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { tokens } from "../../../theme";
import OutletDropdown from "../../../components/supportingComponents/outletdropdown";
import { CustomProvider, DateRangePicker } from "rsuite";
import { useContext } from "react";
import { useEffect } from "react";
import OutletContext from "../../../context/OutletContext";
import useAuth from "../../../hooks/useAuth";
import { baseInstance, updateToken } from "../../../api/axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { subDays, startOfDay } from "date-fns";
import { saveAs } from "file-saver";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReactExport from "react-export-excel";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const Download = (value) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const [excelData, setExcelData] = useState({ sheet: [], storename: "" });

  const callapi = () => {
    setExcelData({ sheet: [], storename: "" });

    let url = "/api/businessintelligence/salesvsbudget/excel";
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post(url, {
        startFirstDate: value.startFirstDate,
        endFirstDate: value.endFirstDate,
        startSecondDate: value.startSecondDate,
        endSecondDate: value.endSecondDate,
        storeid: value.storeid,
      })
      .then(function (response) {
        setExcelData(response.data);
        // console.log("test");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <button
        style={{ padding: "7px", minWidth: "40px", height: "50%" }}
        type="button"
        className="btn btn-primary"
        onClick={callapi}
        // disabled={loading} // Disable the button while loading
      >
        {" "}
        <FileDownloadOutlinedIcon />
      </button>
      {excelData.sheet.length > 0 && (
        <ExcelFile filename={"Sales Vs Budget"} hideElement={true}>
          {excelData.sheet.map((sheet, sheetIndex) => (
            <ExcelSheet dataSet={sheet.dataset} name={sheet.name}></ExcelSheet>
          ))}
        </ExcelFile>
      )}
    </>
  );
};

const SalesVsBudget = () => {
  const localStartofToday = new Date(
    startOfDay(new Date()).getTime() -
      startOfDay(new Date()).getTimezoneOffset() * 60000
  );

  const localEndofToday = new Date(
    startOfDay(new Date()).getTime() -
      startOfDay(new Date()).getTimezoneOffset() * 60000
  );
  const localStartOfYesterday = new Date(
    startOfDay(subDays(new Date(), 1)).getTime() -
      startOfDay(subDays(new Date(), 1)).getTimezoneOffset() * 60000
  );

  const localEndOfYesterday = new Date(
    startOfDay(subDays(new Date(), 1)).getTime() -
      startOfDay(subDays(new Date(), 1)).getTimezoneOffset() * 60000
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { OutletId, setOutletId } = useContext(OutletContext);

  const [startFirstDate, setStartFirstDate] = useState(localStartOfYesterday);
  const [endFirstDate, setEndFirstDate] = useState(localEndOfYesterday);

  const [startSecondDate, setStartSecondDate] = useState(localStartofToday);
  const [endSecondDate, setEndSecondDate] = useState(localEndofToday);
  const [dataList, setDataList] = useState([]);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const Lang = localStorage.getItem("Lang");

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };
  const handleFirstDateRangeChange = (value) => {
    if (value === null) {
      setStartFirstDate(null);
      setEndFirstDate(null);
    } else if (Array.isArray(value) && value.length === 2) {
      const [start, end] = value;
      const localStartDate = new Date(
        start.getTime() - start.getTimezoneOffset() * 60000
      );
      const localEndDate = new Date(
        end.getTime() - end.getTimezoneOffset() * 60000
      );
      setStartFirstDate(localStartDate);
      setEndFirstDate(localEndDate);
    }
  };
  const handleSecondDateRangeChange = (value) => {
    if (value === null) {
      setStartSecondDate(null);
      setEndSecondDate(null);
    } else if (Array.isArray(value) && value.length === 2) {
      const [start, end] = value;
      const localStartDate = new Date(
        start.getTime() - start.getTimezoneOffset() * 60000
      );
      const localEndDate = new Date(
        end.getTime() - end.getTimezoneOffset() * 60000
      );
      setStartSecondDate(localStartDate);
      setEndSecondDate(localEndDate);
    }
  };
  const handleOutletChange = (selectedValue) => {
    setOutletId(selectedValue);
  };

  const headofficeStatus = () => {
    const outletlog = jwtDecode(localStorage.getItem("token")).outletsdetails;
    if (OutletId.length === 1) {
      return true;
    } else if (OutletId.length > 1) {
      const isHeadOffice = OutletId.every((outletId) => {
        const outletStatus = outletlog.find((item) => item.id === outletId);
        return outletStatus && outletStatus.headofficeData === 1;
      });
      if (isHeadOffice) {
        return true;
      } else {
        toast.error("The selected Outlets should be Headoffice Outlets");
        return false;
      }
    } else {
      toast.error("The selected Outlets should be Headoffice Outlets Here");
      return false;
    }
  };

  useEffect(() => {
    setOutletId([jwtDecode(localStorage.getItem("token")).outlets[0]?.id]);
  }, []);

  useEffect(() => {
    if (OutletId.length >= 1) {
      if (headofficeStatus) {
        setDataList([]);
        updateToken(localStorage.getItem("token"));
        baseInstance
          .post("/api/businessintelligence/salesvsbudget", {
            storeid:
              OutletId === ""
                ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
                : OutletId,
            startFirstDate: startFirstDate,
            endFirstDate: endFirstDate,
            startSecondDate: startSecondDate,
            endSecondDate: endSecondDate,
          })
          .then((response) => {
            setDataList(response.data.datalist);
            // console.log(response.data.);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              handleLogout();
              toast.error("Unauthorized access");
            } else if (error.response.status === 401) {
              handleLogout();
            } else {
              console.log(error);
            }
            // setCardLoading(false);
          });
      }
    }
  }, [OutletId, startFirstDate, endFirstDate, startSecondDate, endSecondDate]);

  const getfile = () => {
    let url = "/api/businessintelligence/salesvsbudget/pdf";
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post(
        url,
        {
          storeid:
            OutletId === ""
              ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
              : OutletId,
          startFirstDate: startFirstDate,
          endFirstDate: endFirstDate,
          startSecondDate: startSecondDate,
          endSecondDate: endSecondDate,
        },
        {
          responseType: "blob", // Set the response type to 'blob'
        }
      )
      .then(function (response) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, "Sales Vs Budget" + ".pdf");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box className="h-75 mx-auto w-90 text-align">
      <PageHeader centerText={t("Sales Vs Budget")} />
      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}
      >
        <OutletDropdown defaultValue={OutletId} onChange={handleOutletChange} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CustomProvider theme={theme.palette.mode}>
          <Box mb="30px" sx={{ paddingRight: "20px", paddingLeft: "50px" }}>
            <DateRangePicker
              placeholder="Select Date Range"
              value={[startFirstDate, endFirstDate]}
              ranges={[]}
              onChange={handleFirstDateRangeChange}
            />
          </Box>
          <Typography
            sx={{
              fontWeight: "bold",
              paddingTop: "10px",
              paddingRight: "15px",
            }}
          >
            Vs
          </Typography>
          <Box mb="30px" sx={{ paddingRight: "20px" }}>
            <DateRangePicker
              placeholder="Select Date Range"
              value={[startSecondDate, endSecondDate]}
              ranges={[]}
              onChange={handleSecondDateRangeChange}
            />
          </Box>
          <button
            type="button"
            style={{
              padding: "7px",
              minWidth: "40px",
              marginRight: "10px",
              height: "50%",
            }}
            className="btn btn-primary"
            onClick={() => getfile()}
          >
            <PictureAsPdfIcon />
          </button>
          <Download
            startFirstDate={startFirstDate}
            endFirstDate={endFirstDate}
            startSecondDate={startSecondDate}
            endSecondDate={endSecondDate}
            storeid={
              OutletId === ""
                ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
                : OutletId
            }
          />
        </CustomProvider>
      </Box>

      <Grid container spacing={1} paddingBottom={2}>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              width: "100%",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              // marginBottom: 3,

              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 0.5px 0.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                // maxHeight: 800,
                // height: 450,
                overflow: "auto",
                boxShadow:
                  theme.palette.mode === "dark"
                    ? "0 0.5px 1.94px 0.06px #fff"
                    : null,

                backgroundColor: colors.default[100],
              }}
            >
              <Table>
                <TableBody>
                  {dataList.map((item, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {item["storename"]}
                        </TableCell>
                      </TableRow>
                      <TableRow key={index}>
                        <TableCell></TableCell>
                        <TableCell
                          colSpan={3}
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          Total
                        </TableCell>

                        {item["headers"].map((header, index) => (
                          <TableCell
                            colSpan={3}
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow key={index}>
                        <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Sales</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Trans</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Avg</TableCell>

                        {item["headers"].map((headerText, index) => (
                          <>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Sales
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Trans
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              Avg
                            </TableCell>
                          </>
                        ))}
                      </TableRow>
                      <TableRow key={index}>
                        <TableCell>
                          {startFirstDate.toLocaleDateString("en-GB")} -{" "}
                          {endFirstDate.toLocaleDateString("en-GB")}
                        </TableCell>
                        {item["firstdatesales"].map((firstsale, index) => (
                          <TableCell sx={{ textAlign: "center" }}>
                            {firstsale}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow key={index}>
                        <TableCell>
                          {startSecondDate.toLocaleDateString("en-GB")} -{" "}
                          {endSecondDate.toLocaleDateString("en-GB")} <strong>( Current Period )</strong>
                        </TableCell>
                        {item["seconddatesale"].map((secondsale, index) => (
                          <TableCell sx={{ textAlign: "center" }}>
                            {secondsale}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow key={index}>
                        <TableCell>Growth</TableCell>
                        {item["growth"].map((growth, index) => (
                          <TableCell
                            sx={{
                              textAlign: "center",
                              color: growth >= 0 ? "green" : "red",
                            }}
                          >
                            {growth} %
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow key={index}>
                        <TableCell>Current Budget Period</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {item["budget"]}
                        </TableCell>
                      </TableRow>
                      <TableRow key={index}>
                        <TableCell>Achieve %</TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            color: item["budgetperc"] >= 0 ? "green" : "red",
                          }}
                        >
                          {item["budgetperc"]} %
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SalesVsBudget;
