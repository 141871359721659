import {
  Box,
  TextField,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useState, useEffect } from "react";
import PageHeader from "../../../../components/PageHeader";
import { updateToken } from "../../../../api/axios";
import { baseInstance } from "../../../../api/axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import useAuth from "../../../../hooks/useAuth";
import Switch from "@mui/material/Switch";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function CreateUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  // Required Form Details
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState([]);
  const [selectedRole, setSelectedRole] = useState(0);
  const [outletData, setOutletData] = useState([]);
  const [status, setStatus] = useState(true);
  const [errors, setErrors] = useState({});
  const { logout } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  // const [switchStates, setSwitchStates] = useState([]);

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleApiRequest = async () => {
    updateToken(localStorage.getItem("token"));
    try {
      let response;

      if (id) {
        // Editing existing vendor
        response = await baseInstance.put(`/api/administration/users/createuser`, {
          ApiData: {
            name,
            emailAddress,
            password,
            selectedRole,
            status,
            outletData,
            id
          },
        });
      } else {
      // Creating new User
      response = await baseInstance.post(
        "/api/administration/users/createuser",
        {
          ApiData: {
            name,
            emailAddress,
            password,
            selectedRole,
            status,
            outletData,
          },
        }
      );
      }

      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleClick = () => {
    // Validate the form fields
    const validationErrors = {};
    if (name.trim() === "") {
      validationErrors.name = "Name is required";
    }
    if (emailAddress.trim() === "") {
      validationErrors.emailAddress = "Email is required";
    }

    if (password.trim() === "") {
      validationErrors.password = "Password is required";
    }

    if (selectedRole === 0) {
      validationErrors.selectedRole = "Select the Role";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      handleApiRequest()
        .then((response) => {
          if (response.status === 200) {
            navigate("/administration/users");
            id ? toast.success("User Updated!") : toast.success("User Created!");
          } else if (response.status === 400) {
            toast.error("Entered User Email is Already Exists");
          } else {
            console.log("Request failed with status:", response.status);
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            if (error.response.status === 403) {
              navigate(`/`);
            } else if (error.response.status === 401) {
              handleLogout();
            } else {
              console.error(error);
              toast.error("Record Failed!");
            }
          }
        });
    }
  };

  useEffect(() => {
    if (id) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .get(`/api/administration/users/createuser`, {
          params: { userId: id },
        })
        .then((response) => {
          const userData = response.data;
          setName(userData.name);
          setEmailAddress(userData.email);
          setPassword(userData.password);
          setSelectedRole(userData.SelectRoleId);
          setRole(userData.RoleList);
          setStatus(userData.status);
          setOutletData(userData.OutletList);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
            toast.error("Record Failed!");
          }
        });
    } else {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .get(`/api/administration/users/createuser`)
        .then((response) => {
          const userData = response.data;
          setRole(userData.RoleList);
          setOutletData(userData.OutletList);
          // setSwitchStates(outletData.map(item => item.value));
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
            toast.error("Record Failed!");
          }
        });
    }
  }, [id]);

  //   const handleModuleChange = (event) => {
  //     const selectedValue = event.target.value;
  //     setSelectedGroupId(selectedValue);
  //     // props.onChange(selectedValue); // Call the onChange callback with the selected value
  //   };

  const handleRoleChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedRole(selectedValues);
    // props.onChange(selectedValue); // Call the onChange callback with the selected value
  };

  const handleActiveStatus = (event) => {
    const newValue = event.target.checked;
    setStatus(newValue);
  };

  const handleSwitchChange = (index) => {
    const updatedOutletData = [...outletData];
    updatedOutletData[index].value = !updatedOutletData[index].value;
    setOutletData(updatedOutletData);
  };

  return (
    <Box className=" h-75 mx-auto w-90 text-align ">
      {id ? (
        <PageHeader title="Edit User" />
      ) : (
        <PageHeader title="Create User" />
      )}

      <Box component="form" sx={{ display: "flex", flexDirection: "column" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              label={t("Name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              error={errors.name}
              helperText={errors.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("Email Address")}
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              required
              error={errors.emailAddress}
              helperText={errors.emailAddress}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("Password")}
              value={password}
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              required
              error={errors.password}
              helperText={errors.password}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-select-small-label">{t("Role")}</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedRole}
                label="Role*"
                onChange={handleRoleChange}
                error={errors.selectedRole}
              >
                {role.map((roleid) => (
                  <MenuItem value={roleid.id}>{roleid.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container alignItems="center" item xs={2}>
            <Grid item>
              <Typography fontWeight="bold" variant="h5">
                Active
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={status}
                onChange={handleActiveStatus}
                color="warning"
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          variant="h5"
          sx={{
            marginTop: "15px",
            marginBottom: "10px",
            textAlign: t("langDir"),
          }}
          fontWeight="bold"
        >
          {" "}
          {t("Outlets")}{" "}
        </Typography>
        <Grid container>
          {outletData.map((item, index) => (
            <Grid container alignItems="center" item xs={2} key={index}>
              <Grid item>
                <Typography fontWeight="bold" variant="h5">
                  {item.label}
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  // checked={outletData[index]}
                  onChange={() => handleSwitchChange(index)}
                  checked={item.value}
                  color="warning"
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ textAlign: "right", marginTop: "20px", marginBottom: "20px" }}>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleClick}
        >
          {t("Save")}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            navigate("/administration/users");
          }}
        >
          {t("Cancel")}
        </button>
      </Box>
    </Box>
  );
}

export default CreateUser;
