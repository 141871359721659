import React from 'react'
import { useTranslation } from 'react-i18next';

export default function TaxSummary(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div class="modal-body">
                <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>
                    <h6 id="fromtodatesalebytype" style={{textAlign:t('langDir')}}>{t('Total')} {props.data.totaltrans} {t('Transactions')}</h6>
                </div>

                <table id="TaxSummaryviewtable" style={{ width: "100%" }}>
                    <thead>
                    <tr className="headingtop">
                        <td>{t('Net_Sale')}</td>
                        <td>{t("Tax_1")}</td>
                        {props.data.tax2total!=0 && <td>{t("Tax_2")}</td>}   
                        <td>{t('Total_Tax')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td >{props.data.netsales}</td>
                        <td>{props.data.tax1total}</td>
                        {props.data.tax2total!=0 && <td>{props.data.tax2total}</td>}               
                        <td>{props.data.vattotal}</td>
                    </tr>
                
                    </tbody>
                </table>
                <div >
                    <h6 style={{textAlign:t('langDir')}}>{t("Taxable_Summary")}</h6>
                    <h6 style={{textAlign:t('langDir')}}>{t("Net_Total")} : {props.data.netsales}</h6>
                    <h6 style={{textAlign:t('langDir')}}>{t("Total")} : {props.data.finaltotal}</h6>
                </div>
            </div>
        </div>
    )
}