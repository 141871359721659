import React, { useEffect, useState, useContext } from "react";
import PageHeader from "../../../components/PageHeader";
import {  Box,  TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { baseInstance } from "../../../api/axios";
import OutletDropdown from "../../../components/supportingComponents/outletdropdown";
import { updateToken } from "../../../api/axios";
import OutletContext from "../../../context/OutletContext";
import CustomModalInvoice from "../../../components/supportingComponents/CustomModelInvoice";
import CustomLoadingOverlay from "../../../components/supportingComponents/CustomLoadingOverLay";
import { CustomNoRowsOverlay } from "../../../components/supportingComponents/CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const defaultValueSalesInvoice = {
  transactnoinvoice: "",
  dateinvoice: "",
  vatnoinvoice: "",
  outletname: "",
  finaltotal: "",
  total: "",
  vattotal: "",
  companyname: "",
  salesdetail: [],
  discountdetail: [],
};

const Transactions = () => {
  const { logout } = useAuth();
  const navigate=useNavigate();
  const [rows, setRows] = useState([]);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const [totalRows, setTotalRows] = useState(0);
  const [selectedOutlet, setSelectedOutlet] = useState(OutletId);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedOutletId, setSelectedOutletId] = useState(null);

  const [selectedRowData, setSelectedRowData] = useState(
    defaultValueSalesInvoice
  );
  const [modalopen, setModelStatus] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const {t}=useTranslation();

const columns = [

  {
    field: "id",
    headerName: t('Transact'),
    flex: 0.2,
    headerClassName: "th-datagrid-theme",
    align: "left",
  },
  {
    field: "outlet_id",
    headerName: t('Outlet ID'),
    flex: 0.1,
    headerClassName: "th-datagrid-theme",
    align: "left",
  },
  {
    field: "price_without_vat",
    headerName: t('PRICE_WITHOUT_VAT'),
    flex: 0.1,
    headerClassName: "th-datagrid-theme",
    align: "left",
  },
  {
    field: "outlet_name",
    headerName: t('Outlet'),
    flex: 0.1,
    headerClassName: "th-datagrid-theme",
    align: "left",
  },
  {
    field: "vat_amount",
    headerName: t('VAT'),
    flex: 0.1,
    headerClassName: "th-datagrid-theme",
    align: "left",
  },
  {
    field: "final_total",
    headerName: t('Final_Total'),
    flex: 0.2,
    headerClassName: "th-datagrid-theme",
    align: "left",
  },
  {
    field: "order_date",
    headerName: t('Order_Date'),
    flex: 0.2,
    headerClassName: "th-datagrid-theme",
    align: "left",
  },
];
const handleLogout = async (e) => {
  const success = await logout();
  if (success) {
    document.body.dir = "ltr";
    navigate("/auth/login", { replace: true });
  }
};

  useEffect(() => {
    fetchRows();
  }, [selectedOutlet]);

  useEffect(() => {
    if (selectedRowId) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .get("/api/accounts/transactions/transactionsdetails", {
          params: {
            transact: selectedRowId,
            outletid: selectedOutletId,
          },
        })
        .then((response) => {
          const rowData = response.data; // Assuming the response data contains the row information
          setSelectedRowData(rowData);
          setLoadingTransactions(false);

        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
          setLoadingTransactions(false);

        });
    }
  }, [selectedRowId]);

  const handleOutletChange = (selectedValue) => {
    setSelectedOutlet(selectedValue);
    setOutletId(selectedValue);
  };

  const handleRowClick = (params) => {
    const { id,outlet_id } = params.row; // Assuming the unique identifier of the row is "id"
    setSelectedRowId(id);
    setSelectedOutletId(outlet_id)
    setModelStatus(true);
    // Open the modal here or set a flag to indicate that the modal should be open
  };

  const fetchRows = () => {
    // Perform API call to fetch data
    setLoadingTransactions(true);
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post("/api/accounts/transactions", {

          outletid: selectedOutlet,

      })
      .then((response) => {
        const { data, totalRows } = response.data;

        setRows(data);
        setTotalRows(totalRows);
        setLoadingTransactions(false);

      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
        setLoadingTransactions(false);

      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    String(row.id).includes(searchText)
  );

  return (
    <>
      <CustomModalInvoice
        isOpen={modalopen !== false}
        onClose={() => {
          setSelectedRowData(defaultValueSalesInvoice);
          setModelStatus(false);
        }} // Close the modal by setting selectedRowData to null
        data={selectedRowData}
      />
      <Box className=" h-75 mx-auto w-90 text-align " sx={{ width: "100%" }}>
        <PageHeader title={t("Transactions")} />
        <Box  sx={{ height: "450px", width: "100%" }}>
          <Box
            sx={{
              mx: "20px",
              my: "10px",
              display: "flex",
              justifyContent:"right",
            }}
          >
            {/* <Typography
              variant="h5"
              color={colors.orange[500]}
              fontWeight="bold"
              sx={{ textAlign: "left" }}
            >
              Sales Invoice Wise
            </Typography> */}
            <OutletDropdown
              onChange={handleOutletChange}
              defaultValue={selectedOutlet}
            />
             <TextField
              label={t('Search')}
              value={searchText}
              onChange={handleSearch}
              size="small"
              sx={{paddingLeft:"10px"}}
            />
          </Box>

          {/* <Box
            sx={{
              textAlign: "right",
              marginRight: "20px",
              marginBottom: "10px",
            }}
          >
           
          </Box> */}

          <Box sx={{ mx: "20px" }}>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              slots={{
                noRowsOverlay: () => <CustomNoRowsOverlay message="No Transactions!" />,
              }}
              // components={{
              //   LoadingOverlay: CustomLoadingOverlay,
              // }}
              loading={loadingTransactions}
              style={{ height: 630 }}
              // componentsProps={{
              //   loadingOverlay: {
              //     style: { zIndex: 9999 },
              //   },
              // }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                columns: {
                  columnVisibilityModel: {
                    outlet_id: false,
               
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
              onRowClick={handleRowClick}
              // paginationMode="server"
              // rowCount={totalRows}
              // onPageChange={handlePageChange}
              // onPageSizeChange={handlePageSizeChange}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Transactions;
