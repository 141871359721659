import React from 'react'
import { BarChart, Bar, CartesianGrid, XAxis, YAxis ,Tooltip ,Legend } from 'recharts';
import { useTranslation } from 'react-i18next';


function BarRechartComponents(props) {
    const {t}=useTranslation();

    return (

        <BarChart width={750} height={350} data={props.bargraphlist}>
            <Bar dataKey="Amount" fill="#106e8a" />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
        </BarChart>
    );

}



export default function Salesbyhour(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>
                <h6 ></h6>
            </div>

            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td style={{ fontWeight: "bold" }}>{t('Date')}</td>
                        <td style={{ fontWeight: "bold" }}>0</td>
                        <td style={{ fontWeight: "bold" }}>1</td>
                        <td style={{ fontWeight: "bold" }}>2</td>
                        <td style={{ fontWeight: "bold" }}>3</td>
                        <td style={{ fontWeight: "bold" }}>4</td>
                        <td style={{ fontWeight: "bold" }}>5</td>
                        <td style={{ fontWeight: "bold" }}>6</td>
                        <td style={{ fontWeight: "bold" }}>7</td>
                        <td style={{ fontWeight: "bold" }}>8</td>
                        <td style={{ fontWeight: "bold" }}>9</td>
                        <td style={{ fontWeight: "bold" }}>10</td>
                        <td style={{ fontWeight: "bold" }}>11</td>
                        <td style={{ fontWeight: "bold" }}>12</td>
                        <td style={{ fontWeight: "bold" }}>13</td>
                        <td style={{ fontWeight: "bold" }}>14</td>
                        <td style={{ fontWeight: "bold" }}>15</td>
                        <td style={{ fontWeight: "bold" }}>16</td>
                        <td style={{ fontWeight: "bold" }}>17</td>
                        <td style={{ fontWeight: "bold" }}>18</td>
                        <td style={{ fontWeight: "bold" }}>19</td>
                        <td style={{ fontWeight: "bold" }}>20</td>
                        <td style={{ fontWeight: "bold" }}>21</td>
                        <td style={{ fontWeight: "bold" }}>22</td>
                        <td style={{ fontWeight: "bold" }}>23</td>

                    </tr>
                </thead>
                <tbody>

                    {
                        props.data.querysalesbyhour.map((item, index) => (
                            <tr key={index}>
                                <td style={{ fontWeight: "bold" }}>{item.saledate}</td>
                                <td>{item.zero}</td>
                                <td>{item.one}</td>
                                <td>{item.two}</td>
                                <td>{item.three}</td>
                                <td>{item.four}</td>
                                <td>{item.five}</td>
                                <td>{item.six}</td>
                                <td>{item.seven}</td>
                                <td>{item.eight}</td>
                                <td>{item.nine}</td>
                                <td>{item.ten}</td>
                                <td>{item.eleven}</td>
                                <td>{item.twelve}</td>
                                <td>{item.thirteen}</td>
                                <td>{item.forteen}</td>
                                <td>{item.fifteen}</td>
                                <td>{item.sixteen}</td>
                                <td>{item.seventeen}</td>
                                <td>{item.eighteen}</td>
                                <td>{item.nineteen}</td>
                                <td>{item.twenty}</td>
                                <td>{item.twentyone}</td>
                                <td>{item.twentytwo}</td>
                                <td>{item.twentythree}</td>


                            </tr>
                        ))
                    }



                </tbody>
            </table>
            <br/>
            <BarRechartComponents bargraphlist={props.data.bargraphlist} />
        </div>

    )

}