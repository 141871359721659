import { Box, TextField, Grid,  Typography } from "@mui/material";
import { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import { updateToken } from "../../../api/axios";
import { baseInstance } from "../../../api/axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";


function VendorForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const {t}=useTranslation();
  const { logout } = useAuth();

  // Required Form Details
  const [name, setName] = useState("");
  const [vatNo, setVatNo] = useState("");
  const [crNo, setCrNo] = useState("");
  const [coNo, setCoNo] = useState("");
  const [buildingNo, setBuildingNo] = useState("");
  const [street, setStreet] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [additionalNo, setAdditionalNo] = useState("");
  const [errors, setErrors] = useState({});

  const handleApiRequest = async () => {
    updateToken(localStorage.getItem("token"));
    try {
      let response;

      if (id) {
        // Editing existing vendor
        response = await baseInstance.put(`/api/accounts/vendors/editvendor`, {
          ApiData: {
            name,
            vatNo,
            crNo,
            coNo,
            buildingNo,
            street,
            district,
            city,
            country,
            zipCode,
            additionalNo,
            id,
          },
        });
      } else {
        // Creating new vendor
        response = await baseInstance.post(
          "/api/accounts/vendors/createvendor",
          {
            ApiData: {
              name,
              vatNo,
              crNo,
              coNo,
              buildingNo,
              street,
              district,
              city,
              country,
              zipCode,
              additionalNo,
            },
          }
        );
      }

      return response;
    } catch (error) {
      throw error;
    }
  };
  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const handleClick = () => {
    // Validate the form fields
    const validationErrors = {};
    if (name.trim() === "") {
      validationErrors.name = "Name is required";
    }
    if (vatNo.trim() === "") {
      validationErrors.vatNo = "VAT No. is required";
    }
    if (crNo.trim() === "") {
      validationErrors.crNo = "CR No. is required";
    }
    if (coNo.trim() === "") {
      validationErrors.coNo = "CO No. is required";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      handleApiRequest()
        .then((response) => {
          if (response.status === 200) {
            navigate("/accounts/vendors");

            toast.success("Record Created!");
          } else {
            console.log("Request failed with status:", response.status);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
          toast.error("Record Failed!");
          console.log(error);
        });
    }
  };

  useEffect(() => {
    // Fetch the vendor details if editing an existing vendor
    if (id) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .get(`/api/accounts/vendors/getvendors`, { params: { vendorId: id } })
        .then((response) => {
          const vendorData = response.data;
          // Set the form fields with the vendor data
          setName(vendorData.vendornameEng);
          setVatNo(vendorData.vatno);
          setCrNo(vendorData.crno);
          setCoNo(vendorData.coid);
          setBuildingNo(vendorData.bldgno);
          setStreet(vendorData.street);
          setDistrict(vendorData.district);
          setCity(vendorData.city);
          setCountry(vendorData.country);
          setZipCode(vendorData.postalcode);
          setAdditionalNo(vendorData.additionalno);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
        });
    }
  }, [id]);

  return (
    <Box className=" h-75 mx-auto w-90 text-align ">
      {id ? <PageHeader title={t("Edit_Vendor")} /> : <PageHeader title={t("Create_Vendor")} />}
      

      <Box component="form" sx={{ display: "flex", flexDirection: "column" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              label={t("Name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              error={errors.name}
              helperText={errors.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("VAT_No")}
              value={vatNo}
              onChange={(e) => setVatNo(e.target.value)}
              required
              error={errors.vatNo}
              helperText={errors.vatNo}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("CR_No")}
              value={crNo}
              onChange={(e) => setCrNo(e.target.value)}
              required
              error={errors.crNo}
              helperText={errors.crNo}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("CO_No")}
              value={coNo}
              onChange={(e) => setCoNo(e.target.value)}
              required
              error={errors.coNo}
              helperText={errors.coNo}
              fullWidth
            />
          </Grid>
        </Grid>

        <Typography variant="h5" sx={{ marginTop: "15px", marginBottom: "10px",textAlign:t('langDir') }} fontWeight="bold">
          {" "}
          {t("Address")}{" "}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              label={t("Building_No")}
              value={buildingNo}
              onChange={(e) => setBuildingNo(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("Street")}
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={t("District")}
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} marginTop="5px">
          <Grid item xs={3}>
            <TextField
              label={t("City")}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={t("Country")}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={t("Zip_Code")}
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label={t("Additional_No")}
              value={additionalNo}
              onChange={(e) => setAdditionalNo(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ textAlign: "right", marginTop: "20px", marginBottom: "20px" }}>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleClick}
        >
          {t("Save")}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            navigate("/accounts/vendors");
          }}
        >
          {t("Cancel")}
        </button>
      </Box>
    </Box>
  );
}

export default VendorForm;
