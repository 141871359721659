import React from 'react'
import { useTranslation } from 'react-i18next';

export default function SalesPromo(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>

            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Promo_Num')}</td>
                        <td>{t('Desc')}</td>
                        <td>{t('Qty')}</td>
                        <td>{t('Final_Total')}</td>
                    </tr>
                </thead>
                <tbody>

                    {
                        props.data.querypromobytype1.map((item, index) => (
                            <tr key={index}>
                                <td>{item.Promonum}</td>
                                <td>{item.promodescript}</td>
                                <td>{item.promoquantity}</td>
                                <td>{item.promosum}</td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </div>

    )

}