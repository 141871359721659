import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tokens } from "../../../theme";
import EmployeePerformance from "./EmployeePerformance";
import OutletDropdown from "../../../components/supportingComponents/outletdropdown";
import CustomDateRange from "../../../components/CustomDateRange";
import { useContext } from "react";
import { useEffect } from "react";
import OutletContext from "../../../context/OutletContext";
import useAuth from "../../../hooks/useAuth";
import { baseInstance, updateToken } from "../../../api/axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DateContext from "../../../context/DateContext";

const ExecutiveSummary = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateContext);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [dataOutlet, setDataOutlet] = useState({
    total_sales: 0,
    total_orders: 0,
    total_tax: 0,
    total_nettotal: 0,
    total_void: 0,
    total_void_number: 0,
    total_discount: 0,
    total_discount_number: 0,
    productdata: [],
    promodata: [],
    voiddata: [],
    employeelinedata: {
      employeename: [],
      employeesales: [],
    },
  });
  const Lang = localStorage.getItem("Lang");

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };
  const handleOutletChange = (selectedValue) => {
    setOutletId(selectedValue);
  };

  useEffect(() => {
    if (startDate) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post("/api/businessintelligence/executivesummary", {
          storeid:
            OutletId === ""
              ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
              : OutletId,
          startdate: startDate,
          enddate: endDate,
        })
        .then((response) => {
          if (response.status === 200) {
            setDataOutlet(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            handleLogout();
            toast.error("Unauthorized access");
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.log(error);
          }
          // setCardLoading(false);
        });
    }
  }, [OutletId, startDate, endDate]);

  return (
    <Box className="h-75 mx-auto w-90 text-align">
      <PageHeader centerText={t("Executive_Summary")} />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CustomDateRange sx={{ paddingRight: "20px" }} />
        <OutletDropdown defaultValue={OutletId} onChange={handleOutletChange} />
      </Box>
      <Grid container spacing={2}>
        {/* First Container */}
        <Grid item xs={6}>
          <Box height="100%">
            <Grid container direction="column" spacing={2}>
              {/* First Card */}
              <Grid item>
                <Paper elevation={3}>
                  <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={4}
                    style={{
                      boxShadow:
                        theme.palette.mode === "dark"
                          ? "0 1px 2.94px 0.06px #fff"
                          : null,

                      backgroundColor: colors.default[100],
                    }}
                  >
                    {" "}
                    <Typography variant="h4">
                      {dataOutlet["total_orders"]}
                     

                    </Typography>
                    <Typography variant="h6">
                      {t("Total Number of closed Orders")}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              {/* Second Card */}
              <Grid item>
                <Paper elevation={3}>
                  <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={4}
                    style={{
                      boxShadow:
                        theme.palette.mode === "dark"
                          ? "0 1px 2.94px 0.06px #fff"
                          : null,

                      backgroundColor: colors.default[100],
                    }}
                  >
                    <Typography variant="h4">
                      {dataOutlet["total_sales"]}
                    </Typography>
                    <Typography variant="h6">{t("Total Net sales")}</Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Second Container */}
        <Grid item xs={6}>
          <TableContainer
            component={Paper}
            style={{
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Table>
              <TableBody>
                {/* Table Rows */}
                <TableRow>
                  <TableCell>
                    {t("Gross Sales without Discount & Tax")}
                  </TableCell>
                  <TableCell> {dataOutlet["total_nettotal"]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Taxable_Amount")}</TableCell>
                  <TableCell> {dataOutlet["total_tax"]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Discounted_Amount")}</TableCell>
                  <TableCell> {dataOutlet["total_discount"]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Void_Amount")}</TableCell>
                  <TableCell> {dataOutlet["total_void"]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("Average_Per_Order")}</TableCell>
                  <TableCell>{dataOutlet["average_order"]}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Typography
              variant="h4"
              style={{
                alignItems: "center",
                margin: "10px",
                paddingTop: "10px",
              }}
            >
              {dataOutlet["total_discount"]}
            </Typography>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {t("Total_Discounts")}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Typography
              variant="h4"
              style={{
                alignItems: "center",
                margin: "10px",
                paddingTop: "10px",
              }}
            >
              {dataOutlet["total_void"]}
            </Typography>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {t("Total_Void/Return")}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <Typography
              variant="h4"
              style={{
                alignItems: "center",
                margin: "10px",
                paddingTop: "10px",
              }}
            >
              {dataOutlet["sumproduct"]}
            </Typography>
            <Typography
              variant="body1"
              style={{ alignItems: "center", margin: "10px" }}
            >
              {t("Top 10 Products Sales")}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={1} marginTop={2}>
        <Grid item xs={4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: 250,
                height: 250,
                overflow: "auto",
                boxShadow:
                  theme.palette.mode === "dark"
                    ? "0 1px 2.94px 0.06px #fff"
                    : null,

                backgroundColor: colors.default[100],
              }}
            >
              <Table>
                <TableBody>
                  {dataOutlet["promodata"].map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item[0]}</TableCell>
                      <TableCell>{item[1]}</TableCell>
                      <TableCell>{item[2]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: 250,
                height: 250,
                overflow: "auto",
                boxShadow:
                  theme.palette.mode === "dark"
                    ? "0 1px 2.94px 0.06px #fff"
                    : null,

                backgroundColor: colors.default[100],
              }}
            >
              <Table>
                <TableBody>
                  {dataOutlet["voiddata"].map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item[0]}</TableCell>
                      <TableCell>{item[1]}</TableCell>
                      <TableCell>{item[2]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: 250,
                height: 250,
                overflow: "auto",
                boxShadow:
                  theme.palette.mode === "dark"
                    ? "0 1px 2.94px 0.06px #fff"
                    : null,

                backgroundColor: colors.default[100],
              }}
            >
              <Table>
                <TableBody>
                  {dataOutlet["productdata"].map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item[0]}</TableCell>
                      <TableCell>{item[1]}</TableCell>
                      <TableCell>{item[2]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <Paper
        elevation={3}
        style={{
          padding: "0px",
          boxShadow:
            theme.palette.mode === "dark" ? "0 1px 2.94px 0.06px #fff" : null,

          backgroundColor: colors.default[100],
        }}
      >
        <Typography variant="h4" marginTop={3} paddingTop={2} paddingLeft={2} style={{textAlign:Lang ==='ar'?'right': 'left'}}>
          {t("Employee performance Overiew")}
        </Typography>
        <EmployeePerformance data={dataOutlet["employeelinedata"]} />
      </Paper>
    </Box>
  );
};

export default ExecutiveSummary;
