import React from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { useTranslation } from 'react-i18next';



function PieRechartComponents(props){

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

    const pieData = props.piedata

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
                    <label>{`${payload[0].name} : ${payload[0].value}%`}</label>
                </div>
            );
        }
        return null;
    };

        return (
            <PieChart width={730} height={300}>
                <Pie data={pieData} color="#000000" dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={90} fill="#8884d8" >
                    {
                        pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend />
            </PieChart>
        )
    
}


export default function ProfitByReportCategory(props){
    const {t}=useTranslation();

return(
    <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>
            <hr/>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Desc')}</td>
                        <td>{t('Qty')}</td>
                        <td>{t('Value')}</td>
                        <td>{t('Profit')}</td>
                        <td>% {t('Profit')}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.querycost1.map((item, index) => (
                            <tr key={index}>
                                <td>{item.descript}</td>
                                <td>{item.qty}</td>
                                <td>{item.datavalues}</td>
                                <td>{item.profit}</td>
                                <td>{item.profitpercent}</td>
                            </tr>
                        )
                        )
                    }


                    <tr  className="border border-dark">
                        <td className="font-weight-bold">{t('Total')}</td>
                        <td className="font-weight-bold">{props.data.totalqty}</td>
                        <td className="font-weight-bold">{props.data.totalvalue}</td>
                        <td className="font-weight-bold">{props.data.profitvalue}</td>
                        <td className="font-weight-bold"></td>
                    </tr>
                </tbody>
            </table>
            <PieRechartComponents piedata={props.data.piedata}/>
        </div>

)
}