import React from 'react'
import { useTranslation } from 'react-i18next';

export function ActiveEmployeeListing(props){
    const {t}=useTranslation();

return(
    <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>
            <hr/>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Employee_No')}</td>
                        <td>{t('Name')}</td>
                        <td>{t('Active_InActive')}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.employeedata.map((item, index) => (
                            <tr key={index}>
                                <td>{item.employeeno}</td>
                                <td>{item.empname}</td>
                                <td>{item.active}</td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>
        </div>
)
}