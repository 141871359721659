import React, { createContext, useState, useEffect } from "react";
import {baseInstance }from "../api/axios";
import jwtDecode from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import { noHeadersInstance, updateToken } from "../api/axios";


const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState((false));
  const [isLoading, setIsLoading] = useState(true);
  const navigate =useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    // let decoded = jwt.decode(token);
    try{
    let decoded = jwtDecode(token);
}
    catch{
        console.log("error in token validation");
    }

    if (token) {
      const validateToken = async () => {
        // try {
        //     // Use jwtDecode to decode the token
        //     const decodedToken = jwtDecode(token);
        //     const exp = decodedToken.exp;
        //     const isExpired = Date.now() >= exp * 1000; // Check if token is expired

        //     if (isExpired) {
        //       // Token expired, perform logout
        //       await logout();

        // const fetchUserPermissions = async () => {
        //     try {
        //       const response = await axios.get('/api/user/permissions', {
        //         headers: {
        //           Authorization: `Bearer ${token}`,
        //         },
        //       });
        //       const { permissions } = response.data;
        //       localStorage.setItem('permissions', JSON.stringify(permissions));
        //       setIsLoading(false);
        //     } catch (error) {
        //       // Handle error
        //       setIsLoading(false);
        //     }
        //   };
        //   fetchUserPermissions();
        // } 
  

        try {
          updateToken(token);
          const response = await baseInstance.get("/api/user", {});
          const fetchedUser = response.data;
          setAuth(fetchedUser);
          setIsLoading(false);
        } catch (error) {
          // If the token is expired, refresh it
          if (error.response.status === 401) {
            const success = await refreshToken();
            if (success) {
              // Token refreshed successfully, fetch user data again
              await validateToken();
            } else {
              // Failed to refresh token, log out the user
              logout();
              navigate("/auth/login");
                
            }
          } else {
            // Other error occurred, log out the user
            logout();
            navigate("/auth/login");
          }
        }
      };
      validateToken();
    } else {
      setIsLoading(false);
    }
  }, []);

  const refreshToken = async () => {
    try {
      const response = await noHeadersInstance.post("/api/auth/refresh");
      const token = response.data.access_token;
      localStorage.setItem("token", token);
      return true;
    } catch (error) {
      return false;
    }
  };

  const login = async (username, password) => {
    try {
      const response = await noHeadersInstance.post("/api/auth/login", {
        username,
        password,
      });
      const token = response.data.access_token;
      localStorage.setItem("token", token);
      const Lang = response.data.Lang;
      const Theme=response.data.Theme;
      localStorage.setItem("Lang",Lang);
      localStorage.setItem("Theme",Theme);
      const fetchedUser = { email: username }; 
      setAuth(fetchedUser);
      setIsLoading(false);
      // console.log(Lang);
      return true;
    } catch (error) {
      return false;
    }
  };

  const logout = async () => {
    try {
      updateToken(localStorage.getItem("token"));
      localStorage.removeItem("token");
      setAuth(false);
      await baseInstance.post("/api/auth/logout", false, {});
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
