import { Box,   TextField } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect} from "react";
import { baseInstance } from "../../../api/axios";
import { updateToken } from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import CustomLoadingOverlay from "../../../components/supportingComponents/CustomLoadingOverLay";
import { CustomNoRowsOverlay } from "../../../components/supportingComponents/CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import useAuth from "../../../hooks/useAuth";



function Outlets() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [searchText, setSearchText] = useState("");
  const { t } = useTranslation();
  const { logout } = useAuth();


  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

    const handleEdit = (id) => {
    navigate(`/administration/outlets/createoutlet/${id}`);
  };

  const columns = [
   
    {
        field: "actions",
        headerName: "",
        headerClassName: "th-datagrid-theme",
        width: 0.1,
        renderCell: (params) => (
          <IconButton onClick={() => handleEdit(params.row.id)}>
            <EditIcon />
          </IconButton>
        ),
      },
      {
        field: "id",
        headerName: "id",
        flex: 0.1,
        headerClassName: "th-datagrid-theme",
      },
    {
      field: "outlet_name",
      headerName: "Outlet Name",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
    },
    {
      field: "address",
      headerName: "Address",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "company",
      headerName: "Company",
      flex: 0.4,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "subscribed_date",
      headerName: "Subscribed Date",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  const [loadingCompanies, setLoadingOutlets] = useState(true);


  useEffect(() => {
    fetchRows();
  }, []);

    const handleNewCompany = () => {
      navigate("/administration/outlets/createoutlet");
    };



  const fetchRows = () => {
    setLoadingOutlets(true);
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/administration/outlets", {})
      .then((response) => {
        const { data, totalRows } = response.data;
        setRows(data);
        setTotalRows(data.totalRows);

        // setTotalRows(data.totalRows);
        setLoadingOutlets(false);
      })
      .catch((error) => {
         if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
        setLoadingOutlets(false);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    String(row.outlet_name).includes(searchText)
  );

  return (
    <Box className=" h-75 mx-auto w-90 text-align ">
      <PageHeader title={t("Outlets")} />
      <Box className=" mx-auto text-align " sx={{ width: "100%" }}>
        <Box sx={{ textAlign: "right", marginBottom: "10px" }}>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            style={{ marginLeft: "10px" }}
            onClick={handleNewCompany}
          >
            Create Outlet
          </button>
        </Box>
        <Box mb="10px" sx={{ textAlign: "right" }}>
          <TextField
            label="Search"
            // value={searchText}
            onChange={handleSearch}
            size="small"
            style={{ marginLeft: "10px" }}
          />
        </Box>

        <DataGrid
          rows={filteredRows}
        
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            // columns: {
            //   columnVisibilityModel: {
            //     id: false,
            //   },
            // },
          }}
          slots={{
            noRowsOverlay: () => (
              <CustomNoRowsOverlay message="No Outlets!" />
            ),
          }}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
        //   loading={loadingCompanies}
          style={{ height: 630 }}
          componentsProps={{
            loadingOverlay: {
              style: { zIndex: 9999 },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          // paginationMode="server"
          rowCount={totalRows}
        />
      </Box>
    </Box>
  );
}

export default Outlets;
