import { useTranslation } from "react-i18next";
import { Modal, Select, Radio, Form, Button, Grid } from "antd";
import { toast } from "react-toastify";
import { TextField, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { useState } from "react";
import { useEffect } from "react";
import { baseInstance, updateToken } from "../../../api/axios";
import jwtDecode from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const { Option } = Select;

const AddCategory = ({ isOpen,LoadPageContent, onClose, selectedOutlet }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedSubwayCategory, setSelectedSubwayCategory] = useState("");
  const [selectedPosCategory, setSelectedPosCategory] = useState(0);
  const [selectPosProduct, setSelectedPosProduct] = useState(0);
  const [form] = Form.useForm();
  const [posCategory, setPosCategory] = useState([]);
  const [posProduct, setPosProduct] = useState([]);
  const [selectedValueby, setSelectedValueby] = useState(0);
  const [errors, setErrors] = useState({});
  const [fieldType, setFieldType] = useState("Category");
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setPosCategory([]);
      setPosProduct([]);
      setSelectedPosCategory(0);
      setSelectedSubwayCategory("");
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post("/api/integration/subwaysetup/poscategory", {
          storeid:
            selectedOutlet === ""
              ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
              : selectedOutlet,
        })
        .then((response) => {
          if (response.status === 200) {
            // setTableData(response.data);
            let data = response.data;
            setPosCategory(data.categorylist);
            setPosProduct(data.productlist);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            handleLogout();
            toast.error("Unauthorized access");
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.log(error);
          }
        });
    }
  }, [isOpen]);

  const optionsSubwayCategory = [
    { label: "drinkSales", value: "drinkSales" },
    { label: "partySales", value: "partySales" },
    // { label: "schoolLunchSales", value: "schoolLunchSales" },
    // { label: "tcbySales", value: "tcbySales" },
    // { label: "mmilSales", value: "mmilSales" },
    // { label: "miscSales", value: "miscSales" },
    { label: "numberOfFootlongs", value: "numberOfFootlongs" },
    { label: "numberOfSixInch", value: "numberOfSixInch" },
    { label: "numberOfFourInch", value: "numberOfFourInch" },
    { label: "numberOfSaladPlates", value: "numberOfSaladPlates" },
    { label: "numberOfDrinks", value: "numberOfDrinks" },
    // { label: "numberOfGiantSubs", value: "numberOfGiantSubs" },
    // { label: "numberOfPlatters", value: "numberOfPlatters" },
    // { label: "numberOfPartyBreads", value: "numberOfPartyBreads" },
    // { label: "numberOfFreeUnits", value: "numberOfFreeUnits" },
  ];

  const optionsField = [
    { label: "Category", value: "Category" },
    { label: "Item", value: "Item" },

  ];



  const handleApiRequest = async () => {
    updateToken(localStorage.getItem("token"));
    try {
      let response;
      const fieldValues = form.getFieldsValue();
      response = await baseInstance.post(
        "/api/integration/subwaysetup/addapidata",
        {
          ApiData: {
            selectedSubwayCategory,
            fieldType,
            itemlist: fieldValues.selectedPosItem,
            categorylist: fieldValues.selectedPosCategory,
            valueby: fieldValues.selectedValueby,
            selectedOutlet,
          },
        }
       
      );
      form.resetFields();
      return response;
    } catch (error) {
      form.resetFields();
      throw error;
      
    }
  };

  const handleClick = () => {
    const validationErrors = {};
    if (selectedSubwayCategory.trim() === "") {
      validationErrors.selectedSubwayCategory = "Subway Category is required";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      handleApiRequest()
        .then((response) => {
          if (response.status === 200) {
            toast.success("Data Added");
            LoadPageContent();
            onClose();
          } else if (response.status === 400) {
            toast.error("Data Already Added!");
          } else {
            console.log("Request failed with status:", response.status);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
        });
    }
  };

  return (
    <Modal
      title="Add API Data"
      visible={isOpen}
      onCancel={onClose}
      onOk={handleClick}
    >
      <Form form={form} layout="vertical" initialValues={{
        'selectedfieldType':fieldType}
      }>
        <Form.Item
          label={t("Select_Field")}
          name="selectedSubwayCategory"
          rules={[
            { required: true, message: "Please select at least one Field" },
          ]}
        >
          <Select
            placeholder={t("Select_Field")}
            optionFilterProp="children"
            value={selectedSubwayCategory}
            onChange={(values) => setSelectedSubwayCategory(values)}
            style={{ width: "100%" }}
          >
            {optionsSubwayCategory.map((subwaycategoryitem) => (
              <Option
                key={subwaycategoryitem.label}
                value={subwaycategoryitem.value}
              >
                {subwaycategoryitem.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
       
        <Form.Item
          label={t("Select_Type")}
          name="selectedfieldType"
          rules={[
            { required: true, message: "Please select at least one Type" },
          ]}
        >
          <Select
            placeholder={t("Select_Type")}
            optionFilterProp="children"
            value={fieldType}
            onChange={(values) => setFieldType(values)}
            style={{ width: "100%" }}
          >
            {optionsField.map((field) => (
              <Option
                key={field.label}
                value={field.value}
              >
                {field.value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {fieldType==="Category" ?(<Form.Item
          label={t("Select_Category")}
          name="selectedPosCategory"
        >
          <Select
            mode="multiple"
            placeholder={t("Select_Category")}
            optionFilterProp="children"
            value={selectedPosCategory}
            onChange={(values) => setSelectedPosCategory(values)}
            style={{ width: "100%" }}
          >
            {posCategory.map((poscategoryitem) => (
              <Option
                key={poscategoryitem.categoryid}
                value={poscategoryitem.categoryid}
              >
                {poscategoryitem.categoryname}
              </Option>
            ))}
          </Select>
        </Form.Item>):null}

        {fieldType!=="Category"?(<Form.Item
          label={t("Select_Item")}
          name="selectedPosItem"
        >
          <Select
            mode="multiple"
            placeholder={t("Select_Item")}
            optionFilterProp="children"
            value={selectPosProduct}
            onChange={(values) => setSelectedPosProduct(values)}
            style={{ width: "100%" }}
          >
            {posProduct.map((positemitem) => (
              <Option
                key={positemitem.productid}
                value={positemitem.productid}
              >
                {positemitem.productname}
              </Option>
            ))}
          </Select>
        </Form.Item>):null}
        
        <Form.Item label="Value By" name="selectedValueby" initialValue={0}>
          <Radio.Group onChange={(e) => setSelectedValueby(e.target.value)}>
            <Radio value={0}>Sales</Radio>
            <Radio value={1}>Count</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCategory;
