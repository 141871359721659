import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Salesreconcilation(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>
            <h7 style={{ fontSize: "16px" }}>{t('Daily_Sales')}</h7>
            
            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Date')}</td>
                        <td>{t('Net_Sale')}</td>
                        <td>{t('POS_Tendered')}</td>
                        <td>{t('Diff_Amount')}</td>

                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.datadailysales.map((item, index) => (
                            <tr key={index}>
                                <td>{item.opendate}</td>
                                <td>{item.possales}</td>
                                <td>{item.postender}</td>
                                <td>{item.diff}</td>
                            </tr>
                        ))
                    }
                     <tr>
                        <td className='font-weight-bold'>{t('Total')}</td>
                        <td className='font-weight-bold'>{props.data.Totalvalue4}</td>
                        <td className='font-weight-bold'>{props.data.Totalvalue5}</td>
                        <td className='font-weight-bold'>{props.data.Totalvalue6}</td>
        

                    </tr>
                </tbody>
            </table>
            <h7 style={{ fontSize: "16px" }}>{t('Invoices')}</h7>
            {props.data.Totalvalue6!=0?




            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Date')}</td>
                        <td>{t('Transact')}</td>
                        <td>{t('Net_Sale')}</td>
                        <td>{t('POS_Tendered')}</td>
                        <td>{t('Diff_Amount')}</td>

                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.querysales.map((item, index) => (
                            <tr key={index}>
                                <td>{item.opendate}</td>
                                <td>{item.transact}</td>
                                <td>{item.possales}</td>
                                <td>{item.postender}</td>
                                <td>{item.diff}</td>
                            </tr>
                        ))
                    }
                                         <tr>
                        <td className='font-weight-bold'>{t('Total')}</td>
                        <td className='font-weight-bold'></td>
                        <td className='font-weight-bold'>{props.data.Totalvalue1}</td>
                        <td className='font-weight-bold'>{props.data.Totalvalue2}</td>
                        <td className='font-weight-bold'>{props.data.Totalvalue3}</td>

                    </tr>
                </tbody>
            </table>
            :  <h6>{t('No_Invoices')} </h6>}
        </div>

    )

}