import {
  Box,
  TextField,
  Select,
  MenuItem,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import PageHeader from "../../../components/PageHeader";
import jwtDecode from "jwt-decode";
import { updateToken } from "../../../api/axios";
import InputLabel from "@mui/material/InputLabel";
import { FormControl, FormHelperText } from "@mui/material";
import { baseInstance } from "../../../api/axios";
import CustomLoadingOverlay from "../../../components/supportingComponents/CustomLoadingOverLay";
import { CustomNoRowsOverlay } from "../../../components/supportingComponents/CustomNoRowsOverlay";
import OutletContext from "../../../context/OutletContext";
import CustomModalTransactionDetail from "../../../components/supportingComponents/CustomModelTransactionDetail";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";

const defaultValueSalesInvoice = {
  transactnoinvoice: "",
  dateinvoice: "",
  vatnoinvoice: "",
  outletname: "",
  finaltotal: "",
  total: "",
  vattotal: "",
  companyname: "",
  salesdetail: [],
  discountdetail: [],
};

function CreateInvoice() {
  const [outlets, setOutlets] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [rows, setRows] = useState([]);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const [searchText, setSearchText] = useState("");
  const [selectedOutlet, setSelectedOutlet] = useState(OutletId[0]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [customerName, setCustomerName] = useState("");
  const [poNumber, setPoNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(
    defaultValueSalesInvoice
  );
  const [modalopen, setModelStatus] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const columns = [
    {
      field: "id",
      headerName: t("Transact"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
    },
    {
      field: "date",
      headerName: t("Date"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },

    {
      field: "vat_amount",
      headerName: t("Total_Tax"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "total_amount",
      headerName: t("Final_Total"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  const handleRowClick = (params) => {
    const { id } = params.row;
    setSelectedRowId(id);
    setModelStatus(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    try {
      const fetchOutletsData = jwtDecode(token);
      setOutlets(fetchOutletsData.outlets);
    } catch {
      console.log("error in token validation");
    }
  }, []);

  const fetchVendors = () => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/accounts/invoices/getvendorlist", {})
      .then((response) => {
        const { data } = response.data;
        setVendors(data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
      });
  };

  const handleApiRequest = async () => {
    updateToken(localStorage.getItem("token"));
    try {
      let response;
      response = await baseInstance.post("api/accounts/invoices/createinvoice", {
        ApiData: {
          selectedRowId,
          selectedOutlet,
          customerName,
          poNumber,
          selectedVendor,
        },
      });
      return response;
    } catch (error) {
      throw(error);
    }
  };

  const handleClick = () => {
    // Validate the form fields
    const validationErrors = {};
    if (customerName.trim() === "") {
      validationErrors.customerName =t('Customer_Name_Is_Required');
    }
    if (poNumber.trim() === "") {
      validationErrors.poNumber = t('PO_No_Is_Required');
    }
    if (selectedVendor === null) {
      validationErrors.selectedVendor = t('Vendor_Is_Required');
    }
    if (selectedRowId === null) {
      validationErrors.selectedRowId = t('Select_The_Transaction') ;
      toast.error(t('Select_The_Transaction'));
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      handleApiRequest()
        .then((response) => {
          if (response.status === 200) {
      
            if (response.data.message === "invoice exists") {
              toast.error(
                t("Invoice_With_transaction") +
                  String(selectedRowId) +
                  t('Is_Already_Created')
              );
            } else {
              navigate("/accounts/invoices");
              toast.success(t('Record_Created'));
            }
          } else {
            toast.success(t('Record_Failed'));
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            toast.error(t('Record_Failed'));
            console.log(error);
          }
         
        });
      // }
    }
  };

  const fetchInvoices = () => {
    setLoadingTransactions(true);
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/accounts/invoices/createinvoice/transaction", {
        params: { outletid: selectedOutlet },
      })
      .then((response) => {
        const { data, totalrows } = response.data;
        setRows(data);
        setTotalRows(data.totalrows);
        setLoadingTransactions(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
        setLoadingTransactions(false);
      });
  };

  useEffect(() => {
    fetchInvoices();
  }, [selectedOutlet]);

  useEffect(() => {
    fetchVendors();
  }, []);

  useEffect(() => {
    if (selectedRowId) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .get("/api/accounts/invoices/createinvoice/transactiondetails", {
          params: {
            transact: selectedRowId,
            outletid: selectedOutlet,
          },
        })
        .then((response) => {
          const rowData = response.data;
          setSelectedRowData(rowData);
         
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
          }
        });
    }
  }, [selectedRowId]);

  const handleOutletChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOutlet(selectedValue);
  };

  const handleVendorChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedVendor(selectedValue);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    String(row.id).includes(searchText)
  );
  return (
    <>
      <CustomModalTransactionDetail
        isOpen={modalopen !== false}
        onClose={() => {
          setSelectedRowData(defaultValueSalesInvoice);
          setModelStatus(false);
        }}
        data={selectedRowData}
      />

      <Box className=" h-75 mx-auto w-90 text-align ">
        <PageHeader title={t("Create_Invoice")} />

        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", height: "635px" }}
        >
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                label={t("Contact_Name")}
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                required
                error={errors.customerName}
                helperText={errors.customerName}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t("PO_No")}
                value={poNumber}
                onChange={(e) => setPoNumber(e.target.value)}
                required
                error={errors.poNumber}
                helperText={errors.poNumber}
                fullWidth
              />
            </Grid>

            <Grid container spacing={4}></Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-select-small-label">
                {t("Select_Vendor")}
                </InputLabel>
                <Select
                  value={selectedVendor}
                  onChange={handleVendorChange}
                  required
                  fullWidth
                  // helperText={errors.selectedVendor}
                  error={errors.selectedVendor}
                >
                  {vendors.map((vendor) => (
                    <MenuItem value={vendor.id}>
                      {vendor.vendornameEng}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(errors.selectedVendor) && (
                  <FormHelperText>
                    <Typography variant="body2" color="error">
                      {errors.selectedVendor}
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-select-small-label">
                  {t("Select_Outlet")}
                </InputLabel>
                <Select
                  // value={selectedVendor}
                  onChange={handleOutletChange}
                  value={selectedOutlet}
                  required
                  fullWidth
                >
                  {outlets.map((outlet) => (
                    <MenuItem value={outlet.id}>{outlet.storename}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container>
              <TextField
                label={t("Search")}
                value={searchText}
                onChange={handleSearch}
                size="small"
                style={{ textAlign: t("langDir"),marginLeft:"30px", marginTop: "15px" }}
              />
            </Grid>
          </Grid>

          <DataGrid
            sx={{ marginTop: "15px" }}
            rows={filteredRows}
            columns={columns}
            onRowClick={handleRowClick}
            slots={{
              noRowsOverlay: () => (
                <CustomNoRowsOverlay message={t("No_Transactions")} />
              ),
            }}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
            }}
            loading={loadingTransactions}
            style={{ height: 630 }}
            componentsProps={{
              loadingOverlay: {
                style: { zIndex: 9999 },
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 6,
                },
              },
            }}
          />
        </Box>

        <Box
          sx={{ textAlign: "right", marginTop: "20px", marginBottom: "20px" }}
        >
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleClick}
          >
            {t("Save")}
          </button>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              navigate("/accounts/invoices");
            }}
          >
            {t("Cancel")}
          </button>
        </Box>
      </Box>
    </>
  );
}

export default CreateInvoice;
