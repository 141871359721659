import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { TextField, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Button from "react-bootstrap/Button";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { updateToken, baseInstance } from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Form, Input, Row, Col } from "antd";
import { ConfigProvider, theme, Popconfirm } from "antd";
import { saveAs } from "file-saver";
import { format } from 'date-fns';

function LoadingSpinner() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      style={{ height: "300px", backgroundColor: colors.default[100] }}
      className="container-logo-bounce"
    >
      <div className="logo-bounce"></div>
      <div className="shadow-bounce" />
    </div>
  );
}

const ReportModalSubway = ({
  isOpen,
  onClose,
  outletid,
  startdate,
  enddate,
  LoadPageContent,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const themecustom = useTheme();
  const colors = tokens(themecustom.palette.mode);
  const { logout } = useAuth();
  const [apidata, setApiData] = useState([]);
  const [apidataCategoryCount, setApiDataCategoryCount] = useState([]);
  const [onpressdisable, setonpressdisable] = useState(false);
  const [existingrecords, setExistingRecords] = useState(0);
  const [spinnerstatus, setspinnerstatus] = useState(true);
  const [form] = Form.useForm();

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };
  useEffect(() => {
    if (isOpen) {
      form.resetFields();
      setspinnerstatus(true);
      setonpressdisable(false);
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post("/api/integration/subwayreporting/showreport", {
          apiData: {
            outletid: outletid,
            // startdate: startdate.toISOString().slice(0, 10),
            startdate: format(startdate, 'yyyy-MM-dd', { timeZone: 'Asia/Riyadh' }),
            // enddate: enddate.toISOString().slice(0, 10),
            enddate: format(enddate, 'yyyy-MM-dd', { timeZone: 'Asia/Riyadh' }),

          },
        })
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            setApiData(data.categorylist);
            setApiDataCategoryCount(data.categorylistcount);
            setExistingRecords(data.existingrecords);
            const formValues = {
              vendorName: data.vendorName === null ? "" : data.vendorName,
              StoreNo: data.storeNum === null ? "" : data.storeNum,
              vendorId: data.vendorId === null ? "" : data.vendorId,
              vendorCountry:
                data.vendorCountry === null ? "" : data.vendorCountry,
              customerType: data.customerType === null ? "" : data.customerType,
              numberOfTrnsactions:
                data.numberOfTransactions === null
                  ? ""
                  : data.numberOfTransactions,
              day1sales:
                data.salesbyday.day1Sales === null
                  ? ""
                  : data.salesbyday.day1Sales,
              day2sales:
                data.salesbyday.day2Sales === null
                  ? ""
                  : data.salesbyday.day2Sales,
              day3sales:
                data.salesbyday.day3Sales === null
                  ? ""
                  : data.salesbyday.day3Sales,
              day4sales:
                data.salesbyday.day4Sales === null
                  ? ""
                  : data.salesbyday.day4Sales,
              day5sales:
                data.salesbyday.day5Sales === null
                  ? ""
                  : data.salesbyday.day5Sales,
              day6sales:
                data.salesbyday.day6Sales === null
                  ? ""
                  : data.salesbyday.day6Sales,
              day7sales:
                data.salesbyday.day7Sales === null
                  ? ""
                  : data.salesbyday.day7Sales,
              enddate: data.weekEndingDate === null ? "" : data.weekEndingDate,
              hoursopened: data.hoursOpened === null ? "" : data.hoursOpened,
              hoursworked: data.hoursWorked === null ? "" : data.hoursWorked,
              breakfastSales:
                data.breakfastSales === null ? "" : data.breakfastSales,
              schoolLunchSales:
                data.schoolLunchSales === null ? "" : data.schoolLunchSales,
              tcbySales: data.tcbySales === null ? "" : data.tcbySales,
              mmilSales: data.mmilSales === null ? "" : data.mmilSales,
              drinkCosts: data.drinkCosts === null ? "" : data.drinkCosts,
              couAmount: data.couAmount === null ? "" : data.couAmount,
              cgsAmount: data.cgsAmount === null ? "" : data.cgsAmount,
              numberOfGiantSubs:
                data.numberOfGiantSubs === null ? "" : data.numberOfGiantSubs,
              numberOfPlatters:
                data.numberOfPlatters === null ? "" : data.numberOfPlatters,
              numberOfPartyBreads:
                data.numberOfPartyBreads === null
                  ? ""
                  : data.numberOfPartyBreads,
              numberOfFreeUnits:
                data.numberOfFreeUnits === null ? "" : data.numberOfFreeUnits,
            };

            if (data.categorylist.length > 0) {
              const formFields = data.categorylist.map((item) => ({
                name: item.name,
                value: item.value === null ? "" : item.value,
              }));
              formFields.forEach((field) => {
                formValues[field.name] = field.value;
              });
            }
            if (data.categorylistcount.length > 0) {
              const formFields1 = data.categorylistcount.map((item) => ({
                name: item.name,
                value: item.value === null ? "" : item.value,
              }));
              formFields1.forEach((field) => {
                formValues[field.name] = field.value;
              });
            }
            form.setFieldsValue(formValues);
            setspinnerstatus(false);
          }
          // if (response.status === 400) {
          //   toast.error("Complete Api setup for the Outlet");
          // }
        })
        .catch((error) => {
          console.log(error);
          setonpressdisable(true);
        if (error.response.status === 403) {
            handleLogout();
            toast.error("Unauthorized access");
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.log(error);
          }
        });
    }
  }, [isOpen]);

  const getfile = (value) => {
    let url = "/api/reports/integration/subway";
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post(
        url,
        {
          apiData: {
            outletid: value.storeid,
            startdate: value.startdate.toISOString().slice(0, 10),
            enddate: value.enddate.toISOString().slice(0, 10),
            reportstatus: 0,
          },
        },
        {
          responseType: "blob", // Set the response type to 'blob'
        }
      )
      .then(function (response) {
        // console.log(response.data);
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, "Subway" + ".pdf");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    let url = "/api/reports/integration/subway/submitreport";
    setonpressdisable(true);
    toast.info("Report Submitting , Please dont close the browser");
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post(url, {
        apiData: {
          outletid: outletid,
          startdate: format(startdate, 'yyyy-MM-dd', { timeZone: 'Asia/Riyadh' }),
          enddate: format(enddate, 'yyyy-MM-dd', { timeZone: 'Asia/Riyadh' }),
          reportstatus: 0,
        },
      })
      .then(function (response) {
        toast.success("Report Submitted Successfully");
        onClose();
        LoadPageContent();
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(t('Record_Failed'));

        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          toast.error(t('Record_Failed'));
          console.log(error);
        } 
        console.log(error);
        setonpressdisable(true);
        toast.error("Error while submitting");
      });
  };

  return (
    <Modal dialogClassName="modal-70w" show={isOpen} onHide={onClose}>
      <Modal.Header style={{ backgroundColor: colors.default[100] }}>
        <Modal.Title>API Subway Report</Modal.Title>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() =>
            getfile({
              storeid: outletid,
              startdate: startdate,
              enddate: enddate,
            })
          }
        >
          <i className="fa fa-download"></i> {t("Download")}
        </button>
      </Modal.Header>
      {spinnerstatus ? (
        <>
          <Modal.Body style={{ backgroundColor: colors.default[100] }}>
            {" "}
            <LoadingSpinner />
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: colors.default[100] }}>
            <Button variant="secondary" onClick={onClose}>
            {t("Cancel")}
                
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 17 }}
          wrapperCol={{ span: 17 }}
          // onFinish={onFinish}
          layout="vertical" // Set the form layout to vertical
        >
          <Modal.Body style={{ backgroundColor: colors.default[100] }}>
            {/* <span style={{ color: "red" }}> - Not Submitted</span> */}
            {existingrecords === 0 ? (
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "10px",
                  color: "red",
                  textAlign: t("langDir"),
                }}
                fontWeight="bold"
              >
                {" "}
                {t("Not Submitted")}{" "}
              </Typography>
            ) : (
              <Typography
                variant="h5"
                sx={{
                  marginBottom: "10px",
                  color: "green",
                  textAlign: t("langDir"),
                }}
                fontWeight="bold"
              >
                {" "}
                {t("Submitted ")} {existingrecords} {" time earlier"}
              </Typography>
            )}
            <Typography
              variant="h5"
              sx={{ marginBottom: "10px", textAlign: t("langDir") }}
              fontWeight="bold"
            >
              {" "}
              {t("Vendor Details")}{" "}
            </Typography>

            <Row gutter={1}>
              <Col span={6}>
                <Form.Item label="Vendor Name" name="vendorName">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Store No" name="StoreNo">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Vendor Id" name="vendorId">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Vendor Country" name="vendorCountry">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={1}>
              <Col span={6}>
                <Form.Item label="Customer Type" name="customerType">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Week Ending Date" name="enddate">
                  <Input disabled />
                </Form.Item>
              </Col>
              {/* <Col span={6}>
              <Form.Item label="Hours Opened" name="hoursopened">
                <Input disabled />
              </Form.Item>
            </Col>
          <Col span={6}>
              <Form.Item label="Hours Worked" name="hoursworked">
                <Input disabled />
              </Form.Item>
            </Col> */}
            </Row>
            <Typography
              variant="h5"
              sx={{ marginBottom: "10px", textAlign: t("langDir") }}
              fontWeight="bold"
            >
              {t("Sales Details")}{" "}
            </Typography>
            <Row gutter={1}>
              <Col span={6}>
                <Form.Item label="Day 1 Sale" name="day1sales">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Day 2 sale" name="day2sales">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Day 3 Sale" name="day3sales">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Day 4 Sale" name="day4sales">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Day 5 Sale" name="day5sales">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Day 6 sale" name="day6sales">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Day 7 Sale" name="day7sales">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={1}>
              <Col span={6}>
                <Form.Item label="No. Transactions" name="numberOfTrnsactions">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Hours Worked" name="hoursworked">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Hours Opened" name="hoursopened">
                  <Input disabled />
                </Form.Item>
              </Col>
              {/* <Col span={6}>
              <Form.Item label="Day 4 Sale" name="day4sales">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Day 5 Sale" name="day5sales">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Day 6 sale" name="day6sales">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Day 7 Sale" name="day7sales">
                <Input disabled />
              </Form.Item>
            </Col> */}
            </Row>
            <Typography
              variant="h5"
              sx={{ marginBottom: "10px", textAlign: t("langDir") }}
              fontWeight="bold"
            >
              {t("Other Details")}{" "}
            </Typography>
            <Row gutter={1}>
              {apidata
                ? apidata.map((item) => (
                    <Col span={6}>
                      <Form.Item label={item.name} name={item.name}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  ))
                : null}
            </Row>
            <Row gutter={1}>
              {apidataCategoryCount
                ? apidataCategoryCount.map((item) => (
                    <Col span={6}>
                      <Form.Item label={item.name} name={item.name}>
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  ))
                : null}
            </Row>

            <Typography
              variant="h5"
              sx={{ marginBottom: "10px", textAlign: t("langDir") }}
              fontWeight="bold"
            >
              {" "}
              {t("Optional Details")}{" "}
            </Typography>

            <Row gutter={1}>
              <Col span={6}>
                <Form.Item label="breakfast Sales" name="breakfastSales">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="schoolLunch Sales" name="schoolLunchSales">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="tcby Sales" name="tcbySales">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="mmil Sales" name="mmilSales">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={1}>
              <Col span={6}>
                <Form.Item label="drink Costs" name="drinkCosts">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="cou Amount" name="couAmount">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="cgs Amount" name="cgsAmount">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="numberOf GiantSubs" name="numberOfGiantSubs">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={1}>
              <Col span={6}>
                <Form.Item label="numberOf Platters" name="numberOfPlatters">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="numberOfParty Breads"
                  name="numberOfPartyBreads"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="numberOf FreeUnits" name="numberOfFreeUnits">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Modal.Body>

          <Modal.Footer style={{ backgroundColor: colors.default[100] }}>
            {/* <Popconfirm
                    placement="leftTop"
                    title="Are you sure to delete?"
                    description="Delete API Data" 
                    okText="Yes"
                    cancelText="No"
                    onConfirm={handleSubmit}
                  > */}

            <Button
              variant="primary"
              disabled={spinnerstatus || onpressdisable}
              onClick={handleSubmit}
            >
              Submit
            </Button>
            {/* </Popconfirm> */}

            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};

export default ReportModalSubway;
