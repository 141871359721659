
import React from 'react'
import { useTranslation } from "react-i18next";

export default function Inventorybyusage(props) {
    const { t } = useTranslation();
    return (
        <div>
            <div>
            <h6 style={{ textAlign: t("langDir") }}>
          {t("Outlet")} : {props.outletLabel}
        </h6>
                <h6 ></h6>
            </div>

            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                    <td>{t('Inventory')} #</td>
            <td>{t('Desc')}</td>
            <td>{t("Units")}</td>
            <td>{t("Packs")}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.inventorydata.map((item, index) => (
                            <tr key={index}>
                                 {
                                  item.units==="" ? <td className='font-weight-bold'>{item.inventory}</td>  :  <td>{item.inventory}</td>
                                 } 
                                 {
                                  item.packs==="" ? <td className='font-weight-bold'>{item.description}</td>  :   <td>{item.description}</td>
                                 } 
                                <td>{item.units}</td>
                                <td>{item.packs}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}