import React from 'react'
import { useTranslation } from 'react-i18next';

export default function SalesByStationDetail(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div class="modal-body">
                <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                    <h6 id="fromtodatesalebytype"></h6>
                </div>

                <table id="salesbytypeviewtable" style={{ width: "100%" }}>
                    <thead>
                    <tr className="headingtop">
                        <td>{t('Summary_Group')}</td>
                        <td>{t('Report_Category')}</td>
                        <td>{t('Station_No')}</td>
                        <td>{t('Trans')}</td>
                        <td>{t('Value')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.data.salesdata.map((item,index) => (
                            <tr key={index}>
                                <td>{item.summarygroup}</td>
                                <td>{item.reportcat}</td>
                                <td>{item.description}</td>
                                <td>{item.qty}</td>
                                <td>{item.amount}</td>
                            </tr>
                        ))
                    }
                    </tbody>

                </table>
            </div>
        </div>
    )
}