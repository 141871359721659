import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";

const OutletDropdown = (props) => {
  const [outlets, setOutlets] = useState([]);
  const { t } = useTranslation();
  const [selectedOutlet, setSelectedOutlet] = useState(
    props.defaultValue || []
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    // let decoded = jwt.decode(token);
    try {
      const fetchOutletsData = jwtDecode(token);
      setOutlets(fetchOutletsData.outlets);
    } catch {
      console.log("error in token validation");
    }
  }, []);


  const handleOutletChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOutlet(typeof value === 'string' ? selectedValue.split(',') : selectedValue);
    props.onChange(selectedValue);
  };

  const renderSelectedStoreNames = (selected) => {
    const selectedStoreNames = outlets
      .filter((outlet) => selected.includes(outlet.id))
      .map((outlet) => outlet.storename);

    return selectedStoreNames.join(", ");
  };

  return (
    <FormControl sx={{ minWidth: 250,maxWidth:250 }} size="small">
      <InputLabel id="demo-select-small-label">{t("Select_Outlet")}</InputLabel>
      <Select
        labelId="demo-select-checkbox-label"
        id="demo-select-checkbox"
        multiple
        value={selectedOutlet}
        onChange={handleOutletChange}
        label={t("Select Outlet")}
        style={props.sx}
        renderValue={renderSelectedStoreNames}
      >
        {outlets.map((outlet) => (
          <MenuItem key={outlet.id} value={outlet.id}>
            <Checkbox checked={selectedOutlet.includes(outlet.id)}  />
            <ListItemText primary={outlet.storename} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OutletDropdown;
