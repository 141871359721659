import { Box,  TextField } from "@mui/material";
import PageHeader from "../../../components/PageHeader";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { baseInstance } from "../../../api/axios";
import { updateToken } from "../../../api/axios";
import { tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";
import CustomLoadingOverlay from "../../../components/supportingComponents/CustomLoadingOverLay";
import { CustomNoRowsOverlay } from "../../../components/supportingComponents/CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ViewUpdateRole from "./CustomModal/ViewUpdateRole";
import useAuth from "../../../hooks/useAuth";
import CreateUserRole from "./CustomModal/CreateUserRole";


function UserRole() {

  const navigate = useNavigate();
  const { logout } = useAuth();
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [selectedRowData, setSelectedRowData] = useState();
  const [searchText, setSearchText] = useState("");
  const { t } = useTranslation();
  const [modalopen, setModelStatus] = useState(false);
  const [addPackageModalOpen, setaddPackageModalOpen] = useState(false);
  const [removePackageModalOpen, setremovePackageModalOpen] = useState(false);

  const [loadingEntityGroup, setLoadingEntityGroup] = useState(false);

  const handleAdd = (params) => {
    setaddPackageModalOpen(true);
  };

  const handleRemove = (params) => {
    setremovePackageModalOpen(true);
  };

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      flex: 0.1,
      headerClassName: "th-datagrid-theme",
    },
    // {
    //   field: "actions",
    //   headerName: "",
    //   headerClassName: "th-datagrid-theme",
    //   width: 0.1,
    //   renderCell: (params) => (
    //     <IconButton >
    //       <EditIcon />
    //     </IconButton>
    //   ),
    // },

    {
      field: "role_name",
      headerName: "Role Name",
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
    },
    
    {
      field: "actions",
      headerName: "",
      sortable: false,
      filterable: false,
      flex: 0.1,
      headerClassName: "th-datagrid-theme",
      renderCell: (params) => (
        <div>
          <IconButton>
            <VisibilityOutlinedIcon onClick={() => handleAdd(params)} />
          </IconButton>

  

          {/* <Button variant="contained" color="primary" >
              Add
            </Button>
            <Button variant="contained" color="secondary" >
              Remove
            </Button>
            <Button variant="contained" >
              View
            </Button> */}
        </div>
      ),
    },
  ];


  const handleRowClick = (params) => {
    const { id } = params.row;
    setSelectedRowData(id);
  };

  useEffect(() => {
    fetchRows();
  }, []);

  const handleNewUserRole = () => {
    setModelStatus(true);
  };

  const fetchRows = () => {
    setLoadingEntityGroup(true);
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/administration/userrole", {})
      .then((response) => {
        const { data, totalRows } = response.data;
        setRows(data);
        setTotalRows(data.totalRows);
        setLoadingEntityGroup(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
          navigate(`/auth/login`);
        } else {
          console.error(error);
        }
        setLoadingEntityGroup(false);
      });
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    String(row.group_name).includes(searchText)
  );

  return (
    <>
      <CreateUserRole
        isOpen={modalopen !== false}
        onClose={() => {
          // setSelectedRowData(defaultValueSalesInvoice);
          setModelStatus(false);
        }} // Close the modal by setting selectedRowData to null
        //   data={selectedRowData}
      />
      <ViewUpdateRole
        isOpen={addPackageModalOpen !== false}
        onClose={() => {
          // setSelectedRowData(defaultValueSalesInvoice);
          setaddPackageModalOpen(false);
        }} // Close the modal by setting selectedRowData to null
        roleId={selectedRowData}
      />
{/* 
      <RemovePackageDetail
        isOpen={removePackageModalOpen !== false}
        onClose={() => {
          // setSelectedRowData(defaultValueSalesInvoice);
          setremovePackageModalOpen(false);
        }} // Close the modal by setting selectedRowData to null
        data={selectedRowData}
      /> */}
      <Box className=" h-75 mx-auto w-90 text-align ">
        <PageHeader title="User Role" />
        <Box className=" mx-auto text-align " sx={{ width: "100%" }}>
          <Box sx={{ textAlign: "right", marginBottom: "10px" }}>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              style={{ marginLeft: "10px" }}
              onClick={handleNewUserRole}
            >
              Create User Role
            </button>
          </Box>
          <Box mb="10px" sx={{ textAlign: "right" }}>
            <TextField
              label="Search"
              value={searchText}
              onChange={handleSearch}
              size="small"
              style={{ marginLeft: "10px" }}
            />
          </Box>

          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}

            slots={{
              noRowsOverlay: () => (
                <CustomNoRowsOverlay message="No User Role!" />
              ),
            }}

            components={{
              LoadingOverlay: CustomLoadingOverlay,
            }}

            loading={loadingEntityGroup}
            style={{ height: 630 }}
            componentsProps={{
              loadingOverlay: {
                style: { zIndex: 9999 },
              },
            }}
            

            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            // paginationMode="server"
            onRowClick={handleRowClick}
            rowCount={totalRows}
          />
        </Box>
      </Box>
    </>
  );
}

export default UserRole;
