import React from 'react'
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { useTranslation } from 'react-i18next';



function PieRechartComponents(props){

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

    const pieData = props.piedata

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
                    <label>{`${payload[0].name} : ${payload[0].value}%`}</label>
                </div>
            );
        }
        return null;
    };

        return (
            <PieChart width={730} height={300}>
                <Pie data={pieData} color="#000000" dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={90} fill="#8884d8" >
                    {
                        pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                    }
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend />
            </PieChart>
        )
    
}


export default function SalesPayment(props) {
    const {t}=useTranslation();

   

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>

            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Desc')}</td>
                        <td>{t('Final_Total')}</td>
                        <td>{t('Change_Tip')}</td>
                        {/* <td>{t('Net_Total')}</td>
                        <td>{t('Total_Tax')}</td> */}

                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.querysalesbypayment1.map((item, index) => (
                            <tr key={index}>
                                <td>{item.descript}</td>
                                <td>{item.total}</td>
                                <td>{item.change}</td>
                                {/* <td>{item.nettotal}</td>
                                <td>{item.tax} {item.tax2==0 ? <span> </span> : <span>( Tax1 - {item.tax1} Tax2 - {item.tax2} )</span>}
                                    </td> */}
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>
            <PieRechartComponents piedata={props.data.piechart}/>
        </div>
    )
}