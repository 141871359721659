import React from 'react'
import { useTranslation } from 'react-i18next';

export default function SalesProduct(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div >
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 id="fromtodateproduct"></h6>
            </div>

            <table id="producteviewtable" style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Product_No')}</td>
                        <td>{t('Name')}</td>
                        <td>{t('Trans')}</td>
                        <td>{t('Trans%')}</td>
                        <td>{t('Value')}</td>
                        <td>{t('Value%')}</td>

                    </tr>

                </thead>

                <tbody>
                    {
                        props.data.querysalesbyproductquantity1.map((item, index) => (
                            <tr key={index}>
                                <td>{item.prodnum}</td>
                                <td>{item.descript}</td>
                                <td>{item.qty}</td>
                                <td>{item.qty_percent}</td>
                                <td>{item.value}</td>
                                <td>{item.value_percent}</td>

                            </tr>

                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}