import React from 'react'
import { useTranslation } from 'react-i18next';

export default function DaySummary(props) {
    const {t}=useTranslation();
    return (
        <div>
            <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>
                <h6 ></h6>
            </div>

            <table id="daysummaryviewtable" style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Date')}</td>
                        <td>{t('Number_Trans')}</td>
                        <td>{t('Net_Sales')}</td>
                        <td>{t('Taxes')}</td>
                        <td>{t('Final_Sales')}</td>
                        <td> {t('Discount_Amount')}</td>
                        <td> {t('Void_Qty')}</td>
                        <td> {t('Void_Amount')}</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.data.tabledata.map((item, index) => (
                            <tr key={index}>
                                <td>{item.opendate}</td>
                                <td>{item.NumTrans}</td>
                                <td>{item.SalesNet}</td>
                                <td>{item.Tax1}</td>
                                <td>{item.SalesGross}</td>
                                <td>{item.CouponValue}</td>
                                <td>{item.NumVoid}</td>
                                <td>{item.VoidValue}</td>

                            </tr>
                        ))
                    }
                </tbody>
            </table>
           
        </div>
    )
}


