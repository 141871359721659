import axios from "axios";

// const baseURL = "http://127.0.0.1:5000";
const baseURL = "https://codeplusapi.azurewebsites.net";


// export default axios.create({
//   baseURL: "http://localhost:5000",
// });

const baseInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const updateToken = (token) => {
  baseInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
// Create a separate instance for APIs without headers
const noHeadersInstance = axios.create({
  baseURL: baseURL,
});

export { baseInstance, noHeadersInstance,updateToken };
