import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { baseInstance, updateToken } from "../../api/axios";
import PageHeader from "../../components/PageHeader";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";

import { useNavigate } from "react-router-dom";
import { CustomNoRowsOverlay } from "../../components/supportingComponents/CustomNoRowsOverlay";


export default function StoreStatus() {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const {t}=useTranslation();
  const { logout } = useAuth();
  const navigate=useNavigate();
  const [loadingStores, setLoadingStores] = useState(true);


  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };
  

  const columns = [
    { field: "id", headerName: "ID", headerClassName: "th-datagrid-theme" },
    {
      field: "outletname",
      headerName: t("Outlet"),
      flex: 0.5,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "location",
      headerName: t("Location"),
      flex: 0.5,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "statuss",
      headerName: t('Last_Replication_Date_Time'),
      flex: 0.5,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  useEffect(() => {
    fetchRows();
  }, []);

  const fetchRows = () => {
    // Perform API call to fetch data

    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/store/storestatus", {
        params: {
          page: currentPage,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        const { data, totalRows } = response.data;
        setRows(data);
        setTotalRows(totalRows);
        setLoadingStores(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
        setLoadingStores(false);
      });
  };

  const handlePageChange = (params) => {
    setCurrentPage(params.page);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
    setCurrentPage(1);
  };
  return (
    <>
    
    <Box 
      className=" h-75 mx-auto w-90 text-align "
      sx={{ height: 200, width: "100%" }}
    >
      <PageHeader title={t("Store_Status")} />
      <DataGrid
        rows={rows}
        slots={{
          noRowsOverlay: () => <CustomNoRowsOverlay message="No Stores" />,
        }}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        loading={loadingStores}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        rowCount={totalRows}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </Box>
    </>
  );
}
