import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { Box, Card, useTheme } from "@mui/material";
import { ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme";

const PieChart = (props) => {
  const [data, setData] = useState(props.dataResponse);
  const { t } = useTranslation();
  const theme = useTheme();
  const colorstheme = tokens(theme.palette.mode);

  useEffect(() => {
    setData(props.dataResponse);
  }, [props.dataResponse]);

  const data1 = [
    ["Category", "Value"],
    ["Category 1", 20],
    ["Category 2", 15],
    ["Category 3", 30],
    ["Category 4", 10],
    ["Category 5", 25],
  ];
  const options = {
    is3D: true,
    chartArea: {
      left: 0, 
      top: 20,
      right: 0,
      bottom: 30,
    },
    backgroundColor: colorstheme.default[100],
    legend: {
      position: "bottom", 
      alignment: "center", 
    },
    titleTextStyle: {
      fontSize: 16,
      bold: true,
      textAlign: "center",
      color: colorstheme.default[200],
    },
    colors: ["#106e8a", "#6da4d0", "#9fbddc", "#e36139", "#ed6943"],
    pieSliceTextStyle: {
      color: "#ffffff", 
    },
  };

  const options1 = {
    ...options,
    title: t("Top_5_Product"),
  };
  const options2 = {
    ...options,
    title: t("Top_5_Payment"),
  };
  const options3 = {
    ...options,
    title: t("Top_5_SalesType"),
  };

  return (
    <Box
      className="row"
      style={{ paddingRight: "5px", paddingLeft: "5px", marginTop: "20px" }}
    >
      <Box className="col-md-4">
        <Card sx={{boxShadow: "0 1px 2.94px 0.06px #ffffff"}}>
          {/* <Box className="card-header">
            <Typography variant="h5">Monthly Branch Sales</Typography>
          </Box> */}
          <Box className="card-body">
            <ResponsiveContainer width="98%" height={250}>
              <Chart
                width={"100%"}
                height={"250px"}
                chartType="PieChart"
                data={data.productpiedata}
                options={options1}
            
              />
            </ResponsiveContainer>
          </Box>
        </Card>
      </Box>
      <Box className="col-md-4">
        <Card sx={{boxShadow: "0 1px 2.94px 0.06px #ffffff"}}>
          {/* <Box className="card-header">
            <Typography variant="h5">Monthly Branch Sales</Typography>
          </Box> */}
          <Box className="card-body">
            <ResponsiveContainer width="98%" height={250}>
              <Chart
                width={"100%"}
                height={"250px"}
                chartType="PieChart"
                data={data.paymentpiedata}
                options={options2}
              />
            </ResponsiveContainer>
          </Box>
        </Card>
      </Box>
      <Box className="col-md-4">
        <Card sx={{boxShadow: "0 1px 2.94px 0.06px #ffffff"}}>
          {/* <Box className="card-header">
            <Typography variant="h5">Monthly Branch Sales</Typography>
          </Box> */}
          <Box className="card-body">
            <ResponsiveContainer width="98%" height={250}>
              <Chart
                width={"100%"}
                height={"250px"}
                chartType="PieChart"
                data={data.salestypepiedata}
                options={options3}
              />
            </ResponsiveContainer>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default PieChart;
