import { React, useEffect, useState } from "react";
import { ColorModeContext, useMode } from "../../../theme";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import TopBar from "../../global/topbar";
import SideBar from "../../global/sidebar";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { Outlet } from "react-router-dom";
import i18next from "i18next";
// import Chatbot from "../../../components/supportingComponents/ChatBot";

export const languages = [
  {
    code: "en",
    name: "English",
    country_code: "ind",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];

const MainLayout = () => {
  const Lang = localStorage.getItem("Lang");
  const Theme = localStorage.getItem("Theme");
  const Themeinitial = Theme === "null" ? "light" : Theme.trim("");
  const [theme, colorMode] = useMode(Themeinitial);
  const currentLanguageCode = Lang === "null" ? "en" : Lang;
  const currentLanguage = languages.find(
    (l) => l.code === currentLanguageCode.trim("")
  );

  const [padding, setPadding] = useState("0");

  const { t } = useTranslation();

  useEffect(() => {
    // i18next.changeLanguage(currentLanguageCode.trim(''));
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("Codeplus Hospitality App");
    const paddingValue =
      document.body.dir === "ltr" ? "0 0 0 200px" : "0 200px 0 0";
    setPadding(paddingValue);
  }, [currentLanguage, t]);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const handleContentClick = () => {
    // Hide the sidebar when clicking on the main content area
    if (isSidebarVisible) {
      setIsSidebarVisible(false);
    }
  };
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app" >
          <SideBar isSidebarVisible={isSidebarVisible}/>
          <Box
            className="content"
            sx={{
              "@media (min-width: 991px)": {
                padding, // Use the calculated padding value
                transition: "padding 0.2s",
              },
            }}
            onclick={handleContentClick}
          >
            <TopBar  onMenuClick={() => setIsSidebarVisible(!isSidebarVisible)} isSidebarVisible={isSidebarVisible}/>
            <Outlet />
          </Box>
        </div>
        {/* <div
          style={{
            position: "fixed",
            bottom: 0,
            left: "75%",
            width: "100%",
            zIndex: 1000, // Adjust this value as needed
            padding: "10px", // Add padding to make it more visible
            background: "#fff", // Add a background color
            marginBottom:"20px"
          }}
        >

          <Chatbot />
        </div> */}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default MainLayout;
