import React, { useState, useRef } from "react";
import PageHeader from "../../../components/PageHeader";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { tokens } from "../../../theme";
import OutletDropdown from "../../../components/supportingComponents/outletdropdown";
import CustomDateRange from "../../../components/CustomDateRange";
import { useContext } from "react";
import { useEffect } from "react";
import OutletContext from "../../../context/OutletContext";
import useAuth from "../../../hooks/useAuth";
import { baseInstance, updateToken } from "../../../api/axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DateContext from "../../../context/DateContext";
import PieDonut from "./PieDonut";
import PieChart from "./PieChart";
import BarChartData from "./barchart";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Select } from "antd";

const { Option } = Select;

const SubwaySeriesReport = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const boxRef = useRef(null);
  const boxRef2 = useRef(null);
  const colors = tokens(theme.palette.mode);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateContext);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(1);

  const [reportData, setReportData] = useState({
    seriessub: {
      table: [],
      totaldaysalespercet: [],
      totalseriessales: 0,
      sixinch12inch: [],
      combononcombo: [],
    },
    seriessubmelts: {
      table: [],
      totaldaysalespercet: [],
      totalseriessales: 0,
      sixinch12inch: [],
      combononcombo: [],
    },
    wraps: {
      table: [],
      totaldaysalespercet: [],
      totalseriessales: 0,
      sixinch12inch: [],
      combononcombo: [],
    },
    salads: {
      table: [],
      totaldaysalespercet: [],
      totalseriessales: 0,
      sixinch12inch: [],
      combononcombo: [],
    },
  });
  const [reportBarData, setReportBarData] = useState({
    sixinchlist: [],
    twelveinchlist: [],
  });
  const Lang = localStorage.getItem("Lang");

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };
  const handleOutletChange = (selectedValue) => {
    setOutletId(selectedValue);
  };

  const handleCategoryChange = (selectedValue) => {
    setSelectedCategory(selectedValue);
    getGetBarCharts(selectedValue);
  };

  const optionsField = [
    { label: "Series", value: 1 },
    { label: "Submelts", value: 2 },
  ];
  useEffect(() => {
    if (startDate) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post("/api/integration/seriesreport", {
          storeid:
            OutletId === ""
              ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
              : OutletId,
          startdate: startDate,
          enddate: endDate,
        })
        .then((response) => {
          if (response.status === 200) {
            setReportData(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            handleLogout();
            toast.error("Unauthorized access");
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.log(error);
          }
        });
    }
  }, [OutletId, startDate, endDate]);

  useEffect(() => {
    getGetBarCharts(selectedCategory);
  }, [OutletId, startDate, endDate]);

  const getGetBarCharts = (category) => {
    if (startDate) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post("/api/integration/seriesreportbarreport", {
          storeid:
            OutletId === ""
              ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
              : OutletId,
          startdate: startDate,
          enddate: endDate,
          selectedCategory: category,
        })
        .then((response) => {
          if (response.status === 200) {
            // setReportData(response.data);
            setReportBarData(response.data);
            // console.log("tets");
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            handleLogout();
            toast.error("Unauthorized access");
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.log(error);
          }
          // setCardLoading(false);
        });
    }
  };

  // const getfile = () => {
  //   let url = "/api/integration/subwayreportseries/downloadpdf";
  //   updateToken(localStorage.getItem("token"));
  //   baseInstance
  //     .post(
  //       url,
  //       {
  //         startdate: "test",
  //       },
  //       {
  //         responseType: "blob", // Set the response type to 'blob'
  //       }
  //     )
  //     .then(function (response) {
  //       console.log(response.data);
  //       const blob = new Blob([response.data], { type: "application/pdf" });
  //       saveAs(blob, "test.pdf");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handleDownloadPDF = () => {
    const boxElement = boxRef.current;
    const options = {
      scale: 2, // Increase the scale for higher clarity
    };
    const image = new Image();
    image.src = "../../assets/subwaybanner.jpg";
    html2canvas(boxElement, options).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        unit: "px",
        format: "a4",
        orientation: "landscape",
        putOnlyUsedFonts: true,
        compress: true,
        lineHeight: 1.2,
      });
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      pdf.addImage(image, "PNG", 0, 0, 70, height); // Adjust position and size as needed
      pdf.setTextColor(1, 133, 57); // RGB color for green

      pdf.setFont("helvetica", "bold");
      pdf.addImage(imgData, "PNG", 100, 30, width - 140, height - 40);
      const formattedStartDate = startDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      const formattedEndDate = endDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

      pdf.text(" " + formattedStartDate + " - " + formattedEndDate, 80, 25);
      pdf.setTextColor(0, 0, 0); // RGB color for green
      pdf.setFontSize(8);
      pdf.text("Powered By kaemsolutions.com", width - 110, height - 6);
      pdf.save("SubwayCustomReport.pdf");
    });
  };

  const handleDownloadPDFBarchart = () => {
    const boxElement = boxRef2.current;
    const options = {
      scale: 2, // Increase the scale for higher clarity
    };
    const image = new Image();
    image.src = "../../assets/subwaybanner.jpg";
    html2canvas(boxElement, options).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        unit: "px",
        format: "a4",
        orientation: "landscape",
        putOnlyUsedFonts: true,
        compress: true,
        lineHeight: 1.2,
      });
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      pdf.addImage(image, "PNG", 0, 0, 70, height); // Adjust position and size as needed
      pdf.setTextColor(1, 133, 57); // RGB color for green

      pdf.setFont("helvetica", "bold");
      pdf.addImage(imgData, "PNG", 100, 30, width - 135, height - 40);
      const formattedStartDate = startDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      const formattedEndDate = endDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

      pdf.text("" + formattedStartDate + " - " + formattedEndDate, 80, 25);
      pdf.setTextColor(0, 0, 0); // RGB color for green
      pdf.setFontSize(8);
      pdf.text("Powered By kaemsolutions.com", width - 110, height - 6);
      pdf.save("Report6vsFL.pdf");
    });
  };

  return (
    <Box className="h-75 mx-auto w-90 text-align">
      <PageHeader centerText={t("Subway Custom Report")} />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CustomDateRange sx={{ paddingRight: "20px" }} />
        <OutletDropdown defaultValue={OutletId} onChange={handleOutletChange} />
      </Box>
      <Box
        sx={{
          marginBottom: "15px",
          textAlign: t("langDir"),
          // border: 0.5,
          // borderColor: "#000",
          padding: 3,
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)", // Add a box shadow
          borderRadius: 3,
          marginBottom: 2,
        }}
      >
        <button
          type="button"
          className="btn btn-sm btn-primary"
          style={{ marginLeft: "10px", textAlign: "right", minWidth: "10px" }}
          onClick={handleDownloadPDF}
        >
          <span>
            <FileDownloadOutlinedIcon />
          </span>
          PDF
        </button>
        <Box ref={boxRef}>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{ fontWeight: "bold", borderBottom: "1px solid #000" }}
                variant="body1"
                align="left"
              >
                Series
              </Typography>
              <TableContainer
                //   component={Paper}
                sx={{
                  maxHeight: 185,
                  height: 185,
                  overflow: "auto",
                  marginBottom: 2,
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0 1px 2.94px 0.06px #fff"
                      : null,

                  // backgroundColor: colors.default[100],
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow key={100}>
                      <TableCell
                        style={{ color: "black", padding: "7px" }}
                      ></TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Value
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Value%
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Qty%
                      </TableCell>
                    </TableRow>
                    {reportData["seriessub"]["table"].map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ padding: "6px" }}>
                          {item["descript"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["sum"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["sumperct"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["qty"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["qtyperc"]}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key={100}>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        {reportData["seriessub"]["totalseriessales"]}
                      </TableCell>
                      <TableCell
                        style={{ color: "black", padding: "7px",fontWeight: "bold" }}
                      > {reportData["seriessub"]["totalseriessalesperc"]}</TableCell>
                      <TableCell
                        style={{ color: "black", padding: "7px",fontWeight: "bold" }}
                      >{reportData["seriessub"]["totalseriesqty"]}</TableCell>
                      <TableCell
                        style={{ color: "black", padding: "7px",fontWeight: "bold" }}
                      > {reportData["seriessub"]["totalseriesqtyperc"]}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="right"
              >
                % of total units
              </Typography>
              <PieDonut data={reportData["seriessub"]["totaldaysalespercet"]} />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="center"
              >
                {"           FL' vs 6'"}
              </Typography>
              <PieChart
                data={reportData["seriessub"]["sixinch12inch"]}
                labels={["FL", "6"]}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="right"
              >
                Non Combo vs Combo
              </Typography>

              <PieChart
                data={reportData["seriessub"]["combononcombo"]}
                labels={["Non Combo", "Combo"]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{ fontWeight: "bold", borderBottom: "1px solid #000" }}
                variant="body1"
                align="left"
              >
                Submelts
              </Typography>
              <TableContainer
                //   component={Paper}
                sx={{
                  maxHeight: 185,
                  height: 185,
                  overflow: "auto",
                  marginBottom: 2,
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0 1px 2.94px 0.06px #fff"
                      : null,

                  // backgroundColor: colors.default[100],
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow key={100}>
                      <TableCell
                        style={{ color: "black", padding: "7px" }}
                      ></TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Value
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Value%
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Qty%
                      </TableCell>
                    </TableRow>
                    {reportData["seriessubmelts"]["table"].map(
                      (item, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ padding: "6px" }}>
                            {item["descript"]}
                          </TableCell>
                          <TableCell style={{ padding: "6px" }}>
                            {item["sum"]}
                          </TableCell>
                          <TableCell style={{ padding: "6px" }}>
                            {item["sumperct"]}
                          </TableCell>
                          <TableCell style={{ padding: "6px" }}>
                            {item["qty"]}
                          </TableCell>
                          <TableCell style={{ padding: "6px" }}>
                            {item["qtyperc"]}
                          </TableCell>
                        </TableRow>
                      )
                    )}

                    <TableRow key={100}>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        {reportData["seriessubmelts"]["totalseriessales"]}
                      </TableCell>
                      <TableCell
                        style={{ color: "black", padding: "7px" ,fontWeight: "bold"}}
                      >{reportData["seriessubmelts"]["totalseriessalesperc"]}</TableCell>
                      <TableCell
                        style={{ color: "black", padding: "7px" ,fontWeight: "bold"}}
                      >{reportData["seriessubmelts"]["totalseriesqty"]}</TableCell>
                      <TableCell
                        style={{ color: "black", padding: "7px",fontWeight: "bold" }}
                      >{reportData["seriessubmelts"]["totalseriesqtyperc"]}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="right"
              >
                % of total units
              </Typography>
              <PieDonut
                data={reportData["seriessubmelts"]["totaldaysalespercet"]}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="center"
              >
                {"           FL' vs 6'"}
              </Typography>
              <PieChart
                data={reportData["seriessubmelts"]["sixinch12inch"]}
                labels={["FL", "6"]}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="right"
              >
                Non Combo vs Combo
              </Typography>

              <PieChart
                data={reportData["seriessubmelts"]["combononcombo"]}
                labels={["Non Combo", "Combo"]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} marginTop={1}>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{ fontWeight: "bold", borderBottom: "1px solid #000" }}
                variant="body1"
                align="left"
              >
                Wraps
              </Typography>
              <TableContainer
                //   component={Paper}
                sx={{
                  maxHeight: 215,
                  height: 215,
                  overflow: "auto",
                  marginBottom: 2,
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0 1px 2.94px 0.06px #fff"
                      : null,

                  // backgroundColor: colors.default[100],
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow key={100}>
                      <TableCell
                        style={{ color: "black", padding: "6px" }}
                      ></TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "6px",
                        }}
                      >
                        Value
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "6px",
                        }}
                      >
                        Value%
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "6px",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "6px",
                        }}
                      >
                        Qty%
                      </TableCell>
                    </TableRow>
                    {reportData["wraps"]["table"].map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ padding: "6px" }}>
                          {item["descript"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["sum"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["sumperct"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["qty"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["qtyperc"]}
                        </TableCell>
                      </TableRow>
                    ))}

                    <TableRow key={100}>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "6px",
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "6px",
                        }}
                      >
                        {reportData["wraps"]["totalseriessales"]}
                      </TableCell>
                      <TableCell
                        style={{ color: "black", padding: "6px",fontWeight: "bold" }}
                      > {reportData["wraps"]["totalseriessalesperc"]}</TableCell>
                      <TableCell
                        style={{ color: "black", padding: "6px",fontWeight: "bold" }}
                      >{reportData["wraps"]["totalseriesqty"]}</TableCell>
                      <TableCell
                        style={{ color: "black", padding: "6px",fontWeight: "bold" }}
                      > {reportData["wraps"]["totalseriesqtyperc"]}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="right"
              >
                % of total units
              </Typography>
              <PieDonut data={reportData["wraps"]["totaldaysalespercet"]} />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="center"
              >
                {"           Steak vs Chk"}
              </Typography>
              <PieChart
                data={reportData["wraps"]["wrapmeal"]}
                labels={["Steak", "Chk"]}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="right"
              >
                Non Combo vs Combo
              </Typography>

              <PieChart
                data={reportData["wraps"]["combononcombo"]}
                labels={["Non Combo", "Combo"]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{ fontWeight: "bold", borderBottom: "1px solid #000" }}
                variant="body1"
                align="left"
              >
                Salads
              </Typography>
              <TableContainer
                //   component={Paper}
                sx={{
                  maxHeight: 185,
                  height: 185,
                  overflow: "auto",
                  marginBottom: 2,
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0 1px 2.94px 0.06px #fff"
                      : null,

                  // backgroundColor: colors.default[100],
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow key={100}>
                      <TableCell
                        style={{ color: "black", padding: "7px" }}
                      ></TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Value
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Value%
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Qty%
                      </TableCell>
                    </TableRow>
                    {reportData["salads"]["table"].map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ padding: "6px" }}>
                          {item["descript"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["sum"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["sumperct"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["qty"]}
                        </TableCell>
                        <TableCell style={{ padding: "6px" }}>
                          {item["qtyperc"]}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key={100}>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          padding: "7px",
                        }}
                      >
                        {reportData["salads"]["totalseriessales"]}
                      </TableCell>
                      <TableCell
                        style={{ color: "black", padding: "7px",fontWeight: "bold" }}
                      >   {reportData["salads"]["totalseriessalesperc"]}</TableCell>
                      <TableCell
                        style={{ color: "black", padding: "7px",fontWeight: "bold" }}
                      >{reportData["salads"]["totalseriesqty"]}</TableCell>
                      <TableCell
                        style={{ color: "black", padding: "7px" ,fontWeight: "bold"}}
                      >   {reportData["salads"]["totalseriesqtyperc"]}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="right"
              >
                % of total units
              </Typography>
              <PieDonut data={reportData["salads"]["totaldaysalespercet"]} />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="center"
              >
                {"           Chk Vs Tuna"}
              </Typography>
              <PieChart
                data={reportData["salads"]["saladmeal"]}
                labels={["Chk", "Tuna"]}
              />
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Typography
                style={{ fontWeight: "bold" }}
                variant="body1"
                align="right"
              >
                Non Combo vs Combo
              </Typography>
              <PieChart
                data={reportData["salads"]["combononcombo"]}
                labels={["Non Combo", "Combo"]}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box
        sx={{
          marginBottom: "15px",
          textAlign: t("langDir"),
          // border: 0.5,
          // borderColor: "#000",
          padding: 3,
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)", // Add a box shadow
          borderRadius: 3,
          marginBottom: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            style={{ marginLeft: "10px", textAlign: "right", minWidth: "10px" }}
            onClick={handleDownloadPDFBarchart}
          >
            <span>
              <FileDownloadOutlinedIcon />
            </span>
            PDF
          </button>
          <Select
            placeholder={t("Select_Type")}
            optionFilterProp="children"
            value={selectedCategory}
            onChange={handleCategoryChange}
            style={{ width: "20%" }}
          >
            {optionsField.map((field) => (
              <Option key={field.label} value={field.value}>
                {field.label}
              </Option>
            ))}
          </Select>
        </Box>

        <Box ref={boxRef2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} align="center">
              <b>
                <Typography variant="h5" fontWeight="bold">
                  {selectedCategory === 1 ? "6inch Series" : "6inch Submelts"}
                </Typography>
              </b>
              <BarChartData
                colors="#e8b41a"
                data={reportBarData["sixinchlist"]}
              />
            </Grid>
            <Grid item xs={12} sm={6} align="center">
            <b>
                <Typography variant="h5" fontWeight="bold">
                {selectedCategory === 1 ? "FL Series" : "FL Submelts"}
                </Typography>
              </b>
              <BarChartData
                colors="#e8b41a"
                data={reportBarData["twelveinchlist"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: 270,
                  height: 270,
                  overflow: "auto",
                  marginBottom: 4,
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0 1px 2.94px 0.06px #fff"
                      : null,

                  backgroundColor: colors.default[100],
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow key={100} style={{ backgroundColor: "#179117" }}>
                      <TableCell style={{ color: "white", padding: "9px" }}>
                        Flavor
                      </TableCell>
                      <TableCell style={{ color: "white", padding: "9px" }}>
                        Value
                      </TableCell>
                      <TableCell style={{ color: "white", padding: "9px" }}>
                        Value %
                      </TableCell>
                      <TableCell style={{ color: "white", padding: "9px" }}>
                        Qty
                      </TableCell>
                      <TableCell style={{ color: "white", padding: "9px" }}>
                        Qty %
                      </TableCell>
                    </TableRow>
                    {reportBarData["sixinchlist"].map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ color: "black", padding: "9px" }}>
                          {item["category"]}
                        </TableCell>
                        <TableCell style={{ color: "black", padding: "9px" }}>
                          {item["value"]}
                        </TableCell>
                        <TableCell style={{ color: "black", padding: "9px" }}>
                          {item["valueperc"]}
                        </TableCell>
                        <TableCell style={{ color: "black", padding: "9px" }}>
                          {item["qty"]}
                        </TableCell>
                        <TableCell style={{ color: "black", padding: "9px" }}>
                          {item["qtyperc"]}
                        </TableCell>
                      </TableRow>
                    ))}

                    {/* <TableRow key={100}>
                
                    <TableCell style={{ color: "black" }}>1000</TableCell>
                    <TableCell style={{ color: "black" }}>20</TableCell>
                    <TableCell style={{ color: "black" }}>10%</TableCell>
                  </TableRow> */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: 270,
                  height: 270,
                  overflow: "auto",
                  marginBottom: 4,
                  boxShadow:
                    theme.palette.mode === "dark"
                      ? "0 1px 2.94px 0.06px #fff"
                      : null,

                  backgroundColor: colors.default[100],
                }}
              >
                <Table>
                  <TableBody>
                    <TableRow key={100} style={{ backgroundColor: "#179117" }}>
                      <TableCell style={{ color: "white", padding: "9px" }}>
                        Flavor
                      </TableCell>
                      <TableCell style={{ color: "white", padding: "9px" }}>
                        Value
                      </TableCell>
                      <TableCell style={{ color: "white", padding: "9px" }}>
                        Value %
                      </TableCell>
                      <TableCell style={{ color: "white", padding: "9px" }}>
                        Qty
                      </TableCell>
                      <TableCell style={{ color: "white", padding: "9px" }}>
                        Qty %
                      </TableCell>
                    </TableRow>

                    {reportBarData["twelveinchlist"].map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ color: "black", padding: "9px" }}>
                          {item["category"]}
                        </TableCell>
                        <TableCell style={{ color: "black", padding: "9px" }}>
                          {item["value"]}
                        </TableCell>
                        <TableCell style={{ color: "black", padding: "9px" }}>
                          {item["valueperc"]}
                        </TableCell>
                        <TableCell style={{ color: "black", padding: "9px" }}>
                          {item["qty"]}
                        </TableCell>
                        <TableCell style={{ color: "black", padding: "9px" }}>
                          {item["qtyperc"]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SubwaySeriesReport;
