import { React, useState, useEffect, useContext } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import jwtDecode from "jwt-decode";
import i18next from "i18next";
import OutletContext from "../../context/OutletContext";

function Copyright() {
  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 1 }}
      >
        {"© Hospitality App - "}
        <Link
          color="inherit"
          href="https://kaemsolutions.com/code-plus/"
          target="_blank"
        >
          CodePlus
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>

      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 1, mb: 1 }}
      >
        {" "}
        {"Powered By  "}
        <Link color="inherit" href="https://kaemsolutions.com/" target="_blank">
          KAEM SOLUTIONS
        </Link>{" "}

      </Typography>
    </>
  );
}

export default function LoginPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  // const from = location.state?.from?.pathname || "/dashboard/onlinereport";
  const { auth, login } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { OutletId, setOutletId } = useContext(OutletContext);

  useEffect(() => {
    setError("");
  }, [username, password]);

  useEffect(() => {
    // Check if the user is already authenticated and token is active
    document.title = "Codeplus Hospitality App";

    if (auth) {
      const token = localStorage.getItem("token");
      const landingpage = jwtDecode(token);
      // Redirect to the dashboard
      navigate(landingpage.landingpage);
    }
  }, [auth, navigate]);

  const onhandleClick = async (e) => {
    e.preventDefault();
    setError("");

    const success = await login(username, password);
    if (success) {
      const token = localStorage.getItem("token");
      const landingpage = jwtDecode(token);
      navigate(landingpage.landingpage, { replace: true });
      i18next.changeLanguage(localStorage.getItem("Lang"));
      if (jwtDecode(token).outlets[0]?.id) {
        setOutletId([jwtDecode(token).outlets[0]?.id]);
      } else {
        setOutletId([]);
      }
    } else {
      setError("Invalid username or password");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 5,
          marginTop: 9,
          px: 6,
          py: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          alt="profile-user"
          width="200px"
          height="80px"
          src="../../assets/codepluslogo.png"
          style={{ borderRadius: "50%" }}
        />
        <Box sx={{ mt: 1 }}>
          <form onSubmit={onhandleClick}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
              //   hintText="username"
              //   placholder="username"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person2OutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  defaultChecked
                  sx={{
                    "&.Mui-checked": {
                      color: "#db4f4a",
                    },
                  }}
                />
              }
              label="Remember me"
            />
            {error && (
              <Typography variant="body2" color="error" align="center">
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </form>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <a href="https://play.google.com/store/apps/details?id=com.kaemsolutions.codeplus"  target="_blank">
          <img
            alt="profile-user"
            width="280px"
            height="90px"
            src="../../assets/googleplaylogo.png"
            // style={{ borderRadius: "50%" }}
          />
        </a>
      </Box>

      <Copyright />
    </Container>
  );
}
