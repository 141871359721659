import React, { useRef } from "react";
import PageHeader from "../../../components/PageHeader";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Paper,
  Typography,
  useTheme,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { tokens } from "../../../theme";
import { useState, useEffect, useContext } from "react";
import jwtDecode from "jwt-decode";
import { Select } from "antd";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { baseInstance, updateToken } from "../../../api/axios";
import OutletContext from "../../../context/OutletContext";
import CustomDateRange from "../../../components/CustomDateRange";
import DateContext from "../../../context/DateContext";
import OutletDropdown from "../../../components/supportingComponents/outletdropdown";
import BarChartData from "./barchart";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { CustomProvider } from "rsuite";
import { DownOutlined } from "@ant-design/icons";
import LineChart from "./linechart";
import BarChartVertical from "./barchartvertical";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function LoadingSpinner() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      style={{ height: "300px", backgroundColor: colors.default[100] }}
      className="container-logo-bounce"
    >
      <div className="logo-bounce" style={{marginTop:"10%",marginLeft:"5%"}}></div>
      <div className="shadow-bounce" style={{marginTop:"10%",marginLeft:"5%"}}/>
    </div>
  );
}

const FoodSalesInsights = () => {
  const boxRef = useRef(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [outlets, setOutlets] = useState([]);
  const { OutletId, setOutletId } = useContext(OutletContext);
  // const [selectedOutlet, setSelectedOutlet] = useState(OutletId[0]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryGrid, setSelectedCategoryGrid] = useState([]);
  const [spinnerstatus, setspinnerstatus] = useState(false);
  //state for the data retrivals

  const [selectedTransDetails, setSelectedTransDetails] = useState([]);
  const [selectedTotalStorewise, setSelectedTotalStorewise] = useState([]);
  const [selectedTotalSalesType, setSelectedTotalSalesType] = useState([]);
  const [selectedVerticalBarChart, setSelectedVerticalBarChart] = useState([]);
  const [selectedAmountData, setSelectedAmountData] = useState([]);

  const [selectedBarChart, setSelectedBarChart] = useState([]);

  //-----------------------------------------------------------------------------
  const [selectedReportType, setSelectedReportType] = useState(1);
  const [selectedReportFormat, setSelectedReportFormat] = useState(2);

  const [gridwidth, setGridwidth] = useState(12);

  const [categorylabel, setCategoryLabel] = useState("");
  const Lang = localStorage.getItem("Lang");
  const { Option } = Select;
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateContext);

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handlecategorychange = (selectedValue, option) => {
    setSelectedCategory(selectedValue);
  };

  const handleReportformat = (selectedValue, option) => {
    setSelectedReportFormat(selectedValue);
  };

  const handleReportType = (selectedValue, option) => {
    if (headofficeStatus()) {
      setSelectedCategory([]);
      setSelectedReportType(selectedValue);
      fetchdetails(selectedValue);
    }
  };

  const handleOutletChange = (selectedValue) => {
    setOutletId(selectedValue);
  };

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    setOutletId([jwtDecode(localStorage.getItem("token")).outlets[0]?.id]);
    fetchdetails(selectedReportType);
  }, []);

  const fetchdetails = (selectedValue) => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post("/api/businessintelligence/foodsalesinsights", {
        storeid:
          OutletId === ""
            ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
            : OutletId,
        reportType: selectedValue,
      })
      .then((response) => {
        if (response.status === 200) {
          setCategoryList(response.data.datalist);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          handleLogout();
          toast.error("Unauthorized access");
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
        }
      });
  };

  const headofficeStatus = () => {
    const outletlog = jwtDecode(localStorage.getItem("token")).outletsdetails;
    if (OutletId.length === 1) {
      return true;
    } else if (OutletId.length > 1) {
      const isHeadOffice = OutletId.every((outletId) => {
        const outletStatus = outletlog.find((item) => item.id === outletId);
        return outletStatus && outletStatus.headofficeData === 1;
      });

      if (isHeadOffice) {
        return true;
      } else {
        toast.error("The selected Outlets should be Headoffice Outlets");
        return false;
      }
    } else {
      toast.error("The selected Outlets should be Headoffice Outlets Here");
      return false;
    }
  };

  const suffix = (
    <>
      <span>
        {selectedCategory.length} / {3}
      </span>
      <DownOutlined />
    </>
  );

  const reportType = [
    { id: 1, reportname: "Summary Group" },
    { id: 2, reportname: "Category" },
    { id: 3, reportname: "Item" },
    { id: 4, reportname: "Batches" },
  ];

  const reportformat = [
    // { id: 1, reportname: "Analysis" },
    { id: 2, reportname: "Comparison" },
    // { id: 3, reportname: "Rankings" },
  ];

  // const getfile = () => {
  //   let url = "/api/integration/flavourmix/download";
  //   updateToken(localStorage.getItem("token"));
  //   baseInstance
  //     .post(url, selectedOutlet, {
  //       responseType: "blob", // Set the response type to 'blob'
  //     })
  //     .then(function (response) {
  //       const blob = new Blob([response.data], { type: "application/pdf" });
  //       saveAs(blob, "Invoice_a.pdf");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       if (error.response.status === 403) {
  //         navigate(`/`);
  //       } else if (error.response.status === 401) {
  //         handleLogout();
  //       } else {
  //         console.error(error);
  //       }
  //     });
  // };

  const generateReport = () => {
    if (headofficeStatus() && selectedCategory.length>=1) {
      setSelectedCategoryGrid(selectedCategory);
      const formattedSelectedCategory = selectedCategory.map((id) => {
        const category = categoryList.find((item) => item.id === id);
        return { id: category.id, item: category.item };
      });
      setspinnerstatus(true);
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post("/api/businessintelligence/foodsalesinsights/detail", {
          apiData: {
            storeid:
              OutletId === ""
                ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
                : OutletId,
            startDate,
            endDate,
            selectedReportType,
            itemlist: formattedSelectedCategory,
            selectedReportFormat,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (selectedCategory.length === 2) {
              setGridwidth(6);
            } else if (selectedCategory.length === 3) {
              setGridwidth(4);
            } else {
              setGridwidth(12);
            }
            setSelectedTransDetails(response.data.transdetails);
            setSelectedVerticalBarChart(response.data.verticalbarchartdata);
            setSelectedTotalSalesType(response.data.total_salestype);
            setSelectedBarChart(response.data.barchartdata);
            setSelectedTotalStorewise(response.data.total_storewise);
            setSelectedAmountData(response.data.amountdata);
            setspinnerstatus(false);

            //  set(response.data.transdetails);
            //  setSelectedTransDetails(response.data.transdetails);
            //  setSelectedTransDetails(response.data.transdetails);
          }
        })
        .catch((error) => {
          setspinnerstatus(false);
          if (error.response.status === 403) {
            handleLogout();
            toast.error("Unauthorized access");
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.log(error);
          }
          // setCardLoading(false);
        });
    }
    else{
      toast.error("Select the Items");
    }
    // console.log(selectedCategory);
  };


  const handleDownloadPDF = () => {
    const boxElement = boxRef.current;
    const options = {
      scale: 2.2, // Increase the scale for higher clarity
    };

    html2canvas(boxElement, options).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        unit: "px",
        format: "a4",
        orientation: "portrait",
        putOnlyUsedFonts: true,
        compress: true,
        lineHeight: 1.2,
      });
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      // pdf.setFillColor(179, 217, 179);
      // pdf.rect(0, 0, width, height, "F");
      pdf.addImage(imgData, "PNG", 20, 10, width - 40, height);
      pdf.setTextColor(1, 133, 57);
      const formattedStartDate = startDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      const formattedEndDate = endDate.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      pdf.setFontSize(10);
      pdf.text("" + formattedStartDate + " - " + formattedEndDate, 20, 20); // Adjust margins as needed
      pdf.setTextColor(0, 0, 0); // RGB color for green
      pdf.setFontSize(8);
      pdf.text("Powered By kaemsolutions.com", width - 100, height - 5);
      pdf.save("FoodSalesInsights.pdf");
    });
  };

  return (
    <Box className="h-75 mx-auto w-90 text-align">
      <PageHeader centerText={t("Food Sales Insights")} />

      <Box
        sx={{
          marginBottom: "15px",
          textAlign: t("langDir"),
          // border: 0.5,
          // borderColor: "#000",
          padding: 3,
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)", // Add a box shadow
          borderRadius: 3,
          marginBottom: 2,
        }}
      >
        {" "}
        <Grid container spacing={1}>
          <Grid item xs={1.4}>
            <CustomProvider theme={theme.palette.mode}>
              <Select
                style={{
                  // marginLeft: "5px",
                  width: "110px",
                  // marginRight: "10px",
                }}
                //   showSearch
                placeholder={t("Select_Type")}
                optionFilterProp="children"
                //   filterOption={filterOption}
                value={selectedReportFormat}
                onChange={handleReportformat}
              >
                {" "}
                {reportformat.map((report) => (
                  <Option
                    key={report.id}
                    value={report.id}
                    label={report.reportname}
                  >
                    {report.reportname}
                  </Option>
                ))}
              </Select>
            </CustomProvider>
          </Grid>

          <Grid item xs={1.8}>
            <CustomProvider theme={theme.palette.mode}>
              <Select
                style={{
                  // marginLeft: "5px",
                  width: "140px",
                  // marginRight: "10px",
                }}
                //   showSearch
                placeholder={t("Select_Report_Type")}
                optionFilterProp="children"
                //   filterOption={filterOption}
                value={selectedReportType}
                onChange={handleReportType}
              >
                {" "}
                {reportType.map((report) => (
                  <Option
                    key={report.id}
                    value={report.id}
                    label={report.reportname}
                  >
                    {report.reportname}
                  </Option>
                ))}
              </Select>
            </CustomProvider>
          </Grid>

          <Grid item xs={2}>
            <CustomProvider theme={theme.palette.mode}>
              <Select
                style={{
                  // marginLeft: "20px",
                  width: "160px",
                  // marginRight: "10px",
                }}
                showSearch
                mode="multiple"
                placeholder={t("Select_a_item")}
                optionFilterProp="children"
                filterOption={filterOption}
                value={selectedCategory}
                suffixIcon={suffix}
                onChange={handlecategorychange}
                maxCount={3} // Restrict to 3 items
                // maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
              >
                {" "}
                {categoryList.map((category) => (
                  <Option
                    key={category.id}
                    value={category.id}
                    label={category.item}
                  >
                    {category.item}
                  </Option>
                ))}
              </Select>
            </CustomProvider>
          </Grid>

          <Grid item xs={2.3}>
            <CustomProvider theme={theme.palette.mode}>
              <CustomDateRange sx={{ marginBottom: "0px" }} />
            </CustomProvider>
          </Grid>

          <Grid item xs={2}>
            <CustomProvider theme={theme.palette.mode}>
              <OutletDropdown
                defaultValue={OutletId}
                onChange={handleOutletChange}
              />
            </CustomProvider>
          </Grid>
          <Grid item xs={2}>
            <button
              style={{ marginLeft: "100px" }}
              type="button"
              className="btn btn-sm btn-primary"
              onClick={generateReport}
            >
              {t("Generate")}
            </button>
          </Grid>
        </Grid>
      </Box>

      {/* <Button onClick={handleDownloadPDF} variant="contained" color="primary">
        Download PDF
      </Button>     */}
      <Box
        sx={{
          marginBottom: "15px",
          textAlign: t("langDir"),
          padding: 3,
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)",
          borderRadius: 3,
          marginBottom: 2,
          minHeight: "350px",
        }}
      >{spinnerstatus?(
        <Box>
          <LoadingSpinner />
        </Box>):(
        <Box>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            style={{ marginLeft: "10px", textAlign: "right", minWidth: "10px" }}
            onClick={handleDownloadPDF}
          >
            <span>
              <FileDownloadOutlinedIcon />
            </span>
            PDF
          </button>
          <Box
            ref={boxRef}
            sx={{
              background: "transparent",
            }}
          >
            <Grid container spacing={4} marginTop={2}>
              <Grid item xs={1} sm={12} style={{ textAlign: "center" }}>
                {selectedTransDetails.map((trans, index) => (
                  <Typography
                    style={{ fontWeight: "bold", display: "inline-block" }}
                    variant="body1"
                    align="center"
                  >
                    {index !== selectedTransDetails.length - 1
                      ? ` ${trans.item} Vs ` + " "
                      : ` ` + trans.item}
                  </Typography>
                ))}
              </Grid>

              {selectedTransDetails.map((trans, index) => (
                <Grid item key={index} xs={12} sm={gridwidth}>
                  {/* <Typography style={{ fontWeight: 'bold' }} variant="body1" align="center">
                {trans.item}
                </Typography> */}
                  <LineChart
                    name={trans.item} // Assuming category has a 'name' property
                    colors="#e8b41a"
                    data={trans.data} // Assuming category has a 'color' property
                    // Additional props as needed
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={4} marginTop={2}>
              {selectedAmountData.map((totalsales, index) => (
                <Grid item xs={12} sm={gridwidth}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxHeight: 120,
                      height: 120,
                      overflow: "auto",
                      marginBottom: 4,
                      boxShadow:
                        theme.palette.mode === "dark"
                          ? "0 1px 2.94px 0.06px #fff"
                          : null,

                      backgroundColor: colors.default[100],
                    }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow
                          key={100}
                          style={{ backgroundColor: "#179117" }}
                        >
                          <TableCell style={{ color: "white" }}>
                            Amount
                          </TableCell>
                          <TableCell style={{ color: "white" }}>Amt%</TableCell>
                          <TableCell style={{ color: "white" }}>Qty</TableCell>
                          <TableCell style={{ color: "white" }}>Qty%</TableCell>

                        </TableRow>

                        <TableRow key={index}>
                          <TableCell>{totalsales.data.amount}</TableCell>
                          <TableCell>{totalsales.data.amtperc}</TableCell>
                          <TableCell>{totalsales.data.qty}</TableCell>
                          <TableCell>{totalsales.data.qtyperc}</TableCell>

                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={4} marginTop={2}>
              {selectedVerticalBarChart.map((bardata, index) => (
                <Grid item xs={12} sm={gridwidth}>
                  <BarChartVertical
                    colors="#e8b41a"
                    data={bardata.data.data}
                    item={bardata.data.category}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={4} marginTop={2}>
              {selectedTotalSalesType.map((totalsales, index) => (
                <Grid item xs={12} sm={gridwidth}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxHeight: 250,
                      height: 250,
                      overflow: "auto",
                      marginBottom: 4,
                      boxShadow:
                        theme.palette.mode === "dark"
                          ? "0 1px 2.94px 0.06px #fff"
                          : null,

                      backgroundColor: colors.default[100],
                    }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow
                          key={100}
                          style={{ backgroundColor: "#179117" }}
                        >
                          <TableCell style={{ color: "white" }}>
                            Discrpt
                          </TableCell>
                          <TableCell style={{ color: "white" }}>
                            Amount
                          </TableCell>
                          <TableCell style={{ color: "white" }}>QTY</TableCell>
                          <TableCell style={{ color: "white" }}>qty%</TableCell>
                        </TableRow>
                        {totalsales.data.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.descript}</TableCell>
                            <TableCell>{item.amount}</TableCell>
                            <TableCell>{item.qty}</TableCell>
                            <TableCell>{item.qtyperct}</TableCell>
                          </TableRow>
                        ))}

                        {/* <TableRow key={100}>
                        <TableCell style={{ color: "black" }}>
                          Total 6inch Series Subs Sold
                        </TableCell>
                        <TableCell style={{ color: "black" }}>Qty</TableCell>
                      </TableRow> */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={4} marginTop={2}>
              {selectedBarChart.map((bardata, index) => (
                <Grid item xs={12} sm={gridwidth}>
                  <BarChartData
                    colors="#e8b41a"
                    //   linechartdata={tableData["linechartdata"]}
                    name={bardata.item}
                    data={bardata.data.data}
                    category={bardata.data.category}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={4} marginTop={2}>
              {selectedTotalStorewise.map((store, index) => (
                <Grid item xs={12} sm={gridwidth}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxHeight: 250,
                      height: 250,
                      overflow: "auto",
                      marginBottom: 4,
                      boxShadow:
                        theme.palette.mode === "dark"
                          ? "0 1px 2.94px 0.06px #fff"
                          : null,

                      backgroundColor: colors.default[100],
                    }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow
                          key={100}
                          style={{ backgroundColor: "#179117" }}
                        >
                          <TableCell style={{ color: "white" }}>
                            Descript
                          </TableCell>
                          <TableCell style={{ color: "white" }}>
                            Amount
                          </TableCell>
                          <TableCell style={{ color: "white" }}>QTY</TableCell>
                          <TableCell style={{ color: "white" }}>qty%</TableCell>
                        </TableRow>
                        {store.data.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.descript}</TableCell>
                            <TableCell>{item.amount}</TableCell>
                            <TableCell>{item.qty}</TableCell>
                            <TableCell>{item.qtyperct}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>)}
      </Box>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            fontWeight="bold"
            display="inline"
            style={{ color: "#179117" }}
          >
            {categorylabel}
            {t(" | ")}
          </Typography>
          <Typography
            variant="h4"
            fontWeight="bold"
            display="inline"
            style={{ color: "#179117" }}
          >
            {t("Flavor Mix | 20 Jan to 20 Feb")}
          </Typography>
        </div>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          style={{ marginLeft: "10px", textAlign: "right", minWidth: "10px" }}
          onClick={getfile}
        >
          <span>
            <FileDownloadOutlinedIcon />
          </span>
          PDF
        </button>
      </div>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <BarChartData
              colors="#179117"
              //   linechartdata={tableData["linechartdata"]}
              name="6inch Series Subs | Flavour Mix"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              height: "100%",

              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <BarChartData
              colors="#e8b41a"
              //   linechartdata={tableData["linechartdata"]}
              name="12inch Series Subs | Flavour Mix"
            />
          </Paper>
        </Grid>
      </Grid>

       */}
    </Box>
  );
};

export default FoodSalesInsights;
