import React, { useEffect, useState, useContext } from "react";
import PageHeader from "../../../components/PageHeader";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../../../components/supportingComponents/CustomNoRowsOverlay";
import { useTranslation } from "react-i18next";
import OutletContext from "../../../context/OutletContext";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import CustomDateRange from "../../../components/CustomDateRange";
import { DatePicker, CustomProvider } from "rsuite";
import { Form, Input, Row, Col } from "antd";
import Link from "@mui/material/Link";
import { updateToken, baseInstance } from "../../../api/axios";
import { toast } from "react-toastify";
import SendMessageModal from "./messageModal";
import { ConfigProvider, theme } from "antd";

const WhatsApp = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const themecustom = useTheme();
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const { OutletId, setOutletId } = useContext(OutletContext);
  const [contactsRows, setContactRows] = useState([]);
  const [viewLog, setViewLog] = useState([]);
  const [accountdetail, setAccountDetails] = useState(null);
  const [form] = Form.useForm();
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [enablewhatsapp, setEnableWhatsapp] = useState(true);

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const columns = [
    {
      field: "id",
      headerName: t("id"),
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "name",
      headerName: t("Customer Name"),
      width: 400,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "phonenumber",
      headerName: t("Phone No."),
      width: 400,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "outlet",
      headerName: t("Outlet"),
      width: 200,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  const handleApiRequest = async () => {
    updateToken(localStorage.getItem("token"));
    try {
      let response;
      const fieldValues = form.getFieldsValue();
      response = await baseInstance.post(
        "/api/integration/whatsappmessaging/save",
        {
          AccountId: fieldValues.AccountId,
          AuthToken: fieldValues.AuthToken,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const saveapi = () => {
    handleApiRequest()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Posted API Details!!");
        } else {
          console.log("Request failed with status:", response.status);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
        toast.error("Record Failed!");
        console.log(error);
      });
  };

  useEffect(() => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/integration/whatsappmessaging")
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          setContactRows(data.contacts);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          handleLogout();
          toast.error("Unauthorized access");
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
        }
      });
  }, []);

  useEffect(() => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/integration/whatsappmessaging/viewlog")
      .then((response) => {
        if (response.status === 200) {
          setViewLog(response.data.response);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          handleLogout();
          toast.error("Unauthorized access");
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
        }
      });
  }, []);

  useEffect(() => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/integration/whatsappmessaging/save")
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          form.setFieldsValue({
            AccountId: data.accountid,
            AuthToken: data.authtoken,
          });
          setEnableWhatsapp(data.enableapi);

          if (data.balance != null) {
            setAccountDetails({
              balance: data.balance,
              accounts: data.accounts,
            });
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          handleLogout();
          toast.error("Unauthorized access");
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
        }
      });
  }, []);

  const columns2 = [
    {
      field: "id",
      headerName: t("id"),
      width: 400,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "Date",
      headerName: t("Date"),
      width: 150,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "Message",
      headerName: t("Message"),
      width: 400,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "MobileNumber",
      headerName: t("Phone No"),
      width: 200,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "Price",
      headerName: t("Price"),
      width: 200,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "Successcount",
      headerName: t("Success"),
      width: 150,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "failurecount",
      headerName: t("Fails"),
      width: 150,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  return (
    <>
      <SendMessageModal
        isOpen={messageModalOpen !== false}
        onClose={() => {
          setMessageModalOpen(false);
        }}
      />
      <Box className=" h-75 mx-auto w-90 text-align " sx={{ width: "100%" }}>
        <PageHeader subtitle={"ًWhatsapp Messaging - Beta"} />
        <ConfigProvider
            theme={{
              algorithm:
                themecustom.palette.mode == "dark"
                  ? darkAlgorithm
                  : defaultAlgorithm,
            }}
          >
        <Box
          sx={{
            marginBottom: "5px",
            textAlign: t("langDir"),
            // border: 0.5,
            // borderColor: "#000",
            padding: 3,
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)", // Add a box shadow
            borderRadius: 3,
            marginBottom: 2,
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            style={{ marginBottom: 10 }}
          >
            Setup{" "}
            {enablewhatsapp ? (
              ""
            ) : (
              <span style={{ color: "red" }}> - In Active (Contact Support to Activate)</span>
            )}
          </Typography>
          <Form
            form={form}
            name="whatsappForm"
            layout="horizontal"
            initialValues={{
              layout: "horizontal",
              //   selectedOutlet: selectedOutlet,
              //   selectedDay: 0,
            }}
            sx={{ display: "flex", flexDirection: "column" }}
            onFinish={saveapi}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Account ID"
                  name="AccountId"
                  style={{ marginBottom: 3 }}
                  rules={[
                    {
                      required: true,
                      message: "Account ID is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Auth Token"
                  name="AuthToken"
                  style={{ marginBottom: 3 }}
                  rules={[
                    {
                      required: true,
                      message: "Auth Token is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button type="submit" className="btn btn-sm btn-primary"   disabled={!enablewhatsapp}>
                {t("Save")}
              </button>
              <div style={{ flex: 1 }}></div>{" "}
              <Link
                color="inherit"
                href="https://console.twilio.com/"
                target="_blank"
              >
                Login Into Twilo Account
              </Link>
            </div>

            {accountdetail ? (
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}></div>{" "}
                  <Typography variant="body2" color="textSecondary">
                    Account Name: {accountdetail.accounts}{" "}
                  </Typography>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}></div>{" "}
                  <Typography variant="body2" color="textSecondary">
                    Your Balance: {accountdetail.balance}{" "}
                  </Typography>
                </div>
              </div>
            ) : null}
          </Form>
        </Box>

        <Box
          sx={{
            marginBottom: "15px",
            textAlign: t("langDir"),
            padding: 3,
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)", // Add a box shadow
            borderRadius: 3,
            marginBottom: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" fontWeight="bold">
              Contacts
            </Typography>
          </Box>
          <DataGrid
            rows={contactsRows}
            columns={columns}
            slots={{
              noRowsOverlay: () => (
                <CustomNoRowsOverlay message={t("No Contacts")} />
              ),
            }}
            style={{
              height: 350,
              marginBottom: "15px",
           
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 4,
                },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
          <button
            // style={{ marginTop: 2 }}
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => setMessageModalOpen(true)}
            disabled={!enablewhatsapp}
          >
            {t("Send Message")}
          </button>
        </Box>

        <Box
          sx={{
            marginBottom: "15px",
            textAlign: t("langDir"),
            // border: 0.5,
            // borderColor: "#000",
            padding: 3,
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)", // Add a box shadow
            borderRadius: 3,
            marginBottom: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" fontWeight="bold">
              SMS Logs
            </Typography>
          </Box>
          <DataGrid
            rows={viewLog}
            columns={columns2}
            slots={{
              noRowsOverlay: () => (
                <CustomNoRowsOverlay message={t("No Messages")} />
              ),
            }}
            style={{
              height: 350,
              marginBottom: "15px",
    
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 4,
                },
              },
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </Box>
        </ConfigProvider>
      </Box>
      
    </>
  );
};

export default WhatsApp;
