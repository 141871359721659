import React, { useState } from "react";
import { Box, Typography, Card,  useTheme } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme";

const DashboardCard = (props) => {
  const { t } = useTranslation();
  const salesPreviousWeek = 10; // get this from your data
  const [data, setData] = useState(props.dataResponse);
  const [isCardLoading, setCardLoading] = useState(props.isCardLoading);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    setData(props.dataResponse);
  }, [props.dataResponse]);

  return (
    <Box className="row">
      <Box className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <Box style={{ position: "relative" }}>
          <Box
            className="icon icon-lg icon-shape  shadow-dark text-center border-radius-xl"
            style={{
              position: "absolute",
              top: -25,
              left: "18%",
              transform: "translateX(-50%)",
              backgroundColor: colors.primary[500],
            }}
          >
            <img
              src="../../assets/codeplusicons/Code Plus UI-UX-15.svg"
              className="img-fluid "
              style={{ maxHeight: 100, maxWidth: 41 }}
            />
          </Box>
          <Card
            className="codeplus-blue"
            style={{
              borderRadius: 10,
              marginTop: 10,
              backgroundColor: colors.secondary[500],
              boxShadow: "0 1px 2.94px 0.06px #ffffff",
              height: "115px",
            }}
          >
            <Box className="card-header p-2 pt-2">
              <Box className="text-end pt-1">
                <Typography
                  style={{ fontSize: 16, marginTop: "10px" }}
                  className="text-success font-weight-bolder text-sm mb-0 text-capitalize"
                >
                  Total Companies
                </Typography>
              
              </Box>
            </Box>

            <Box
              className="p-2 "
              style={{
                borderRadius: 10,
                marginTop: "20px",
                backgroundColor: colors.primary[500],
              }}
            >
              <Typography style={{ fontSize: 17 }} className="mb-0">
                <span
                  className="text-success text-sm font-weight-bolder"
                  id="output"
                >
                  {data.total_tax}
                </span>
              </Typography>
            </Box>
          </Card>
        </Box>
      </Box>
      <Box className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <Box style={{ position: "relative" }}>
          <Box
            className="icon icon-lg icon-shape  shadow-dark text-center border-radius-xl"
            style={{
              position: "absolute",
              top: -25,
              left: "18%",
              transform: "translateX(-50%)",
              boxShadow: "0 1px 2.94px 0.06px #ffffff",
              backgroundColor: colors.primary[500],
            }}
          >
            <img
              src="../../assets/codeplusicons/Code Plus UI-UX-14.svg"
              className="img-fluid "
              style={{ maxHeight: 100, maxWidth: 41 }}
            />
          </Box>
          <Card
            className="codeplus-blue"
            style={{
              borderRadius: 10,
              marginTop: 10,
              backgroundColor: colors.secondary[500],
              boxShadow: "0 1px 2.94px 0.06px #ffffff",
              height: "115px",
            }}
          >
            <Box className="card-header p-2 pt-2">
              <Box className="text-end pt-1">
                <Typography
                  style={{ fontSize: 16, marginTop: "10px" }}
                  className="text-success font-weight-bolder text-sm mb-0 text-capitalize"
                >
                  Total Outlets
                </Typography>
              </Box>
            </Box>
            <Box
              className="p-2 "
              style={{
                borderRadius: 10,
                marginTop: "20px",
                backgroundColor: colors.primary[500],
              }}
            >
              <Typography style={{ fontSize: 17 }} className="mb-0">
                <span
                  className="text-success text-sm font-weight-bolder"
                  id="output"
                >
                  {data.total_tax}
                </span>
              </Typography>
            </Box>
          </Card>
        </Box>
      </Box>
      <Box className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <Box style={{ position: "relative" }}>
          <Box
            className="icon icon-lg icon-shape  shadow-dark text-center border-radius-xl"
            style={{
              position: "absolute",
              top: -25,
              left: "18%",
              transform: "translateX(-50%)",
              backgroundColor: colors.primary[500],
            }}
          >
            <img
              src="../../assets/codeplusicons/Code Plus UI-UX-16.svg"
              className="img-fluid "
              style={{ maxHeight: 100, maxWidth: 41 }}
            />
          </Box>
          <Card
            className="codeplus-blue"
            style={{
              borderRadius: 10,
              marginTop: 10,
              backgroundColor: colors.secondary[500],
              boxShadow: "0 1px 2.94px 0.06px #ffffff",
              height: "115px",
            }}
          >
            <Box className="card-header p-2 pt-2">
              <Box className="text-end pt-1">
                <Typography
                  style={{ fontSize: 16, marginTop: "10px" }}
                  className="text-success font-weight-bolder text-sm mb-0 text-capitalize"
                >
                  Total Users
                </Typography>
              </Box>
            </Box>

            <Box
              className="p-2 "
              style={{
                borderRadius: 10,
                marginTop: "20px",
                backgroundColor: colors.primary[500],
              }}
            >
              <Typography style={{ fontSize: 17 }} className="mb-0">
                <span
                  className="text-success text-sm font-weight-bolder"
                  id="output"
                >
                  {data.total_customer}
                </span>
              </Typography>
            </Box>
          </Card>
        </Box>
      </Box>
      <Box className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <Box style={{ position: "relative" }}>
          <Box
            className="icon icon-lg icon-shape  shadow-dark text-center border-radius-xl"
            style={{
              position: "absolute",
              top: -25,
              left: "18%",
              transform: "translateX(-50%)",
              backgroundColor: colors.primary[500],
            }}
          >
            <img
              src="../../assets/codeplusicons/Code Plus UI-UX-17.svg"
              className="img-fluid "
              style={{ maxHeight: 100, maxWidth: 41 }}
            />
          </Box>
          <Card
            className="codeplus-blue"
            style={{
              borderRadius: 10,
              marginTop: 10,
              backgroundColor: colors.secondary[500],
              boxShadow: "0 1px 2.94px 0.06px #ffffff",
              height: "115px",
            }}
          >
            <Box className="card-header p-2 pt-2">
              <Box className="text-end pt-1">
                <Typography
                  style={{ fontSize: 16, marginTop: "10px" }}
                  className="text-success font-weight-bolder text-sm mb-0 text-capitalize"
                >
                  Total QR Outlets
                </Typography>
              </Box>
            </Box>

            <Box
              className="p-2"
              style={{
                borderRadius: 10,
                marginTop: "20px",
                backgroundColor: colors.primary[500],
              }}
            >
              <Typography style={{ fontSize: 17 }} className="mb-0">
                <span
                  className="text-success text-sm font-weight-bolder"
                  id="output"
                >
                  {data.salesaverage}
                </span>
              </Typography>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardCard;
