import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Transactionsummary(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div className="modal-body">
                <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>
                 
                </div>

                <table id="TaxSummaryviewtable" style={{ width: "100%" }}>
                    <thead>
                    <tr className="headingtop">
                        <td>{t('Transact')}#</td>
                        <td>{t('Table')}#</td>
                        <td>{t('Time_Start')}</td>
                        <td>{t('Time_End')}</td>
                        <td>{t('Trans_Time')}</td>
                        <td>{t('Who_Started')}</td>
                        <td>{t('Who_Closed')}</td>
                        <td>{t('Net_Total')} </td>
                        <td>{t('Final_Total')}</td>                
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.data.transactiondata.map((item,index) => (
                            <tr key={index}>
                                <td>{item.transact}</td>
                                <td>{item.tableno}</td>
                                <td>{item.timestart}</td>
                                <td>{item.timeend}</td>
                                <td>{item.tabletime}</td>
                                <td>{item.whostarted}</td>
                                <td>{item.whoend}</td>
                                <td>{item.nettotal}</td>
                                <td>{item.finaltotal}</td>
                            </tr>
                        ))
                    }
                    <tr>
                        <td className='font-weight-bold'>{t('Total')}</td>
                        <td className='font-weight-bold'></td>
                        <td className='font-weight-bold'></td>
                        <td className='font-weight-bold'></td>
                        <td className='font-weight-bold'></td>
                        <td className='font-weight-bold'></td>
                        <td className='font-weight-bold'>{props.data.nettotal}</td>
                        <td className='font-weight-bold'>{props.data.totalvalue}</td>
                    </tr>
                                      
                    
                    </tbody>
                </table>
                
            </div>
        </div>
    )
}