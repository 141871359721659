import React, { useState,useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material";
const LineChart = (props) => {
  const theme = useTheme();

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "",
        data: [        
        ], 
      },
    ],
    options: {
      theme: {
        mode: "light",
      },
      chart: {
        type: "area",
        stacked: false,
        height: 330,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
 
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return (val).toFixed(0);
          },
        },
        title: {
          text: "Amount",
        },
      },
      xaxis: {
        type: "datetime",
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val) {
            return (val).toFixed(0);
          },
        },
      },
    },
  });

  useEffect(() => {
    setChartData({
      ...chartData,
      series: [
        {
          name: props.name,
          data: props.linechartdata,
        },
      ],
    });


  }, [props.linechartdata]);

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      options: {
        ...prevChartData.options,
        
        theme: {
          ...prevChartData.options.theme,
          mode: theme.palette.mode, // Update categories from props
        },
      },
     
    }));
  }, [theme.palette.mode]);

  return (
    <div id="chart" style={{padding:"10px",width:"100%"}}>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={330}
        
      />
    </div>
  );
};

export default LineChart;
