import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader";
import { useTranslation } from "react-i18next";
import { Box, Grid, Paper, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import OutletDropdown from "../../../components/supportingComponents/outletdropdown";
import { useContext } from "react";
import { useEffect } from "react";
import OutletContext from "../../../context/OutletContext";
import useAuth from "../../../hooks/useAuth";
import { baseInstance, updateToken } from "../../../api/axios";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { subDays, startOfDay } from "date-fns";
import { saveAs } from "file-saver";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReactExport from "react-export-excel";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CustomDateRange from "../../../components/CustomDateRange";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import DateContext from "../../../context/DateContext";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const Download = (value) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const [excelData, setExcelData] = useState({ sheet: [], storename: "" });

  const callapi = () => {
    setExcelData({ sheet: [], storename: "" });

    let url = "/api/businessintelligence/hourlyanalysis/excel";
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post(url, {
        storeid: value.storeid,
        startDate: value.startDate,
        endDate: value.endDate,
        salesType: value.salesType,
        type: value.type,
      })
      .then(function (response) {
        setExcelData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <button
        style={{ padding: "7px", minWidth: "40px", height: "50%" }}
        type="button"
        className="btn btn-primary"
        onClick={callapi}
        // disabled={loading} // Disable the button while loading
      >
        {" "}
        <FileDownloadOutlinedIcon />
      </button>
      {excelData.sheet.length > 0 && (
        <ExcelFile filename={"Hourly Analysis"} hideElement={true}>
          {excelData.sheet.map((sheet, sheetIndex) => (
            <ExcelSheet dataSet={sheet.dataset} name={sheet.name}></ExcelSheet>
          ))}
        </ExcelFile>
      )}
    </>
  );
};

function BarRechartComponents(props) {
  const { t } = useTranslation();

  return (
    <BarChart width={750} height={350} data={props.bargraphlist}>
      <Bar dataKey="Amount" fill="#106e8a" />
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
    </BarChart>
  );
}

const HourlyAnalysis = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateContext);
  const [salesTypeList, setSalesTypeList] = useState([]);
  const [selectedSalesTypeList, setSelectedSalesTypeList] = useState([]);
  const typeList = [
    { id: 1, descript: "Value" },
    { id: 2, descript: "Trans" },
  ];
  const [dataList, setDataList] = useState({
    bargraphlist: [],
    salesdata: [],
    storename: "",
  });
  const [selectedType, setSelectedType] = useState(1);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const Lang = localStorage.getItem("Lang");

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const handleOutletChange = (selectedValue) => {
    setOutletId(selectedValue);
  };

  const headofficeStatus = () => {
    const outletlog = jwtDecode(localStorage.getItem("token")).outletsdetails;
    if (OutletId.length === 1) {
      return true;
    } else if (OutletId.length > 1) {
      const isHeadOffice = OutletId.every((outletId) => {
        const outletStatus = outletlog.find((item) => item.id === outletId);
        return outletStatus && outletStatus.headofficeData === 1;
      });
      if (isHeadOffice) {
        return true;
      } else {
        toast.error("The selected Outlets should be Headoffice Outlets");
        return false;
      }
    } else {
      toast.error("The selected Outlets should be Headoffice Outlets Here");
      return false;
    }
  };

  useEffect(() => {
    setOutletId([jwtDecode(localStorage.getItem("token")).outlets[0]?.id]);
  }, []);

  const handleSalesTypeChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSalesTypeList(selectedValue);
  };

  const handleTypeChange = (event) => {
    const selectedValue = event.target.value;

    setSelectedType(selectedValue);
  };

  const renderSelectedSalesTypeNames = (selected) => {
    const selectedSalesTypeNames = salesTypeList
      .filter((salestype) => selected.includes(salestype.id))
      .map((salestype) => salestype.descript);

    return selectedSalesTypeNames.join(", ");
  };

  useEffect(() => {
    if (headofficeStatus) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post("/api/businessintelligence/hourlyanalysis", {
          storeid:
            OutletId === ""
              ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
              : OutletId,
          startDate: startDate,
          endDate: endDate,
          salesType: selectedSalesTypeList,
          type: selectedType,
        })
        .then((response) => {
          setDataList(response.data);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            handleLogout();
            toast.error("Unauthorized access");
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.log(error);
          }
        });
    }
  }, [OutletId, startDate, endDate, selectedSalesTypeList, selectedType]);

  useEffect(() => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post("/api/businessintelligence/hourlyanalysis/getsalestype", {
        storeid:
          OutletId === ""
            ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
            : OutletId,
      })
      .then((response) => {
        setSalesTypeList(response.data.Datalist);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          handleLogout();
          toast.error("Unauthorized access");
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
        }
      });
  }, []);

  const getfile = () => {
    let url = "/api/businessintelligence/hourlyanalysis/pdf";
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post(
        url,
        {
          storeid:
            OutletId === ""
              ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
              : OutletId,
          startDate: startDate,
          endDate: endDate,
          salesType: selectedSalesTypeList,
          type: selectedType,
        },
        {
          responseType: "blob", // Set the response type to 'blob'
        }
      )
      .then(function (response) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, "Hourly Analysis" + ".pdf");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box className="h-75 mx-auto w-90 text-align">
      <PageHeader centerText={t("Hourly Analysis")} />
      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}
      >
        <OutletDropdown defaultValue={OutletId} onChange={handleOutletChange} />
        <CustomDateRange sx={{ paddingRight: "20px", paddingLeft: "20px" }} />
        <FormControl
          sx={{ minWidth: 200, maxWidth: 200, paddingRight: "20px" }}
          size="small"
        >
          <InputLabel id="demo-select-small-label">
            {t("Order Type")}
          </InputLabel>
          <Select
            labelId="demo-select-checkbox-label"
            id="demo-select-checkbox"
            multiple
            value={selectedSalesTypeList}
            onChange={handleSalesTypeChange}
            label={t("Select Type")}
            renderValue={renderSelectedSalesTypeNames}
          >
            {salesTypeList.map((salestype) => (
              <MenuItem
                key={salestype.id}
                value={salestype.id}
                style={{ padding: 0 }}
              >
                <Checkbox
                  checked={selectedSalesTypeList.includes(salestype.id)}
                />
                <ListItemText primary={salestype.descript} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{ minWidth: 200, maxWidth: 200, paddingRight: "20px" }}
          size="small"
        >
          <InputLabel id="demo-select-small-label">
            {t("Type")}
          </InputLabel>
          <Select
            labelId="demo-select-checkbox-labels"
            id="demo-select-checkboxs"
            value={selectedType}
            onChange={handleTypeChange}
            label={t("Select Outlet")}
            // renderValue={renderSelectedSalesTypeNames}
          >
            {typeList.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                <ListItemText primary={type.descript} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <button
          type="button"
          style={{
            padding: "7px",
            minWidth: "40px",
            marginRight: "10px",
            height: "50%",
          }}
          className="btn btn-primary"
          onClick={() => getfile()}
        >
          <PictureAsPdfIcon />
        </button>
        <Download
          startDate={startDate}
          endDate={endDate}
          salesType={selectedSalesTypeList}
          storeid={
            OutletId === ""
              ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
              : OutletId
          }
          type={selectedType}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}></Box>

      <Grid container spacing={1} paddingBottom={2}>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              width: "90%",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              // marginBottom: 3,

              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 0.5px 1.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <table style={{ width: "100%" }}>
              <thead>
                <tr className="headingtop">
                  <td style={{ fontWeight: "bold" }}>{t("Date")}</td>
                  <td style={{ fontWeight: "bold" }}>0</td>
                  <td style={{ fontWeight: "bold" }}>1</td>
                  <td style={{ fontWeight: "bold" }}>2</td>
                  <td style={{ fontWeight: "bold" }}>3</td>
                  <td style={{ fontWeight: "bold" }}>4</td>
                  <td style={{ fontWeight: "bold" }}>5</td>
                  <td style={{ fontWeight: "bold" }}>6</td>
                  <td style={{ fontWeight: "bold" }}>7</td>
                  <td style={{ fontWeight: "bold" }}>8</td>
                  <td style={{ fontWeight: "bold" }}>9</td>
                  <td style={{ fontWeight: "bold" }}>10</td>
                  <td style={{ fontWeight: "bold" }}>11</td>
                  <td style={{ fontWeight: "bold" }}>12</td>
                  <td style={{ fontWeight: "bold" }}>13</td>
                  <td style={{ fontWeight: "bold" }}>14</td>
                  <td style={{ fontWeight: "bold" }}>15</td>
                  <td style={{ fontWeight: "bold" }}>16</td>
                  <td style={{ fontWeight: "bold" }}>17</td>
                  <td style={{ fontWeight: "bold" }}>18</td>
                  <td style={{ fontWeight: "bold" }}>19</td>
                  <td style={{ fontWeight: "bold" }}>20</td>
                  <td style={{ fontWeight: "bold" }}>21</td>
                  <td style={{ fontWeight: "bold" }}>22</td>
                  <td style={{ fontWeight: "bold" }}>23</td>
                </tr>
              </thead>
              <tbody>
                {dataList.salesdata.map((item, index) => (
                  <tr key={index}>
                    <td style={{ fontWeight: "bold" }}>{item.saledate}</td>
                    <td>{item.zero}</td>
                    <td>{item.one}</td>
                    <td>{item.two}</td>
                    <td>{item.three}</td>
                    <td>{item.four}</td>
                    <td>{item.five}</td>
                    <td>{item.six}</td>
                    <td>{item.seven}</td>
                    <td>{item.eight}</td>
                    <td>{item.nine}</td>
                    <td>{item.ten}</td>
                    <td>{item.eleven}</td>
                    <td>{item.twelve}</td>
                    <td>{item.thirteen}</td>
                    <td>{item.forteen}</td>
                    <td>{item.fifteen}</td>
                    <td>{item.sixteen}</td>
                    <td>{item.seventeen}</td>
                    <td>{item.eighteen}</td>
                    <td>{item.nineteen}</td>
                    <td>{item.twenty}</td>
                    <td>{item.twentyone}</td>
                    <td>{item.twentytwo}</td>
                    <td>{item.twentythree}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br />
            <BarRechartComponents bargraphlist={dataList.bargraphlist} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HourlyAnalysis;
