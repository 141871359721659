import React from 'react'
import { useTranslation } from 'react-i18next';

export default function SalesByTypeProductMix(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div class="modal-body">
                <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>
                    <h6 id="fromtodatesalebytypeproductmix"></h6>
                </div>

                <table id="salesbytypeproductmixviewtable" style={{ width: "100%" }}>
                    <thead>
                    <tr className="headingtop">
                        <td>{t('Sales Type')}</td>
                        <td>{t('Report Category')}</td>
                        <td>{t('Product')}</td>
                        <td>{t('Trans')}</td>
                        <td>{t('Trans%')}</td>
                        <td>{t('Value')}</td>
                        <td>{t('Value%')}</td>

                    </tr>
                    </thead>
                    <tbody>
                    {
                                                       
                        props.data.productlistigdata.map((item,index) => (
                            <tr key={index}>
                                <td>{item.salestype}</td>
                                <td>{item.reportcat}</td>
                                {item.description==="Total"? <td style={{fontWeight:"bold"}}>{item.description}</td>:  <td>{item.description}</td>}
                                {item.description==="Total"? <td style={{fontWeight:"bold"}}>{item.Trans}</td>:  <td>{item.Trans}</td>}
                                <td>{item.TransPerct}</td>
                                {item.description==="Total"? <td style={{fontWeight:"bold"}}>{item.value}</td>:  <td>{item.value}</td>}
                                <td>{item.valuePerct}</td>
                            </tr>
                        ))
                    }
                    </tbody>

                </table>
            </div>
        </div>
    )
}