import React from "react";
import { Chart } from "react-google-charts";
import { useTheme } from "@mui/material";



export const options = {
    width: 260,
    height: 230,
    bar: { groupWidth: "90%" },
    legend: { position: "none" },
  };
  
  export function BarChart(props) {
    const theme = useTheme();

    if (!props.linedata || props.linedata.length === 0) {
      return <div>No data </div>;
    }
    const backgroundColor = theme.palette.mode==="dark" ? "#333" : "#fff";
    const textColor = theme.palette.mode==="dark" ? "#fff" : "#333";

    const chartOptions = {
      ...options,
      backgroundColor,
      textColor
      // titleTextStyle: { color: textColor },
      
      // legend: { textStyle: { color: textColor } },
    };
  
    const linechartdata = [
      ["Element", "Density", { role: "style" }, { sourceColumn: 0, role: "annotation", type: "string", calc: "stringify" }],
      ...props.linedata,
    ];
  
    return (
      <Chart
        chartType="BarChart"
        width="100%"
        height="100%"
        data={linechartdata}
        options={chartOptions}
      />
    );
  }
  