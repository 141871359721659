import React from "react";
import PageHeader from "../../../components/PageHeader";
import { useTranslation } from "react-i18next";
import { Box, Grid, Paper, Typography, useTheme, Card } from "@mui/material";
import { tokens } from "../../../theme";
import LineChart from "./LineChart";
import TableProduct from "./tableProduct";
import OutletContext from "../../../context/OutletContext";
import { useState, useEffect, useContext } from "react";
import jwtDecode from "jwt-decode";
import { Select } from "antd";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { baseInstance, updateToken } from "../../../api/axios";
import CustomDateRange from "../../../components/CustomDateRange";
import DateContext from "../../../context/DateContext";
import BarChartData from "./barchart";

const DetailSummary = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [outlets, setOutlets] = useState([]);
  const { OutletId, setOutletId } = useContext(OutletContext);
  const [selectedOutlet, setSelectedOutlet] = useState(OutletId[0]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedProductLabel, setSelectedProductLabel] = useState("");
  const Lang = localStorage.getItem("Lang");
  const [tableData, setTableData] = useState({
    columnlist: [],
    rowlist: [],
    linechartdata: [],
    associateproductbyqty: {
      category: [],
      data: [],
    },
    associateproductbyamt: {
      category: [],
      data: [],
    },
  });
  const { startDate, setStartDate, endDate, setEndDate } =
    useContext(DateContext);

  const { Option } = Select;
  const [productList, setProductList] = useState([]);
  const { logout } = useAuth();
  const navigate = useNavigate();

  
  const handleProductChange = (selectedValue, option) => {
    setSelectedProductLabel(option.props.label);
    setSelectedProduct(selectedValue);
  };

  const handleOutletChange = (selectedValue) => {
    setSelectedOutlet(selectedValue);
  };

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    const token = localStorage.getItem("token");
    try {
      const fetchOutletsData = jwtDecode(token);
      setOutlets(fetchOutletsData.outlets);
    } catch {
      console.log("error in token validation");
    }
  }, []);

  useEffect(() => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post("/api/businessintelligence/detailedsummary/productdetails", {
        storeid:
          selectedOutlet === ""
            ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
            : selectedOutlet,
        productnum: selectedProduct,
        startdate: startDate,
        enddate: endDate,
      })
      .then((response) => {
        if (response.status === 200) {
          setTableData(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          handleLogout();
          toast.error("Unauthorized access");
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
        }
      });
  }, [selectedOutlet, startDate, endDate, selectedProduct]);

  useEffect(() => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post("/api/businessintelligence/detailedsummary/products", {
        storeid:
          OutletId === ""
            ? [jwtDecode(localStorage.getItem("token")).outlets[0]?.id]
            : OutletId,
      })
      .then((response) => {
        if (response.status === 200) {
          setProductList(response.data.productlist);
          setSelectedProduct(response.data.productlist[0]["id"]);
          setSelectedProductLabel(response.data.productlist[0]["productname"]);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          handleLogout();
          toast.error("Unauthorized access");
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.log(error);
        }
        // setCardLoading(false);
      });
  }, [selectedOutlet]);

  return (
    <Box className="h-75 mx-auto w-90 text-align">
      <PageHeader centerText={t("Product_Analysis")} />
      <Box
        sx={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}
      >
        <Select
          style={{ marginLeft: "20px", width: "200px", marginRight: "10px" }}
          showSearch
          placeholder={t("Select_a_Product")}
          optionFilterProp="children"
          filterOption={filterOption}
          value={selectedProduct}
          onChange={handleProductChange}
        >
          {" "}
          {productList.map((product) => (
            <Option
              key={product.id}
              value={product.id}
              label={product.productname}
            >
              {product.productname}
            </Option>
          ))}
        </Select>
        <CustomDateRange sx={{ marginBottom: "0px" }} />
        <Select
          onChange={handleOutletChange}
          value={selectedOutlet}
          required
          style={{ minWidth: 230, maxWidth: 230, marginLeft: "10px" }}
        >
          {outlets.map((outlet) => (
            <Option key={outlet.id} value={outlet.id}>
              {outlet.storename}
            </Option>
          ))}
        </Select>
      </Box>

      <div style={{textAlign:Lang ==='ar'?'right': 'left'}}>
        <Typography variant="h4" display="inline" >
        {t("What is the Order of Frequency of")}{" "}
        </Typography>
        <Typography variant="h4" fontWeight="bold" display="inline">
          {selectedProductLabel}{t("?")}
        </Typography>
      </div>
      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <LineChart
              linechartdata={tableData["linechartdata"]}
              name={selectedProductLabel}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              height: "100%",

              textAlign: "center",
              padding: "0px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <TableProduct tableData={tableData} />
          </Paper>
        </Grid>
      </Grid>
      <div style={{ marginTop: "20px",textAlign:Lang ==='ar'?'right': 'left' }}>
        <Typography variant="h4" display="inline">
        {t("What are the other product associated with")}
        </Typography>
        <Typography variant="h4" fontWeight="bold" display="inline">
          {" "}
          {selectedProductLabel}{t("?")}
        </Typography>
      </div>
      <Grid container spacing={2} marginTop={1} marginBottom={1}>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              height: "100%",
              textAlign: "center",
              padding: "10px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <BarChartData
              name={t("By_Quantiy")}
              data={tableData.associateproductbyqty.data}
              category={tableData.associateproductbyqty.category}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              height: "100%",

              textAlign: "center",
              padding: "10px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? "0 1px 2.94px 0.06px #fff"
                  : null,

              backgroundColor: colors.default[100],
            }}
          >
            <BarChartData name={t("By_Amount")}
              data={tableData.associateproductbyamt.data}
              category={tableData.associateproductbyamt.category} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailSummary;
