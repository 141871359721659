import React from 'react'
import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useTranslation } from 'react-i18next';


function LineRechartComponent(props){
    const data =props.linecharts
    return (
        <LineChart width={730} height={250} data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Daily Sales" stroke="#FF0000" />
        </LineChart>
    )

}

export default function Salesummary(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div className="modal-body">
                <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                </div>

                <h7 style={{ fontSize: "16px" }}>{t('Sales_Summary')}</h7>

                <table id="Salesummaryviewtable" style={{ width: "100%" }}>
                    <tbody>
                        {
                            props.data.salessummarydata.map((item, index) => (
                                <tr key={index}>
                                    <td width="40%"></td>
                                    <td>{item.descript}</td>
                                    <td>{item.costeach}</td>
                                </tr>
                            ))

                        }
                        <tr>
                            <td width="40%"></td>
                            <td className='font-weight-bold'>{t('Gross_Sales_No_Tax_No_Discounts')}</td>
                            <td className='font-weight-bold'> {props.data.totaldata.totalsalessummary} </td>
                        </tr>
                    </tbody>
                </table>

                <h7 style={{ fontSize: "16px" }}>{t('Discount_Comps_Summary')}</h7>
                <table id="discountsviewtable" style={{ width: "100%" }}>
                    <tbody>
                        {
                            props.data.promodata.map((item, index) => (
                                <tr key={index}>
                                    <td width="40%"></td>
                                    <td>{item.desc}[{item.qty}]</td>
                                    <td>{item.value}</td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td width="40%"></td>
                            <td className='font-weight-bold'>{t('Total_Discounts')} [{props.data.totaldata.totalpromoqty}]  </td>
                            <td className='font-weight-bold'> {props.data.totaldata.totalpromovalue} </td>
                        </tr>
                    </tbody>
                </table>

                <h7 style={{ fontSize: "16px" }}>{t('')}</h7>
                <table id="taxsummaryviewtable" style={{ width: "100%" }}>
                    <tbody>
                        <tr className="headingtop">
                            <td width="30%">{t('Desc')}</td>
                            <td>{t("Taxable_Amount")}</td>
                            <td>{t("Tax")}</td>
                        </tr>

                        <tr >
                            <td width="30%">{t("Tax")} 1</td>
                            <td>{props.data.taxsummarydata[0].taxable1}</td>
                            <td>{props.data.taxsummarydata[0].tax}</td>
                        </tr>
                        {props.data.taxsummarydata[0].taxable2!=0 &&
                        <tr >
                            <td width="30%">{t("Tax")} 2</td>
                            <td>{props.data.taxsummarydata[0].taxable2}</td>
                            <td>{props.data.taxsummarydata[0].tax2}</td>
                        </tr>
                        }

                        <tr>
                            <td width="30%"></td>
                            <td className='font-weight-bold'>{t("Total")}({t("Tax")})</td>
                            <td className='font-weight-bold'> {props.data.taxsummarydata[0].finaltotal} </td>
                        </tr>
                    </tbody>
                </table>

                <h7 style={{ fontSize: "16px" }}>{t("Sales_Recieved")}</h7>
                <table id="salesbypaymentviewtable" style={{ width: "100%" }}>
                    <tbody>
                        <tr className="headingtop">
                            <td width="30%"></td>
                            <td>{t("Amount")}</td>
                            <td>{t("Change")}</td>
                        </tr>
                        {
                            props.data.salesbypaymentdata.map((item, index) => (
                                <tr key={index}>
                                    <td width="30%">{item.descript}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.change}</td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td  className='font-weight-bold' width="30%">{t("Total")}</td>
                            <td className='font-weight-bold'>{props.data.totaldata.totalpaymentvalue} </td>
                            <td className='font-weight-bold'>  </td>
                        </tr>
                    </tbody>
                </table>

                <h7 style={{ fontSize: "16px" }}>{t("Transaction_Summary")}</h7>
                <table id="transactionviewtable" style={{ width: "100%" }}>
                    <tbody>
                        <tr className="headingtop">
                            <td width="30%">{t("Transactions")}</td>
                            <td>{t("Amount")}</td>
                            <td>{t("Avg_Trans")}</td>
                        </tr>

                        <tr >
                            <td className='font-weight-bold' width="30%">{props.data.transactionsdata[0].transactions}</td>
                            <td className='font-weight-bold'>{props.data.transactionsdata[0].finaltotal}</td>
                            <td className='font-weight-bold'>{props.data.transactionsdata[0].avg}</td>
                        </tr>

                    </tbody>
                </table>

                <h7 style={{ fontSize: "16px" }}>{t("Sales_By_Type_Summary")}</h7>
                <table id="salesbypaymentviewtable" style={{ width: "100%" }}>
                    <tbody>
                        <tr className="headingtop">
                            <td width="30%"></td>
                            <td>{t("Transact")}</td>
                            <td>{t("Amount")}</td>
                        </tr>
                        {
                            props.data.salesbytypedata.map((item, index) => (
                                <tr key={index}>
                                    <td width="30%">{item.descript}</td>
                                    <td>{item.trans}</td>
                                    <td>{item.amount}</td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td  className='font-weight-bold' width="30%">{t("Total")}</td>
                            <td className='font-weight-bold'>{props.data.totaldata.totalsalesbytypeqty} </td>
                            <td className='font-weight-bold'> {props.data.totaldata.totalsalesbytypevalue} </td>
                        </tr>
                    </tbody>
                </table>

                <h7 style={{ fontSize: "16px" }}>{t("Sections_Summary")}</h7>
                <table id="Sectionsviewtable" style={{ width: "100%" }}>
                    <tbody>
                        <tr className="headingtop">
                            <td width="30%"></td>
                            <td>{t("Transact")}</td>
                            <td>{t("Amount")}</td>
                        </tr>
                        {
                            props.data.sectiondata.map((item, index) => (
                                <tr key={index}>
                                    <td width="30%">{item.descript}</td>
                                    <td>{item.trans}</td>
                                    <td>{item.amount}</td>
                                </tr>
                            ))
                        }
                       
                    </tbody>
                </table>

                <h7 style={{ fontSize: "16px" }}>{t("Daily_Sales")}</h7>
                <div>
            <LineRechartComponent   linecharts={props.data.linecharts}/>

            </div>
            </div>
        </div>
    )
}