import React from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import OnlineReport from "../../layouts/dashboard/onlinereport";
import AdminDashboard from "../../layouts/dashboard/admin";
import CompanyProfile from "../../layouts/Administration/CompanyProfile"
import StoreStatus from "../../layouts/storestatus";
import Invoices from "../../layouts/Accounting/Invoices";
import CreateInvoice from "../../layouts/Accounting/createinvoice";
import LoginPage from "../../layouts/global/LoginPage";
import MainLayout from "../../layouts/outletlayout/mainlayout";
import NotFound from "../../layouts/global/notfound";
import useAuth from "../../hooks/useAuth";
import Reports from "../../layouts/reports";
import Transactions from "../../layouts/Accounting/Transactions";
import Vendors from "../../layouts/Accounting/Vendors";
import VendorForm from "../../layouts/Accounting/VendorForm";
import Companies from "../../layouts/Administration/Companies";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CompanyForm from "../../layouts/Administration/Companies/CompanyForm";
import EntityGroup from "../../layouts/Administration/EntityGroup";
import EntityGroupForm from "../../layouts/Administration/EntityGroup/EntityGroupForm";
import jwtDecode from "jwt-decode";
import Outlets from "../../layouts/Administration/Outlets";
import CreateOutlet from "../../layouts/Administration/Outlets/CreateOutlet";
import Users from "../../layouts/Administration/Users";
import CreateUser from "../../layouts/Administration/Users/CreateUser";
import UserRole from "../../layouts/Administration/UserRole";
import BusinessDaySummary from "../../layouts/BusinessIntelligence/BusinessDaySummary";
import DetailSummary from "../../layouts/BusinessIntelligence/DetailedSummary";
import ExecutiveSummary from "../../layouts/BusinessIntelligence/ExecutiveSummary";
import SubwaySetup from "../../layouts/Integration/SubwaySetup";
import SubwayReporting from "../../layouts/Integration/SubwayReporting";
import WhatsApp from "../../layouts/Integration/WhatsApp";
import PrintLabel from "../../layouts/MenuManagement/PrintLabel";
import FoodSalesInsights from "../../layouts/BusinessIntelligence/FoodSalesInsights";
import SubwaySeriesReport from "../../layouts/Integration/SubwaySeriesReport";
import SalesComparison from "../../layouts/BusinessIntelligence/SalesComparison";
import HourlyAnalysis from "../../layouts/BusinessIntelligence/HourlyAnalysis";
import SalesVsBudget from "../../layouts/BusinessIntelligence/SalesVsBudget";



const ProtectedRoute = ({}) => {
  const { auth, isLoading } = useAuth();


  if (isLoading) {
    return (
      <div>
        <div className="logo-bounce"></div>
        <div className="shadow-bounce" />
      </div>
    ); // Show a loading indicator while checking authentication status
  }


  if (!auth) {
    return <Navigate to="/auth/login" replace />;
  }
  if (auth && auth.tokenIsExpired) {
    // Token is not expired, redirect to the dashboard
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};

const GlobalRouteConfig = () => {
  const toastOptions = {
    position: "top-right",
    autoClose: 1000,
  };

  const landingpage= localStorage.getItem("token") ? jwtDecode(localStorage.getItem("token")).landingpage :'/auth/login';


  return (
    <>
      <ToastContainer {...toastOptions} />
      <Routes>
        <Route path="/auth">
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<MainLayout />}>
            <Route
              index
              element={
                landingpage === "/dashboard/onlinereport" ? (
                  <OnlineReport />
                ) : landingpage === "/dashboard/admin" ? (
                  <AdminDashboard />
                )
                // ) : dashboardType === "inventory" ? (
                //   <InventoryDashboard />
                // )
                 : (
                  <LoginPage/> // A default or fallback component
                )
              }
            />
            <Route path="dashboard">
              <Route path="onlinereport" element={<OnlineReport />} />
              <Route path="admin" element={<AdminDashboard />} />
            </Route>
            <Route path="/accounts">
              <Route path="transactions" element={<Transactions />} />
              <Route path="invoices" element={<Invoices />} />
              <Route path="createinvoice" element={<CreateInvoice />} />
              <Route path="vendors" element={<Vendors />} />
              <Route path="createvendor" element={<VendorForm />} />
              <Route path="createvendor/:id" element={<VendorForm />} />
            </Route>
            <Route path="/administration">
              <Route path="profile/companyprofile" element={<CompanyProfile />} />
              <Route path="companylist" element={<Companies />} />
              <Route path="outlets" element={<Outlets />} />
              <Route path="outlets/createoutlet" element={<CreateOutlet />} />
              <Route path="outlets/createoutlet/:id" element={<CreateOutlet />} />

              <Route path="users" element={<Users />} />
              <Route path="users/createuser" element={<CreateUser />} />
              <Route path="users/createuser/:id" element={<CreateUser />} />




              <Route path="userrole" element={<UserRole />} />
                            
              <Route
                path="companylist/createcompany"
                element={<CompanyForm />}
              />
              <Route path="entitygroup" element={<EntityGroup />} />
              <Route path="createentitygroup" element={<EntityGroupForm />} />
            </Route>
            <Route path="businessintelligence/businessday" element={<BusinessDaySummary />} />
            <Route path="businessintelligence/detailedsummary" element={<DetailSummary />} />
            <Route path="businessintelligence/executivesummary" element={<ExecutiveSummary />} />
            <Route path="businessintelligence/foodsalesinsights" element={<FoodSalesInsights />} />
            <Route path="/businessintelligence/salescomparison" element={<SalesComparison />} />
            <Route path="/businessintelligence/hourlyanalysis" element={<HourlyAnalysis />} />
            <Route path="/businessintelligence/salesvsbudget" element={<SalesVsBudget/>} />



            <Route path="reports" element={<Reports />} />
            <Route path="/store/storestatus" element={<StoreStatus />} />

            <Route path="integration">
              <Route path="subwaysetup" element={<SubwaySetup />} />
              <Route path="subwayreporting" element={<SubwayReporting />} />
              <Route path="subwayseriesreport" element={<SubwaySeriesReport />}/>
              <Route path="whatsappmessaging" element={<WhatsApp />} />
            </Route>

            <Route path="menumanagement">
              <Route path="printlabel" element={<PrintLabel />} />
             

            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default GlobalRouteConfig;
