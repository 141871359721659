import React from "react";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

import {
  Typography,
  Grid,
  Paper,
  Card,
  CardContent,
  CardMedia,
  Skeleton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { baseInstance, updateToken } from "../../../api/axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0 2px",
    [theme.breakpoints.up("md")]: {
      padding: "0 4px",
    },
  },
  headerImage: {
    minHeight: 300,
    borderRadius: "0.5rem",
    marginTop: "1rem",
    backgroundImage:
      "url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },
  mask: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.6,
    borderRadius: "0.5rem",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    position: "relative",
  },
  cardMedia: {
    bottom: 0,
    maxHeight: "80px",
    maxWidth: "80px",
    margin: "0 auto",
  },
}));

const CompanyProfile = () => {
  const classes = useStyles();
  const [ProfileData, setProfileData] = useState({
    CompanyName: "",
    Outlets: "",
    BankDetails: "",
    UserDetails: "",
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  useEffect(() => {
    fetchuserprofile();
  }, []);

  const [loadingProfile, setLoadingProfile] = useState(true);

  const { logout } = useAuth();
  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const fetchuserprofile = () => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .get("/api/administration/profile/companyprofile", {})
      .then((response) => {
        const { CompanyName, Outlets, BankDetails, UserDetails } =
          response.data;
        setProfileData({
          CompanyName: CompanyName,
          Outlets: Outlets,
          BankDetails: BankDetails,
          UserDetails: UserDetails,
        });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
      })
      .finally(() => {
        setLoadingProfile(false);
      });
  };
  return (
    <section className="h-75 mx-auto w-90 text-align">
      <div className={`container-fluid ${classes.container}`}>
        <div
          className={`page-header-2 min-height-300 border-radius-xl mt-4 ${classes.headerImage}`}
        >
          <span className={`mask ${classes.mask}`}></span>
        </div>
        <div
          className="card card-body mx-3 mx-md-4 mt-n6"
          style={{ backgroundColor: colors.default[100] }}
        >
          <div className="row gx-4 mb-2">
            <div className="col-auto my-auto">
              <div className="h-100">
                {loadingProfile ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography
                    variant="h5"
                    component="h5"
                    mb={1}
                    fontWeight="bold"
                  >
                    {ProfileData.CompanyName}
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper elevation={3} sx={{ p: 3 }}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ textAlign: t("langDir") }}
                >
                  {t("User_Information")}
                </Typography>

                {loadingProfile ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography sx={{ textAlign: t("langDir") }}>
                    {t("Full_Name")}: {ProfileData.UserDetails.UserCode}
                    <br />
                  </Typography>
                )}
                {loadingProfile ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography sx={{ textAlign: t("langDir") }}>
                    {t("Email")} : {ProfileData.UserDetails.Email}
                    <br />
                    <br />
                  </Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: t("langDir") }}>
              <Paper elevation={3} sx={{ p: 3 }}>
                <Typography variant="h5" fontWeight="bold">
                  {t("Bank_Details")}
                </Typography>
                {loadingProfile ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography>
                    {t("Bank_Name")} : {ProfileData.BankDetails.banknameeng}
                    <br />
                  </Typography>
                )}
                {loadingProfile ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography>
                    {t("Account_No")} : {ProfileData.BankDetails.accnoeng}
                    <br />
                  </Typography>
                )}

                {loadingProfile ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Typography>
                    {t("IBAN_No")}: {ProfileData.BankDetails.ibaneng}
                    <br />
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>

          <div className="col-12 mt-3">
            <div className="mb-3 ps-3" style={{ textAlign: t("langDir") }}>
              <Typography variant="h5" component="h6" fontWeight="bold">
                {t("Outlets")}
              </Typography>
            </div>
            <Grid container spacing={2} sx={{ textAlign: t("langDir") }}>
              {ProfileData.Outlets &&
                ProfileData.Outlets.map((outlet) => (
                  <Grid item xs={12} sm={6} md={3}>
                    <Card className={classes.card}>
                      <CardMedia
                        component="img"
                        image="../../assets/storeicon2.svg"
                        alt="Store Icon"
                        className={classes.cardMedia}
                      />
                      <CardContent>
                        <Typography
                          variant="h6"
                          component="h6"
                          className={classes.cardTitle}
                          fontWeight="bold"
                        >
                          {outlet.storename}
                        </Typography>
                        <Typography variant="body2" component="p" mb={1}>
                          {outlet.location}
                        </Typography>
                        <Typography variant="body2" component="p">
                          <strong>{t("Subscribed")} : </strong>
                        </Typography>
                        <Typography variant="body2" component="p">
                          {outlet.subscribed_date}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CompanyProfile;
