import React from "react";
import { useTranslation } from "react-i18next";

export function MethodPaymentListing(props) {
  const { t } = useTranslation();

  return (
    <div>
      <div>
      <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

        <h6></h6>
      </div>
      <hr />
      <table style={{ width: "100%" }}>
        <thead>
          <tr className="headingtop">
            <td>{t('Method_No')}</td>
            <td>{t('Name')}</td>
            <td>{t('Exchange')}</td>
          </tr>
        </thead>
        <tbody>
          {props.data.methodpaydata.map((item, index) => (
            <tr key={index}>
              <td>{item.methodnum}</td>
              <td>{item.descript}</td>
              <td>{item.exchangerate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
