import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { TextField, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Button from "react-bootstrap/Button";
import { useState,useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Form, Input, Row, Col } from "antd";
import { Select, MenuItem, FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { baseInstance, updateToken } from "../../../api/axios";
import { ConfigProvider, theme } from "antd";

const SendMessageModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const themecustom = useTheme();
  const colors = tokens(themecustom.palette.mode);
  const { logout } = useAuth();
  const [customfield, setCustomField] = useState(true);
  const [defaultnumber, setDufaultNumber] = useState(2);
  const [formmessage] = Form.useForm();
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };
  const { TextArea } = Input;

  const handleApiRequest = async () => {
    updateToken(localStorage.getItem("token"));
    try {
      let response;
      const fieldValues = formmessage.getFieldsValue();
      response = await baseInstance.post(
        "/api/integration/whatsappmessaging/send",
        {
          customnumber: fieldValues.customnumber,
          messagedata: fieldValues.messagedata,
          customfield:customfield
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const sendmessage = () => {
    handleApiRequest()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Message Send Successfully");
          onClose();
        } else {
          console.log("Request failed with status:", response.status);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate(`/`);
        } else if (error.response.status === 401) {
          handleLogout();
        } else {
          console.error(error);
        }
        toast.error("Record Failed!");
        console.log(error);
      });
  };

  useEffect(() => {
    if (isOpen) {
      formmessage.resetFields();
    }
  }, [isOpen, formmessage]);

  return (
    <Modal dialogClassName="modal-70w" show={isOpen} onHide={onClose}>
      <Modal.Header style={{ backgroundColor: colors.default[100] }}>
        <Modal.Title>Send Whatsapp Message</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: colors.default[100] }}>
      <ConfigProvider
            theme={{
              algorithm:
                themecustom.palette.mode == "dark"
                  ? darkAlgorithm
                  : defaultAlgorithm,
            }}
          >
        <Row gutter={1} style={{ marginBottom: "10px" }}>
          <Col span={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-select-small-label">{t("To")}</InputLabel>
              <Select
                value={defaultnumber}
                required
                fullWidth
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const customFieldValue = selectedValue !== 1;
                  setCustomField(customFieldValue);
                  setDufaultNumber(event.target.value);
                }}
              >
                <MenuItem value={1} disabled>{"All POS Contacts"}</MenuItem>
                <MenuItem value={2}>{"Custom"}</MenuItem>
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Form
          form={formmessage}
          name="basic"
          labelCol={{ span: 17 }}
          wrapperCol={{ span: 23 }}
          onFinish={sendmessage}
          layout="vertical" // Set the form layout to vertical
        >
          {customfield ? (
            <Row gutter={1}>
              <Col span={24}>
                <Form.Item
                  label="Custom Numbers"
                  name="customnumber" // Replace with your field name
                  style={{marginBottom:'5px'}}
                  rules={[
                    {
                      required: true,
                      message: "Please enter a Custom Number!",
                    },
                  ]}
                  extra={
                    <>
                      <p>* Numbers should be ending and separated with ;</p>
                      <p style={{marginTop:'2px'}}>* Numbers should be 10 digits along with the country code starting with +, e.g., +966550456589</p>
                    </>
                  }
                >
                  <TextArea placeholder="Mobile Number along with Country Code and separated by ; " rows={2}/>
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col span={24}>
              <Form.Item label="Message" name="messagedata"    rules={[
                    {
                      required: true,
                      message: "Please Message!",
                    },
                  ]}>
                <TextArea placeholder="Write a message" rows={6} />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button type="submit" className="btn btn-sm btn-primary" style={{ borderRadius: "8px" }}>
                {t("Send")}
              </button>

            <Button
              variant="secondary"
              onClick={onClose}
              style={{ marginLeft: "10px" ,borderRadius: "8px" }}
            >
              Cancel
            </Button>
          </div>
        </Form>
        </ConfigProvider>
      </Modal.Body>

    </Modal>
  );
};

export default SendMessageModal;
