import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Salesbystationproduct(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div class="modal-body">
                <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>
                    <h6 id="fromtodatesalebytype"></h6>
                </div>

                <table id="salesstationviewtable" style={{ width: "100%" }}>
                    <thead>
                    <tr className="headingtop">
                        <td>{t('Station')}</td>
                        <td>{t('Product')}</td>
                        <td>{t('Trans')}</td>
                        <td>{t('Value')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                                                       
                        props.data.salesdata.map((item,index) => (
                            <tr key={index}>
                                <td>{item.station}</td>
                                {item.description==="Total"? <td style={{fontWeight:"bold"}}>{item.description}</td>:  <td>{item.description}</td>}
                                {item.description==="Total"? <td style={{fontWeight:"bold"}}>{item.qty}</td>:  <td>{item.qty}</td>}
                                {item.description==="Total"? <td style={{fontWeight:"bold"}}>{item.amount}</td>:  <td>{item.amount}</td>}
                            </tr>
                        ))
                    }
                    </tbody>

                </table>
            </div>
        </div>
    )
}