

import React from 'react'
import { useTranslation } from "react-i18next";

export default function Inventorybyphysicalcount(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>

            <table style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Inventory_Item')}</td>
                        <td colSpan={2}>{t('Syetem')}</td>
                        <td>{t('Total_Units')}</td>
                        <td colSpan={2}>{t('Physical')}</td>
                        <td>{t('Total_Units')}</td>
                        <td>{t('Variance_Units')}</td>
                    </tr>
                </thead>
                <tbody>
                {
                        props.data.inventorydata.map((item, index) => (
                            <tr key={index}>
                                 {
                                  item.col4==="" ? <td className='font-weight-bold'>{item.desc}</td>  :  <td>{item.desc}</td>
                                 } 
                                 {
                                  item.col4==="" ? <td className='font-weight-bold'>{item.col1}</td>  :   <td>{item.col1}</td>
                                 } 
                                <td>{item.col2}</td>
                                <td style={{"borderRight": "1px solid"}}>{item.col3}</td>
                                <td>{item.col4}</td>
                                <td>{item.col5}</td>
                                <td >{item.col6}</td>
                                <td style={{"fontWeight": "bold"}}>{item.col7}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}