import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { languages } from "../outletlayout/mainlayout";
import i18next from "i18next";
import LanguageIcon from "@mui/icons-material/Language";
import { MenuOutlined } from "@mui/icons-material";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useState } from "react";
import { toast } from "react-toastify";
import { updateToken } from "../../api/axios";
import { baseInstance } from "../../api/axios";
import ResetPassword from "./ResetPasswordModal";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";


function TopBar({ onMenuClick ,isSidebarVisible}) {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [resetModal, setResetModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorProfile, setAnchorProfile] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const ProfileMenu = [
    {
      name: "Reset Password",
      code: "reset-password",
    },
    {
      name: "Log Out",
      code: "log-out",
    },
  ];

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClickProfile = (e) => {
    setAnchorProfile(e.currentTarget);
  };

  const handleCloseProfile = (e) => {
    setAnchorProfile(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updatelanguage = (code) => {
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post("/api/util/changelanguage", {
        ApiData: {
          code,
        },
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to Update");
      });
  };

  const updatetheme = (themecodebit) => {
    updateToken(localStorage.getItem("token"));
    const themecode = themecodebit === "light" ? 0 : 1;
    baseInstance
      .post("/api/util/changetheme", {
        ApiData: {
          themecode,
        },
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to Update");
      });
  };

  const handleLanguageClick = (code) => {
    localStorage.setItem("Lang", code);
    // console.log(code);
    updatelanguage(code);
    i18next.changeLanguage(code);
    handleClose();
  };

  const handleClickProfileMenu = (code) => {
    if (code==="reset-password"){
      setResetModal(true);
      handleCloseProfile();
    }
    if (code==='log-out'){
      handleLogout();
    }
  };

  const handleThemeClick = () => {
    const Theme = localStorage.getItem("Theme");
    const nexttheme = Theme === "light" ? "dark" : "light";
    localStorage.setItem("Theme", nexttheme);
    updatetheme(nexttheme);
    colorMode.toggleColorMode();
  };

  return (
    <>
    <ResetPassword
        isOpen={resetModal !== false}
        // LoadPageContent={LoadPageContent}
        // selectedOutlet={selectedOutlet}
        onClose={() => {
          setResetModal(false);
        }}
    />
    <Box display="flex" justifyContent="space-between" p={2} >
      <Box>
      <IconButton onClick={onMenuClick} sx={{
         "@media (max-width: 991px)": {          
          paddingLeft: isSidebarVisible ? "220px" : "0px",
          // position: "absolute",
          
       },

      }}>
          <MenuOutlined />
        </IconButton>
      </Box>
      <Box display={"flex"}>
        <IconButton onClick={handleThemeClick}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton
          aria-controls="language-menu"
          aria-haspopup="true"
          onClick={handleClick}
          aria-label="Multi language"
          title="Multi language"
        >
          <LanguageIcon />
        </IconButton>
        <Menu
          id="language-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {languages.map(({ code, name, country_code }) => (
            <MenuItem
              onClick={() => handleLanguageClick(code)}
              key={country_code}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Menu>
        {/* <IconButton>
        <NotificationsOutlinedIcon />
      </IconButton>
      <IconButton>
        <SettingsOutlinedIcon />
      </IconButton> */}
      
        <IconButton
         aria-controls="Profile-Menu"
         aria-haspopup="true"
         onClick={handleClickProfile}
         aria-label="Profile Menu"
         title="Profile Menu"
        >
          <PersonOutlinedIcon />
        </IconButton>
        <Menu
          id="language-menu"
          anchorEl={anchorProfile}
          open={Boolean(anchorProfile)}
          onClose={handleCloseProfile}
        >
          {ProfileMenu.map(({ name,code}) => (
            <MenuItem
              onClick={() => handleClickProfileMenu(code)}
              key={code}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box></>
  );
}

export default TopBar;
