import React from 'react'
import { useTranslation } from 'react-i18next';

export default function TransactionSalestype(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div className="modal-body">
                <div >
                <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>
                </div>

                <table id="TaxSummaryviewtable" style={{ width: "100%" }}>
                    <thead>
                    <tr className="headingtop">
                         <td>{t('Sales_Type')}</td>
                        <td>{t('Date')}</td>
                        <td>{t('Transact')}</td>
                        <td>{t('Amount')}</td>
                        <td>{t('Payment_Method')}</td>              
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.data.transactiondata.map((item,index) => (
                            <tr className='border-left border-top bottom border-right ' key={index}>

                                {item.newsaletype===1? <td style={{fontWeight:"bold"}}>{item.salestypes}</td>:  <td></td>}
                                {item.newsaletype===1? <td style={{fontWeight:"bold"}}></td>:  <td>{item.opendate}</td>}
                                {item.Total===1? <td style={{fontWeight:"bold"}}>{t('Total')}</td>:  <td>{item.transact}</td>}
                                {item.Total===1? <td style={{fontWeight:"bold"}}>{item.amount}</td>:  <td>{item.amount}</td>}
                                {item.newsaletype===1? <td style={{fontWeight:"bold"}}></td>:  <td>{item.paymenttype}</td>}


                            </tr>
                        ))
                    }

                                      
                    </tbody>
                </table>
                
            </div>
        </div>
    )
}