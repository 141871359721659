import React from 'react'
import { Fragment } from 'react'

import { useTranslation } from 'react-i18next';

export function ProductMix(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>
                <h6 ></h6>
            </div>
            <hr />
            <table  style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Summary_Group')}</td>
                        <td>{t('Report_Category')} </td>
                        <td>{t('Product')} </td>
                        <td>{t('Trans')}</td>
                        <td>{t('Trans%')}</td>
                        <td>{t('Value')}</td>
                        <td>{t('Value%')}</td>                     
                    </tr>
                </thead>
                {
                    props.data.productlistigdata.map((item, index) => (
                        <tbody>
                            <tr key={index} className='border-top border-left border-right'>
                                <td>{item.summarygroup}</td>
                                <td colSpan="6"></td>
                            </tr>
                            {
                                item.reportcatlist.map((items, index) => (
                                    <Fragment>
                                        <tr className='border-left border-top bottom border-right ' key={index}>
                                            <td></td>
                                            <td colSpan="6">{items.reportcat}</td>
                                        </tr>
                                        {
                                            items.products.map((items2, index) => (
                                               
                                                    <tr className='border-left border-right' key={index}>
                                                        <td></td>
                                                        <td></td>
                                                        {items2[0]==="Total"? <td style={{fontWeight:"bold"}}>{items2[0]}</td> :<td>{items2[0]}</td>}
                                                        {items2[0]==="Total"? <td style={{fontWeight:"bold"}}>{items2[1]}</td> :<td>{items2[1]}</td>}
                                                        {items2[0]==="Total"? <td style={{fontWeight:"bold"}}>{items2[2]}</td> :<td>{items2[2]}</td>}
                                                        {items2[0]==="Total"? <td style={{fontWeight:"bold"}}>{items2[3]}</td> :<td>{items2[3]}</td>}
                                                        {items2[0]==="Total"? <td style={{fontWeight:"bold"}}>{items2[4]}</td> :<td>{items2[4]}</td>}


                                                   

                                                    </tr>

                                            ))}
                                    </Fragment>
                                ))}
                        </tbody>
                    )
                    )
                }

            </table>
        </div>
    )
}