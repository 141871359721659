import React from 'react'
import { useTranslation } from 'react-i18next';



export function SummaryGroupListing(props) {
    const {t}=useTranslation();

    return (
        <div>
            <div>
            <h6 style={{textAlign:t('langDir')}}>{t('Outlet')} : {props.outletLabel}</h6>

                <h6 ></h6>
            </div>
            <hr />
            <table  style={{ width: "100%" }}>
                <thead>
                    <tr className="headingtop">
                        <td>{t('Summary_No')}</td>

                        <td>{t('Summary_Group')}</td>
                        
                    </tr>
                </thead>
                <tbody>
                {
                    props.data.summarygroupdata.map((item, index) => (
                      
                            <tr key={index} >
                                <td>{item.summarynum}</td>
                                <td>{item.descript}</td>
                            </tr>
                            
                        
                    )
                    )
                }
                </tbody>

            </table>
        </div>
    )
}