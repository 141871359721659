import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material";


const EmployeePerformance = (props) => {
  const theme = useTheme();

  const [chartData, setChartData] = useState({
    options: {
      theme: {
        mode: "light",
      },
      colors:['#F44336', '#E91E63', '#9C27B0'],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,

        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [], // Initialize with an empty array
      },
    },
    series: [
      {
        name: "Sales",
        data: [], // Initialize with an empty array
      },
    ],
  });

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      options: {
        ...prevChartData.options,
        xaxis: {
          ...prevChartData.options.xaxis,
          categories: props.data["employeename"], // Update categories from props
        },
      },
      series: [
        {
          ...prevChartData.series[0],
          data: props.data["employeesales"], // Update series data
        },
      ],
    }));
  }, [props.data["employeename"]]);

  useEffect(() => {
    setChartData((prevChartData) => ({
      ...prevChartData,
      options: {
        ...prevChartData.options,
        
        theme: {
          ...prevChartData.options.theme,
          mode: theme.palette.mode, // Update categories from props
        },
      },
     
    }));
  }, [theme.palette.mode]);


  return (
    <div id="chart">
      <ReactApexChart
      
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={320}
      />
    </div>
  );
};

export default EmployeePerformance;
