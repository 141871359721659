import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
// import { exportToExcel } from "react-json-to-excel";
import ReactExport from "react-export-excel";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// Import Reports Module

import DailySales from "../reports/sales/DailySales";
import SalesPromo from "../reports/sales/SalesPromo";
import SalesType from "../reports/sales/SalesType";
import SalesPayment from "../reports/sales/SalesPayment";
import SalesProduct from "../reports/sales/SalesProduct";
import ProfitByReportCategory from "../reports/costing/ProfitByReportCategory";
import { MethodPaymentListing } from "../reports/listing/MethodPaymentListing";
import { SummaryGroupListingbyproduct } from "../reports/listing/SummaryGroupListingbyproduct";
import { WarehouseListing } from "../reports/listing/WarehouseListing";
import { ReportCatListing } from "../reports/listing/ReportCatListing";
import { ActiveEmployeeListing } from "../reports/listing/ActiveEmployeeListing";
import { ProfitBySummaryGroup } from "../reports/costing/ProfitBySummaryGroup";
import { SummaryGroupListing } from "../reports/listing/SummaryGroupListing";
import SalesByCategory from "../reports/sales/SalesByCategory";
import TaxSummary from "../reports/summary/TaxSummary";
import EmployeeSalesSummary from "../reports/summary/EmployeeSalesSummary";
import Transactionsummary from "../reports/summary/Transactionsummary";
import Salesummary from "../reports/summary/Salesummary";
import VoidByType from "../reports/void/VoidByType";
import { VoidByEmployee } from "../reports/void/VoidByEmployee";
import Inventorybywarehouse from "../reports/inventory/Inventorybywarehouse";
import Inventorybylevels from "../reports/inventory/Inventorybylevels";
import Inventorybyusage from "../reports/inventory/Inventorybyusage";
import Inventorybyphysicalcount from "../reports/inventory/Inventorybyphysicalcount";
import Salesbyhour from "../reports/sales/Salesbyhour";
import { ProductMix } from "../reports/sales/ProductMix";
import SalesByStationDetail from "../reports/sales/SalesByStationDetail";
import SalesByStation from "../reports/sales/SalesByStation";
import Salesbystationproduct from "../reports/sales/Salesbystationproduct";
import Salesreconcilation from "../reports/sales/Salesreconcilation";
import TransactionSalestype from "../reports/summary/TransactionSalestype";
import TransactionPayment from "../reports/summary/TransactionPayment";
import MemberListReport from "../reports/member/MemberListReport";
import { baseInstance, updateToken } from "../../api/axios";
import { tokens } from "../../theme";
import { useEffect } from "react";
import DaySummary from "../reports/summary/DaySummary";
import SalesByTypeProductMix from "../reports/sales/Salesbytypeproductmix";

// Render Function
const Renderfunction = (props) => {
  if (props.value == 1) {
    return (
      <DailySales
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 2) {
    return (
      <SalesPromo
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 3) {
    return (
      <SalesType
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 4) {
    return (
      <SalesPayment
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 5) {
    return (
      <SalesProduct
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 6) {
    return (
      <ProfitByReportCategory
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 7) {
    return (
      <MethodPaymentListing
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 10) {
    return (
      <SummaryGroupListing
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 11) {
    return (
      <WarehouseListing
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 9) {
    return (
      <ReportCatListing
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 12) {
    return (
      <ActiveEmployeeListing
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 8) {
    return (
      <SummaryGroupListingbyproduct
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 14) {
    return (
      <ProfitBySummaryGroup
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 15) {
    return (
      <SalesByCategory
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 16) {
    return (
      <TaxSummary
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 17) {
    return (
      <EmployeeSalesSummary
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 18) {
    return (
      <Transactionsummary
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 19) {
    return (
      <Salesummary
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 20) {
    return (
      <VoidByType
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 21) {
    return (
      <VoidByEmployee
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 23) {
    return (
      <Inventorybywarehouse
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 26) {
    return (
      <Inventorybylevels
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 27) {
    return (
      <Inventorybyusage
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 29) {
    return (
      <Inventorybyphysicalcount
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 30) {
    return (
      <Salesbyhour
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 31) {
    return (
      <ProductMix
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 32) {
    return (
      <SalesByStationDetail
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 33) {
    return (
      <SalesByStation
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 34) {
    return (
      <Salesbystationproduct
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 35) {
    return (
      <Salesreconcilation
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 36) {
    return (
      <TransactionSalestype
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 37) {
    return (
      <TransactionPayment
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  } else if (props.value == 38) {
    return (
      <MemberListReport
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  }
  else if (props.value == 40) {
    return (
      <DaySummary
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  }
  else if (props.value == 41) {
    return (
      <SalesByTypeProductMix
        data={props.data}
        reportlabel={props.reportlabel}
        outletLabel={props.outletLabel}
      />
    );
  }
};

function LoadingSpinner() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <div
      style={{ height: "300px", backgroundColor: colors.default[100] }}
      className="container-logo-bounce"
    >
      <div className="logo-bounce"></div>
      <div className="shadow-bounce" />
    </div>
  );
}

const Download = (value) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [excelData, setExcelData] = useState({ sheet: [], storename: "" });

  const callapi = () => {
    setExcelData({ sheet: [], storename: "" });

    let url = "/api/reports/download/excel";
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post(url, {
        startdate: value.startdate,
        endate: value.endate,
        selectedID: value.selectedID,
        storeid: value.storeid,
      })
      .then(function (response) {
        setExcelData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <button
        style={{ padding: "7px", minWidth: "40px" }}
        type="button"
        className="btn btn-primary"
        onClick={callapi}
        // disabled={loading} // Disable the button while loading
      >
        {" "}
        <FileDownloadOutlinedIcon />
      </button>
      {excelData.sheet.length > 0 && (
        <ExcelFile
          filename={value.reportname.replace(/\s+/g, "_")}
          hideElement={true}
        >
          {excelData.sheet.map((sheet, sheetIndex) => (
            <ExcelSheet dataSet={sheet.dataset} name={sheet.name}>

            </ExcelSheet>
          ))}
        </ExcelFile>
      )}
    </>
  );
};

function CustomModal(props) {
  const { t } = useTranslation();
  let reportfilename = "Reports";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const getfile = (value) => {
    reportfilename = value.reportname.replace(/\s+/g, "_");
    let url = "/api/reports/download";
    updateToken(localStorage.getItem("token"));
    baseInstance
      .post(
        url,
        {
          startdate: value.startdate,
          endate: value.endate,
          selectedID: value.selectedID,
          storeid: value.storeid,
        },
        {
          responseType: "blob", // Set the response type to 'blob'
        }
      )
      .then(function (response) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        saveAs(blob, reportfilename + ".pdf");
      })
      .catch((error) => {
        console.log(error);
      });

    // fetch(url, {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({
    //         startdate: value.startdate, endate: value.endate, selectedID: value.selectedID
    //     })
    // })
  };

  return (
    <Modal
      dialogClassName="modal-70w"
      show={props.show}
      onHide={props.handleClose}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header style={{ backgroundColor: colors.default[100] }}>
        <Modal.Title> {props.reportdata.ReportLabel}</Modal.Title>
        <div>
          <button
            style={{ padding: "7px", minWidth: "40px", marginRight: "10px" }}
            type="button"
            className="btn btn-primary"
            onClick={() =>
              getfile({
                selectedID: props.reportdata.selectedID,
                reportname: props.reportdata.ReportLabel,
                startdate: props.reportdata.startdates,
                endate: props.reportdata.endates,
                storeid: props.storeid,
              })
            }
          >
            {" "}
            <PictureAsPdfIcon />
          </button>

          <Download
            selectedID={props.reportdata.selectedID}
            reportname={props.reportdata.ReportLabel}
            startdate={props.reportdata.startdates}
            endate={props.reportdata.endates}
            storeid={props.storeid}
          />
        </div>
      </Modal.Header>
      {props.spinnerstatus ? (
        <Modal.Body style={{ backgroundColor: colors.default[100] }}>
          <LoadingSpinner />
        </Modal.Body>
      ) : (
        <Modal.Body style={{ backgroundColor: colors.default[100] }}>
          <Renderfunction
            value={props.reportdata.Reportid}
            data={props.reportdata.data}
            reportlabel={props.reportdata.ReportLabel}
            outletLabel={props.reportdata.OutletLabel}
          />
        </Modal.Body>
      )}

      <Modal.Footer style={{ backgroundColor: colors.default[100] }}>
        <Button variant="secondary" onClick={props.handleClose}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;
