import { Box, TextField, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import PageHeader from "../../../../components/PageHeader";
import { updateToken } from "../../../../api/axios";
import { baseInstance } from "../../../../api/axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import useAuth from "../../../../hooks/useAuth";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DateRangePicker } from "rsuite";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../../../../components/supportingComponents/CustomNoRowsOverlay";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from '@mui/material/FormGroup';


function CreateOutlet() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  // Required Form Details
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [company, setCompany] = useState([]);
  const [versionValue, setVersionValue] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const [noOfDays, setNoOfDays] = useState(0);
  const [endDate, setEndDate] = useState(null);
  const [fee, setFee] = useState(0.0);
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const [newSubscription, setNewSubscription] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [whatsappapi, setWhatsAppApi] = useState(false);
  const [dateRangeApi, setDateRangeApi] = useState([]);
  const [selectedApi, setSelectedApi] = useState(0);
  const [apiDetail, setApiDetail] = useState([]);

  const apiList = [
    {
      id: 1,
      value: "ERPNextV101",
    },
  ];

  const [status, setStatus] = useState(true);
  const [headofficeStatus, setHeadOfficeStatus] = useState(false);

  const handleActiveStatus = (event) => {
    const newValue = event.target.checked;
    setStatus(newValue);
  };

  const handleheadofficeStatus = (event) => {
    const newValue = event.target.checked;
    setHeadOfficeStatus(newValue);
  };
  

  const handleChangeVersion = (event) => {
    setVersionValue(event.target.value);
  };

  const [errors, setErrors] = useState({});
  const [errorsApi, setErrorsApi] = useState({});

  const { logout } = useAuth();

  const handleLogout = async (e) => {
    const success = await logout();
    if (success) {
      document.body.dir = "ltr";
      navigate("/auth/login", { replace: true });
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "th-datagrid-theme",
      flex: 0.2,
    },
    {
      field: "startdate",
      headerName: "Start Date",
      headerClassName: "th-datagrid-theme",
      flex: 0.2,
    },
    {
      field: "enddate",
      headerName: "End Date",
      headerClassName: "th-datagrid-theme",
      flex: 0.2,
    },
    {
      field: "createdon",
      headerName: t("Created On"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "fee",
      headerName: t("Fee"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  const columnsApiData = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "th-datagrid-theme",
      flex: 0.2,
    },
    {
      field: "startdate",
      headerName: "Start Date",
      headerClassName: "th-datagrid-theme",
      flex: 0.2,
    },
    {
      field: "enddate",
      headerName: "End Date",
      headerClassName: "th-datagrid-theme",
      flex: 0.2,
    },
    {
      field: "api_key",
      headerName: t("Api Key"),
      flex: 0.4,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
    {
      field: "user",
      headerName: t("User"),
      flex: 0.2,
      headerClassName: "th-datagrid-theme",
      align: "left",
    },
  ];

  const handleApiRequest = async () => {
    updateToken(localStorage.getItem("token"));
    try {
      let response;

      if (id) {
        // Editing existing outlet
        response = await baseInstance.put(
          `/api/administration/outlets/createoutlet`,
          {
            ApiData: {
              name,
              address,
              status,
              id,
              whatsappapi,
              headofficeStatus
            },
          }
        );
      } else {
        // Creating new outlet
        response = await baseInstance.post(
          "/api/administration/outlets/createoutlet",
          {
            ApiData: {
              name,
              address,
              selectedCompany,
              noOfDays,
              dateRange,
              versionValue,
              status,
              fee,
              whatsappapi,
              headofficeStatus
            },
          }
        );
      }

      return response;
    } catch (error) {
      throw error;
    }
  };
  const handleApiRequestExternalApi = () => {
    const validationErrors = {};
    if (id) {
      if (username.trim() === "") {
        validationErrors.username = "Enter Username";
      }
      if (password.trim() === "") {
        validationErrors.password = "Enter Password";
      }
      if (selectedApi === 0) {
        toast.error("Select the API");
        validationErrors.api = "Select Api";
      }
      if (dateRangeApi.length === 0) {
        toast.error("Select the date range for API");
        validationErrors.range = "Select range";
      }
      if (Object.keys(validationErrors).length > 0) {
        setErrorsApi(validationErrors);
        return;
      }
      updateToken(localStorage.getItem("token"));
      baseInstance
        .post(`/api/administration/outlets/createoutlet/newapiaccess`, {
          ApiData: {
            username,
            password,
            dateRangeApi,
            selectedApi,
            selectedCompany,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            navigate("/administration/outlets");
            toast.success("New Api Key Created");
          } else {
            console.log("Request failed with status:", response.status);
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.data.message) {
            toast.error(error.response.data.message);
          } else {
            if (error.response.status === 403) {
              navigate(`/`);
            } else if (error.response.status === 401) {
              handleLogout();
              navigate(`/auth/login`);
            } else {
              console.error(error);
              toast.error("Record Failed!");
            }
          }
        });
    }
  };

  const handleApiRequestSubscription = () => {
    const validationErrors = {};
    if (id) {
      if (noOfDays === 0) {
        toast.error("Select Date Range");
      } else {
        updateToken(localStorage.getItem("token"));
        baseInstance
          .post(`/api/administration/outlets/createoutlet/newsubscription`, {
            ApiData: {
              dateRange,
              noOfDays,
              id,
              selectedCompany,
              fee,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              navigate("/administration/outlets");
              toast.success("New Subsciption Added");
            } else {
              console.log("Request failed with status:", response.status);
            }
          })
          .catch((error) => {
            if (error.response.data.message) {
              toast.error(error.response.data.message);
            } else {
              if (error.response.status === 403) {
                navigate(`/`);
              } else if (error.response.status === 401) {
                handleLogout();
                navigate(`/auth/login`);
              } else {
                console.error(error);
                toast.error("Record Failed!");
              }
            }
          });
      }
    }
  };

  const handleClick = () => {
    // Validate the form fields
    const validationErrors = {};
    if (!id) {
      if (name.trim() === "") {
        validationErrors.name = "Name is required";
      }
      if (address.trim() === "") {
        validationErrors.address = "Address is required";
      }

      if (selectedCompany === 0) {
        validationErrors.selectedCompany = "Select the Company";
      }
      if (noOfDays === 0) {
        validationErrors.noOfDays = "Select the Date Range";
      }
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
    }

    handleApiRequest()
      .then((response) => {
        if (response.status === 200) {
          navigate("/administration/outlets");
          toast.success("Outlet has been created!");
        } else {
          console.log("Request failed with status:", response.status);
        }
      })
      .catch((error) => {
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
            navigate(`/auth/login`);
          } else {
            console.error(error);
            toast.error("Record Failed!");
          }
        }
      });
  };

  useEffect(() => {
    if (id) {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .get(`/api/administration/outlets/createoutlet`, {
          params: { outletId: id },
        })
        .then((response) => {
          const outletData = response.data.outletdata;
          setName(outletData.name);
          setAddress(outletData.address);
          setSelectedCompany(outletData.companyid);
          setCompany(outletData.companydata);
          setVersionValue(outletData.version);
          setStatus(outletData.status);
          setSubscriptionDetail(outletData.subscriptiondetail);
          setApiDetail(outletData.apikeydetail);
          setWhatsAppApi(outletData.WhatsappApi==0? false:true);
          setHeadOfficeStatus(outletData.HeadOfficeData==0? false:outletData.HeadOfficeData==null?false:true);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
            toast.error("Record Failed!");
          }
        });
    } else {
      updateToken(localStorage.getItem("token"));
      baseInstance
        .get(`/api/administration/outlets/createoutlet`)
        .then((response) => {
          const outletGetData = response.data;
          setCompany(outletGetData.companylist);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            navigate(`/`);
          } else if (error.response.status === 401) {
            handleLogout();
          } else {
            console.error(error);
            toast.error("Record Failed!");
          }
        });
    }
  }, [id]);

  const handleCompanyChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedCompany(selectedValues);
  };

  const labelcheckbox = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleDateRangeChange = (value) => {
    const oneDay = 1000 * 60 * 60 * 24;
    setDateRange(value);
    if (value === null) {
      setNoOfDays(0);
    } else {
      setNoOfDays(Math.abs(value[1] - value[0]) / oneDay);
    }
  };

  const handleDateRangeChangeApi = (value) => {
    setDateRangeApi(value);
  };

  const handleChangeApi = (event) => {
    const selectedValues = event.target.value;
    setSelectedApi(selectedValues);
    // props.onChange(selectedValue); // Call the onChange callback with the selected value
  };

  const handleCheckboxChange = () => {
    const selectedValues = !whatsappapi;
    setWhatsAppApi(selectedValues);
    // props.onChange(selectedValue); // Call the onChange callback with the selected value
  };


  return (
    <Box className=" h-75 mx-auto w-90 text-align ">
      {id ? (
        <PageHeader title="Edit Outlet" />
      ) : (
        <PageHeader title="Create Outlet" />
      )}

      <Box component="form" sx={{ display: "flex", flexDirection: "column" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              label={t("Name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              error={errors.name}
              helperText={errors.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t("Address")}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              error={errors.address}
              helperText={errors.address}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="demo-select-small-label">
                {t("Company")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedCompany}
                label="Company*"
                onChange={handleCompanyChange}
                error={errors.selectedCompany}
                disabled={!!id}
              >
                {company.map((companyid) => (
                  <MenuItem value={companyid.id}>{companyid.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid container alignItems="center" item xs={6}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography fontWeight="bold" variant="h5">
                  Mode
                </Typography>
              </Grid>
              <Grid
                item
                sx={{ paddingTop: "23px" }}
                style={{ paddingTop: "22px" }}
              >
                <RadioGroup
                  // value={value}
                  onChange={handleChangeVersion}
                  style={{ flexDirection: "row" }}
                  defaultValue={0}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Trial Version"
                    disabled={!!id}
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="Full Version"
                    disabled={!!id}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center" item xs={7}>
            <Grid item>
              <Typography fontWeight="bold" variant="h5">
                Active
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={status}
                onChange={handleActiveStatus}
                color="warning"
              />
            </Grid>
            <Grid item>
              <Typography fontWeight="bold" variant="h5">
                Connect HeadOffice
              </Typography>
            </Grid>
            <Grid item>
              <Switch
                checked={headofficeStatus}
                onChange={handleheadofficeStatus}
                color="warning"
              />
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            border: "1px solid #ccc", // Border style
            padding: "16px",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginTop: "15px",
              marginBottom: "10px",
              textAlign: t("langDir"),
            }}
            fontWeight="bold"
          >
            {" "}
            {t("Subscription detail")}{" "}
          </Typography>

          {id ? (
            <DataGrid
              sx={{ marginTop: "15px" }}
              rows={subscriptionDetail}
              columns={columns}
              slots={{
                noRowsOverlay: () => (
                  <CustomNoRowsOverlay message={t("No Subscription")} />
                ),
              }}
              style={{ height: 300, marginBottom: "15px" }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 4,
                  },
                },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
            />
          ) : null}
          {id ? (
            <Box
              sx={{
                textAlign: "left",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => setNewSubscription(true)}
              >
                {t("New Subscription")}
              </button>
            </Box>
          ) : null}
          {!id || newSubscription ? (
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <DateRangePicker
                  placeholder="Select Subscription Range"
                  ranges={[]}
                  style={{ width: "100%", height: "100%" }}
                  size="lg"
                  value={dateRange}
                  onChange={handleDateRangeChange}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  label={t("Days")}
                  value={noOfDays}
                  error={errors.noOfDays}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  label={t("Pay Amount")}
                  onChange={(e) => setFee(e.target.value)}
                  value={fee}
                  fullWidth
                />
              </Grid>
              {id ? (
                <Grid item xs={2}>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={handleApiRequestSubscription}
                  >
                    {t("Add/Renew")}
                  </button>
                </Grid>
              ) : null}
            </Grid>
          ) : null}
          <FormGroup>
            
          <FormControlLabel
            control={<Checkbox  checked={whatsappapi}
            onChange={handleCheckboxChange} />}
            label={
              <Typography variant="body1" fontWeight="bold">
                Whatsapp Integration
              </Typography>
            }
          />
          </FormGroup>
         
        </Box>

        {id ? (
          <Box
            sx={{
              border: "1px solid #ccc", // Border style
              padding: "16px",
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
              marginTop: "10px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginTop: "15px",
                marginBottom: "10px",
                textAlign: t("langDir"),
              }}
              fontWeight="bold"
            >
              API Access
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={3}>
                <DateRangePicker
                  placeholder="Start - End Api Date"
                  ranges={[]}
                  style={{ width: "100%", height: "100%" }}
                  size="lg"
                  value={dateRangeApi}
                  onChange={handleDateRangeChangeApi}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-select-small-label">
                    {t("API")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedApi}
                    label="Api*"
                    size="small"
                    onChange={handleChangeApi}
                    // style={props.sx}
                  >
                    {apiList.map((api) => (
                      <MenuItem value={api.id}>{api.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  label={t("Username")}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  error={errorsApi.username}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  label={t("Password")}
                  onChange={(e) => setPassword(e.target.value)}
                  error={errorsApi.password}
                  value={password}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3} sx={{ marginTop: "20px" }}>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={handleApiRequestExternalApi}
                >
                  {t("Add API key")}
                </button>
              </Grid>
            </Grid>
            {apiDetail.length > 0 ? (
              <DataGrid
                sx={{ marginTop: "15px" }}
                rows={apiDetail}
                columns={columnsApiData}
                slots={{
                  noRowsOverlay: () => (
                    <CustomNoRowsOverlay message={t("No Api Key")} />
                  ),
                }}
                style={{ height: 300, marginBottom: "15px" }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 4,
                    },
                  },
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
              />
            ) : null}
          </Box>
        ) : null}
      </Box>

      <Box sx={{ textAlign: "right", marginTop: "20px", marginBottom: "20px" }}>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleClick}
        >
          {t("Save")}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            navigate("/administration/outlets");
          }}
        >
          {t("Cancel")}
        </button>
      </Box>
    </Box>
  );
}

export default CreateOutlet;
